import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next"
import { useAuth } from "../../../hooks/auth"
import Modal, { ModalHeader, ModalBody, ModalFooter } from "../../Modal"
import Table from "../../Table"
import TableTr from "../../TableTr"
import MoveForwardButton from "../../MoveForwardButton"
import Step from "../../Step"
import Select from "react-select";
import axios from "../../../lib/axios";
import Swal from 'sweetalert2'

const ChooseBulkUsagers = ({
    activeStep, 
    step, 
    nextStep, 
    moveForward,
    formData, 
    setFormData
}) => {

    const {user} = useAuth()
    const {t} = useTranslation()
    const [whatToDo, setWhatToDo] = useState("")
    const [usagers, setUsagers] = useState([])

    useEffect(() => {

        if(activeStep === 2 && !formData.list_of_usagers.length) {

            axios.get('/api/my-usagers?response=json&limit=all')
                .then(r => r.data)
                .then(data => {
    
                    setUsagers(data.map(d => ({value: d.id, label: d.name +' ( '+d.mobile+' )'})))
                    setFormData({...formData, list_of_usagers: data})
                })
        }
    }, [activeStep])

    useEffect(() => {

        if(formData.send_to_all === null) {

            setWhatToDo("")
        }
    }, [formData.send_to_all])

    const getUsagerTr = (usagerId) => {

        const usager = formData.list_of_usagers.filter(usager => usager.id === usagerId)[0]
        return <TableTr key={usagerId}>
            <td>{usager.name}</td>
            <td>{usager.email}</td>
            <td>{usager.mobile}</td>
            <td>
                <button className="btn btn-danger btn-sm" onClick={() => removeUsager(usagerId)}>
                    <i className="bi bi-trash2"></i>
                </button>
            </td>
        </TableTr>
    }

    const removeUsager = usagerId => {

        const usagers = formData.selected_usagers.filter(u => u !== usagerId)
        setFormData({...formData, selected_usagers: usagers})
    }

    return <>
        <Step isActive={step === activeStep} step={step}>
            <button type="button" className="btn btn-success w-100" data-bs-toggle="modal" data-bs-target="#usagers-modal">{t("Receiver")}</button>
        </Step>

        <Modal id="usagers-modal" size="lg">
            {!!formData.list_of_usagers.length && <ModalHeader>
                {t("Usagers")} &nbsp; {<small>({formData.list_of_usagers.length} {t("Total")})</small>}
            </ModalHeader>}

            <ModalBody>
                <div className="row">
                    <div className={whatToDo !== "choose_usagers" ? "col-md-12" : "col-md-6"}>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="what_to_do">{t("Send document to?")}</label>
                            <select id="what_to_do" className="form-select" value={whatToDo} onChange={(e) => {
                                
                                if(whatToDo === "choose_usagers" && e.target.value !== "choose_usagers" && formData.selected_usagers.length) {

                                    Swal.fire({
                                        icon: "warning",
                                        title: t("Are you sure?"),
                                        text: t("Do you really want to switch? All your selected usagers will be removed?"),
                                        showCancelButton: true,
                                        confirmButtonText: t("Yes, nevermind it!"),
                                        cancelButtonText: t("No, cancel!"),
                                        customClass: {
                                            confirmButton: 'btn btn-danger',
                                            cancelButton: 'btn btn-secondary mx-3'
                                        },
                                        buttonsStyling: false,
                                        width: '20rem'
                                    }).then(result => {

                                        if(result.isConfirmed) { 
                                            
                                            setWhatToDo("send_to_all"); 
                                            setFormData({...formData, selected_usagers: [], send_to_all: 1}) 
                                        }
                                    })
                                }
                                else { 
                                    
                                    setWhatToDo(e.target.value); 
                                    if(e.target.value !== "send_to_all") {

                                        setFormData({...formData, send_to_all: 0}) 
                                    } else {
                                     
                                        setFormData({...formData, send_to_all: 1}) 
                                    }
                                }
                            }}>
                                <option disabled value="">{t("Choose")}</option>
                                <option value="send_to_all">{t("Send to all")}</option>
                                <option value="choose_usagers">{t("Choose whom to send")}</option>
                            </select>
                        </div>
                    </div>

                    {whatToDo === "choose_usagers" && <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="choose_usagers">{t("Choose Usagers")}</label>
                            <Select disabled={!usagers.length} value="" options={usagers.filter(usager => !formData.selected_usagers.includes(usager.value))} onChange={option => {

                                setFormData({...formData, selected_usagers: [...formData.selected_usagers, option.value]})
                            }} />
                        </div>
                    </div>}

                    {whatToDo === "choose_usagers" && <div className="col-md-12"> 
                        <Table>
                            <thead>
                                <tr>
                                    <th>{t("Name")}</th>
                                    <th>{t("Email")}</th>
                                    <th>{t("Mobile")}</th>
                                    <th>{t("Action")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.selected_usagers.map(usagerId => getUsagerTr(usagerId))}
                            </tbody>
                        </Table>
                    </div>}
                </div>
            </ModalBody>

            <ModalFooter>
                <MoveForwardButton 
                    disabled={whatToDo ? (whatToDo === "choose_usagers" ? !formData.selected_usagers.length : false) : true} 
                    moveForward={() => moveForward(step)} />
            </ModalFooter>
        </Modal>
    </>
}

export default ChooseBulkUsagers