import {React, useState, useEffect} from "react"
import { useTranslation } from "react-i18next";
import { createImportSpecifier } from "typescript";
import { useAuth } from "../../hooks/auth";
import { useHelpers } from "../../hooks/helpers";
import Step from "../Step";
import ChooseBulkUsagers from "./Steps/ChooseBulkUsagers";

import ChooseDocumentType from "./Steps/ChooseDocumentType";
import DataDocument from "./Steps/DataDocument";
import PaymentButton from "./Steps/PaymentButton";
import PreviewPdf from "./Steps/PreviewPdf";
import SelectAnnuaire from "./Steps/SelectUo";
import SubmitForm from "./Steps/SubmitForm";

const Steps = ({
    errors, 
    moveForward, 
    setErrors,
    docsTypes,
    setDocsTypes,
    activeStep, 
    handleFormDataChange, 
    docTypesNeedFile, 
    setActiveStep, 
    nextStep, 
    submitForm,
    getFormData,
    formatFormData,
    formData, 
    setFormData,
    etimbreIntitule,
    isProcessingFormData,
    setIsProcessingFormData,
    visaDocuments,
    formResponse,
    setFormResponse,
    selectedTypeDoc,
    setSelectedTypeDoc,
    adminstratifIntitule}) => {

        const [setpsList, setStepsList] = useState([])
        const [isShowingPreviewPdfStep, setIsShowingPreviewPdfStep] = useState(true)

        const {t} = useTranslation()
        const {totalPayable} = useHelpers()

        const stepsProps = {
            moveForward, 
            activeStep, 
            errors, 
            setErrors, 
            visaDocuments, 
            docsTypes,
            setDocsTypes,
            handleFormDataChange, 
            docTypesNeedFile, 
            setActiveStep, 
            nextStep, 
            submitForm,
            formData, 
            formatFormData, 
            getFormData,
            setFormData,
            etimbreIntitule,
            isProcessingFormData,
            setIsProcessingFormData,
            formResponse,
            setFormResponse,
            selectedTypeDoc,
            setSelectedTypeDoc,
            adminstratifIntitule
        }

        const {user} = useAuth()
        useEffect(() => {

            setStepsList(__steps_list__())
        }, [user, formData.etimbre_id, formData.administratif_id, formData.type_doc_id, formData.send_to_all, formData.list_of_usagers, formData.selected_usagers, formData.sms_cost])

        const __steps_list__ = () => {

            const __steps__ = []
            __steps__.push(ChooseDocumentType)
            const type_doc_constante = docsTypes.filter(d => d.id == formData.type_doc_id)[0]?.constante

            if(user && user.usager && formData.type_doc_id) {

                if(type_doc_constante === "LIB_DOC_TYPE_VISA") {

                    __steps__.push(SelectAnnuaire)
                }
            }

            if(user && user.annuaire) {

                __steps__.push(ChooseBulkUsagers)
            }

            __steps__.push(DataDocument)
            if(!!totalPayable(formData) || !user) { __steps__.push(PaymentButton) }

            if(![etimbreIntitule.id, adminstratifIntitule.id].includes(formData.type_doc_id)) {
                
                if(type_doc_constante !== "LIB_DOC_TYPE_VISA") { __steps__.push(PreviewPdf) }
                if(!isShowingPreviewPdfStep) { setIsShowingPreviewPdfStep(!isShowingPreviewPdfStep) }
            } else {

                if(isShowingPreviewPdfStep) { setIsShowingPreviewPdfStep(!isShowingPreviewPdfStep) }
            }

            __steps__.push(SubmitForm)
            return __steps__
        }

        const __step__ = index => {

            const __steps__ = __steps_list__();
            const Component = __steps__[index]
            if(__steps__[index] === undefined) { return '' }

            return (<Component key={index} step={index+1} {...stepsProps} isShowingPreviewPdfStep={isShowingPreviewPdfStep} />)
        }

    return <>
        
        {!!setpsList.length && setpsList.map((step, index) => __step__(index))}
        {/* {user && user.annuaire && <ChooseBulkUsagers step="2" {...stepsProps} />}
        <DataDocument step="2" {...stepsProps} />
        <PaymentButton step="3" {...stepsProps} />
        <PreviewPdf step="4" {...stepsProps} />

        <Step isActive={activeStep >= 4} step={![adminstratifIntitule.id, etimbreIntitule.id].includes(formData.type_doc_id) ? '5' : '4'}>
            <button className="btn w-100 px-4" onClick={submitForm} disabled={isProcessingFormData || activeStep < 4 || formResponse.signer !== ''}>  
                <i className="bi bi-qr-code-scan"></i>&nbsp;{t('Certify & Send')}..
            </button>
        </Step> */}
    </>
}

export default Steps