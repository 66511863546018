import axios from "../../lib/axios"
import { React, useEffect, useState } from "react"
import { toast } from "react-toastify"
import ValidationErrors from "../../components/ValidationErrors"
import RegisterUsagerInputs from "../../components/RegisterUsagerInputs"
import { useAuth } from "../../hooks/auth"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import Swal from "sweetalert2"

const Register = () => {
    
    const { t } = useTranslation()
    
    const {login} = useAuth({middleware: 'guest', redirectIfAuthenticated: '/'});
    
    const [isProcessingFormData, setIsProcessingFormData] = useState(false)
    
    const [isRegistred, setIsRegistred] = useState(false)
    
    const [status, setStatus] = useState(null)

    const [errors, setErrors] = useState({})

    const [searchParams] = useSearchParams();

    const [acceptTocs, setAcceptTocs] = useState(false)

    const [cguText, setCguText] = useState('')

    const [usagerData, setUsagerData] = useState({
        uo_id: '',
        pid: '',
        fn: '',
        gn: '',
        dob: '',
        mobile: '',
        epidil: '',
        category: '',
        fonction_id:'',
        civilite: '',
        formejuridique_id: '',
        adresse: '',
        adrsuite: '',
        type_id: '',
        country_id: '',
        city_id: '',
        city_name: '',
        title_doc: '',
        type_doc_id: '',
        ref_doc: '',
        files: '',
        denomination: '',
        editor: '',
        registreid: '', 
        rccm: '',
        cp: '',
        lat: '',
        lng: '',
        email: (searchParams.get('email') || ''),
        password: '',
    })

    useEffect(() => {

        axios.get('/api/parametres/cgu')
        .then(r => r.data)
        .then(data => setCguText(data.comment))
    }, [])

    function handleUsagerDataChange(name, value) {

        setUsagerData({
            ...usagerData,
            [name]: value
        })
    }

    function handleSubmit() {

        Swal.fire({
            width: '50vw',
            //icon:'info',
            title: t('Cgu'),
            html: `<div class="text-start" style="max-height: 50vh; height: 50vh;">${cguText}</div>`,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "I agree!",
            cancelButtonText: "I don't agree!"
        }).then(result => {

            if(result.isConfirmed) {

                const form_data = new FormData();
                searchParams.forEach((value, key) => { form_data.append(key, value) })
                Object.keys(usagerData).map(key => {

                    if(!["city_id", "city_name"].includes(key)) {

                        form_data.append(key, usagerData[key])
                    }
                })

                if(usagerData.city_name) { form_data.append('city_id', usagerData.city_name); }
                else { form_data.append('city_id', usagerData.city_id); }
        
                setIsProcessingFormData(true)
                axios.post(`/api/user/register`, form_data)
                .then(r => r.data)
                .then(response => {
        
                    setTimeout(() => {
        
                        setIsProcessingFormData(false);
                        login({setErrors, setStatus, username: response.username, password: usagerData.password})
            
                        toast.success(response.message)
                        setIsRegistred(true)
                    }, 1000)
                })
                .catch(error => {
        
                    setIsProcessingFormData(false)
                    setErrors(Object.values(error.response.data.errors).flat())
                  })
            }
        })

    }

    return <>
        <div className="row">
            <div className="col-lg-8 card shadow py-6 mx-auto position-relative">
                {isRegistred && <div className="alert alert-success">{t("Registered Verify")}</div>}
                {!isRegistred && <>{ isProcessingFormData && <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100 bg-secondary bg-opacity-10" style={{ zIndex: 9999, marginTop: '-1.5rem !important',marginLeft: '-12px' }}>
                    <div className="spinner-border" role="status">
                    <span className="visually-hidden">{t("Loading...")}</span>
                    </div>
                </div>}
                <ValidationErrors errors={errors} />
                <RegisterUsagerInputs setUsagerData={setUsagerData} formData={usagerData} handleFormDataChange={handleUsagerDataChange} errors={errors} />
                {/* <div className="row my-3">
                    <div className="col-md-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" onChange={e => (setAcceptTocs(!acceptTocs))} checked={acceptTocs} id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                            <a href="javascript:void(0)" onClick={openPolicyBox}>{t("Legacy & Policy")}</a>
                        </label>
                        </div>
                    </div>
                </div> */}
                    <div className="row mt-3">
                        <div className="col-md-12 text-center">
                            <button type="button" className="btn btn-primary" disabled={isProcessingFormData} onClick={handleSubmit}>{t("Save")}</button>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    </>
}

export default Register


































