import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import HelperContext from './contexts/HelperContext';
import {helpers} from './contexts/HelperContext';
import './i18next.conf'
import { GoogleContextProvider } from './contexts/GoogleContext';

ReactDOM.render(
  <React.StrictMode>
    <HelperContext.Provider value={helpers}>
      <GoogleContextProvider>
        <App />
      </GoogleContextProvider>
    </HelperContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
