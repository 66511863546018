import { React } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../hooks/auth";
import ValidationErrors from "../../../ValidationErrors";
import Select from "react-select";
import Modal, { ModalBody, ModalHeader } from "../../../Modal";
import GoogleMap from "../../../GoogleMap";
import jQuery from "jquery";

window.$ = window.jQuery = jQuery;
const AnnuaireLocation = ({
    errors, annuaireData, handleAnnuaireChange, handleCountryChange,
    setAnnuaireData, countries, cities, UpdateButton
    }) => {

    const { user } = useAuth()

    const { t } = useTranslation()

    function updateLocationInfo(result) {

        if(result.address =='' || result.address =='Unnamed Road'){
            
            result.address = "NC";
        }

        jQuery("#choose-address-modal .btn-close").trigger('click')
        setAnnuaireData({
            ...annuaireData, 
            lat: result.lat, 
            lng: result.lng, 
            adresse: result.address
        })
    }

    return <div className="row">
        <ValidationErrors errors={errors} />

        <div className="col-sm-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="country_id">{t("Country")}</label>
                <Select placeholder={t('Choose')} value={countries.filter(country => country.value == (annuaireData.country_id))[0]} options={countries} onChange={(option) => {

                    handleCountryChange(option)
                }} />
            </div>
        </div>

        <div className="col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="city_id">{t("City")}</label>
                {
                    cities.length ? <Select placeholder="Choix ville" 
                        defaultValue={cities.filter(city => city.value == annuaireData.city_id)[0] || ''} 
                        options={cities} 
                        onChange={(option) => handleAnnuaireChange({target: {name: "city_id", value: option.value}})} />
                                    : <select value="" className="form-select" disabled><option value="">No cities found!</option></select>
                }
            </div>
        </div>
        
        <div className="col-auto">
            <div className="mb-3">
                <label className="form-label" htmlFor="cp">{t("Cp")}</label>
                <input type="number" name="cp" id="cp" className="form-control" onChange={handleAnnuaireChange} value={annuaireData.cp} />
            </div>
        </div>

        <div className="col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="adresse">{t("Address")}</label>
                <div className="input-group">
                    <input type="text" name="adresse" id="adresse" className="form-control" onChange={handleAnnuaireChange} value={annuaireData.adresse} />
                    <button type="button" className="btn btn-primary" data-bs-target="#choose-address-modal" data-bs-toggle="modal">
                        <i className="bi bi-map"></i>
                    </button>
                </div>
                <input type="hidden" id="map_address" />
            </div>
        </div>

        <div className="col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="adresse_suite">{t("Addressnext")}</label>
                <input type="text" name="adresse_suite" id="adresse_suite" className="form-control" onChange={handleAnnuaireChange} value={annuaireData.adresse_suite} />
            </div>
        </div>

        <div className="col-auto">
            <div className="mb-3">
                <label className="form-label" htmlFor="csbp">{t("CSBP")}</label>
                <input type="number" name="csbp" id="csbp" className="form-control" onChange={handleAnnuaireChange} value={annuaireData.csbp} />
            </div>
        </div>
        <UpdateButton />

        <Modal id="choose-address-modal">
            <ModalHeader>{t("Move the marker at your desire location")}</ModalHeader>
            <ModalBody>
                <GoogleMap 
                    address={annuaireData.adresse} 
                    latitude={annuaireData.lat} 
                    longitude={annuaireData.lng} 
                    askIfWantsToUpdate="true"
                    ifConfirmed={(result) => { updateLocationInfo(result) }} 
                    autocompleteInput="#map_address" 
                    forceLatLng="true" />
            </ModalBody>
        </Modal>
    </div>
}

export default AnnuaireLocation