import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/auth";
import Modal from "../../Modal";
import MoveForwardButton from "../../MoveForwardButton";
import Settings from "../../Settings";
import Step from "../../Step";
import {toast} from "react-toastify"
import axios from "../../../lib/axios";
import bootstrap from  'bootstrap/dist/js/bootstrap';
import $ from "jquery";
import { useHelpers } from "../../../hooks/helpers";

const PreviewPdf = ({
        step, 
        activeStep, 
        moveForward, 
        formResponse, 
        etimbreIntitule, 
        adminstratifIntitule, 
        formData,
        getFormData,
        formatFormData,
        isProcessingFormData, 
        setIsProcessingFormData,
        setFormData
    }) => {

        const [isSettingsReady, setIsSettingsReady] =  useState(false)
        const [showSettings, setShowSettings] = useState(false)
        const {user} = useAuth()
        const {t} = useTranslation()
        const {totalPayable} = useHelpers()

        const refreshPreview = (openModal = false, newFormData = null) => {

            if(etimbreIntitule.id !== formData.type_doc_id && !formData.transactionId && totalPayable(formData)) {
        
                toast.error(t('Please pay before preview the document'))
                return;
            }
        
            let form_data = getFormData()
            if(newFormData) { 
            
            form_data = formatFormData(newFormData)
            //Object.keys(_settings_).forEach(function (key) { form_data.append(`settings[${key}]`, _settings_[key]) });
            }
        
            form_data.delete('form_data')
            if(user.usager) { form_data.append('usager_id', user.usager.id) }
            setIsProcessingFormData(true)
            axios.post(`/api/qrcode-preview?group_name=docs-types&group_name_value=`+form_data.get('type_doc_id'), form_data)
            .then(r => r.data)
            .then(data => {
        
            setIsProcessingFormData(false)
            if(newFormData) {
        
                data = {
                ...newFormData,
                previews: [
                    ...newFormData.previews,
                    data
                ]
                }
            } else {
        
                data = {
                ...formData,
                previews: [
                    ...formData.previews,
                    data
                ]
                }
            }
            
            //if(_settings_) { data['settings'] = {...data['settings'], ..._settings_} }
            setFormData(data)
            setIsSettingsReady(true)
            if(openModal) {
        
                const modal = new bootstrap.Modal("#preview-modal");
                modal.show()
            }
            }).catch(err => {
        
            setIsProcessingFormData(false)
            })
        }

        const openPreviewModal = () => { refreshPreview(true) }

        const toggleSettings = () => { setShowSettings(!showSettings) }

        useEffect(() => {

            if(user && formData.type_doc_id && activeStep == step) {
        
                $('#files').on('change', function () {

                    if($(this).get(0).files.length) {
                      setTimeout(() => {
            
                        $('[data-bs-target="#document-form"]').trigger('click');
                      }, 1500);
                    }
                });

                axios.get('api/settings?group_name=docs-types&group_name_value='+formData.type_doc_id)
                    .then(r => r.data)
                    .then(data => { 
            
                        let _data = {...data};
                        _data["show_sign_file_stamp"] = _data["show_sign_file_stamp"] == 1;
                        _data["show_sign_file_logo"] = _data["show_sign_file_logo"] == 1;
                        _data["show_sign_file_initials"] = _data["show_sign_file_initials"] == 1;
                        _data["show_sign_file_first_name"] = _data["show_sign_file_first_name"] == 1;
                        _data["show_sign_file_last_name"] = _data["show_sign_file_last_name"] == 1;
                        _data["show_sign_file_signature"] = _data["show_sign_file_signature"] == 1;
                        
            
                        if(localStorage.getItem('__preview__settings__'+formData.type_doc_id)) {
            
                            _data = {
                                ..._data,
                                ...JSON.parse(localStorage.getItem('__preview__settings__'+formData.type_doc_id)),
                                show_sign_file_stamp: _data["show_sign_file_stamp"],
                                show_sign_file_logo: _data["show_sign_file_logo"],
                                show_sign_file_initials: _data["show_sign_file_initials"],
                                show_sign_file_first_name: _data["show_sign_file_first_name"],
                                show_sign_file_last_name: _data["show_sign_file_last_name"],
                                show_sign_file_signature: _data["show_sign_file_signature"]
                            }
                            
                            setFormData({...formData, settings: {..._data}})
                        } else {  setFormData({...formData, settings: {..._data}}) }
                        
                        localStorage.setItem('__preview__settings__'+formData.type_doc_id, JSON.stringify(_data))
                    })
            }
        }, [user, formData.type_doc_id, activeStep])

        return <>
            <Step isActive={activeStep === step} step={step}>
                <button onClick={openPreviewModal} type="button" disabled={isProcessingFormData || activeStep < step || formResponse.signer !== ''} className='btn w-100 btn-primary px-4 my-1'>
                    <i className="bi bi-eyeglasses"></i> {t('Preview')}
                </button>
            </Step>


            {(user && isSettingsReady && formData.previews.length) ? 
                <Modal id="preview-modal" size="xl">
                    <div className='modal-header'>
                        <h5 className='modal-title'>
                        {etimbreIntitule.id !== formData.type_doc_id && <button type="button" onClick={toggleSettings} className='btn btn-sm'><i className='bi bi-gear'></i></button> }
                            {t('Preview')}
                        </h5>
                        <button type="button" className='btn-close d-none' data-bs-dismiss="modal" aria-label='Close'></button>
                    </div>

                    <div className='modal-body'>
                        {
                            formData.type_doc_id && 
                            etimbreIntitule.id !== formData.type_doc_id && 
                            <Settings 
                                formData={formData} 
                                setFormData={setFormData} 
                                setIsSettingsReady={setIsSettingsReady} 
                                isSettingsReady={isSettingsReady} 
                                settings={formData.settings} 
                                setSettings={(setting) => {

                                    localStorage.setItem('__preview__settings__'+formData.type_doc_id, JSON.stringify({...formData.settings, ...setting})); 
                                    setFormData({...formData, settings: {...formData.settings, ...setting}})
                                }} 
                                refreshPreview={refreshPreview} 
                                showSettings={showSettings} 
                                type_doc_id={formData.type_doc_id} />
                            }

                        <iframe title={t('Document Preview')} className='w-100 mt-5' style={{ height: '400px' }} src={formData.previews[formData.previews.length-1].url}></iframe>
                    </div>

                    <div className="modal-footer">
                        <MoveForwardButton moveForward={() => { $('#preview-modal [data-bs-dismiss="modal"]').trigger('click'); moveForward(step)}} />
                    </div>
                </Modal>
                : ''
            }
        </>
}

export default PreviewPdf