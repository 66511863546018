import {React} from 'react'
import { Outlet } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import Footer from "./Footer"
import Navigation from "./Navigation"

export default () => {

    return <>
    <div className="container-fluid p-0">
      <Navigation/>
      <main className="container" style={{ minHeight: '70vh', marginBottom: '4vh', marginTop: 'auto' }}>
        <ToastContainer autoClose={2500} closeButton={true} closeOnClick={true} />
        <Outlet />
      </main>
      <Footer />
    </div>
    </>
}