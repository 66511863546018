import { React, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../components/Table";
import TableTr from "../components/TableTr";
import axios from "../lib/axios";
import ValidationErrors from "../components/ValidationErrors";
import AuthSessionStatus from "../components/AuthSessionStatus";
import DataTable from "datatables.net";
import { fallbackLng } from "../i18next.conf";
const $ = require('jquery')
//require('datatables.net')
require('datatables.net-bs5')

const MyUsagers = () => {

    const {t} = useTranslation()
    const [fileChoosed, setFileChoosed] = useState('')
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const [showImportForm, setShowImportForm] = useState(false)
    const fileInput = useRef()

    const  handleChoosedFile = (e) => {

        if(e.target.files.length) { setFileChoosed(e.target.files[0]) }
        else { setFileChoosed('') }
    }

    const handleSubmit = () => {

        const form_data = new FormData();
        setIsSaving(true)
        form_data.append('file', fileChoosed)
        axios.post('/api/import-usager', form_data)
            .then(r => r.data)
            .then(data => {

                setIsSaving(false)
                setStatus(data.message)
                fileInput.current.files = ''
            })
            .catch(error => {

                setErrors(Object.values(error.response.data.errors).flat())
                setIsSaving(false)
            })
    }

    useEffect(() => {

        if(!showImportForm) {

            $('#my-usagers').DataTable({
                serverSide: true,
                processing:true,
                ajax: {
                    url: `${process.env.REACT_APP_ENDPOINT}/api/my-usagers`,
                    type: 'GET',
                    beforeSend: function (request) {
                        
                        const token = localStorage.getItem('token')
                        const locale = localStorage.getItem('i18nextLng') || fallbackLng
                        request.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
                        request.setRequestHeader('Authorization', token && `Bearer ${token}`)
                        request.setRequestHeader('X-Locale', locale.substr(0, 2))
                    }
                },
                columns: [
                    // {data: 'civilite'},
                    {data: 'icon', className: 'text-center', orderable: false},
                    {data: 'name'},
                    {data: 'email'},
                    {data: 'mobile'},
                ]
            })
        }
    }, [showImportForm])

    return <div className="row">
        <div className="col-md-12 mb-3">
            <button type="button" className="btn btn-success btn-sm float-end" onClick={() => setShowImportForm(!showImportForm)}>
                <i className="bi bi-user"></i> {!showImportForm ? t("Import Usagers") : t("List usagers")}
            </button>
        </div>
            {showImportForm ? <div className="col-md-12">
                <AuthSessionStatus className="alert alert-info mb-4" status={status} />
                {!!errors.length && <ValidationErrors errors={errors} />}
                <div className="mb-3">
                    <label htmlFor="import" className="form-label">{t("Import")}</label>
                    <div className="input-group">
                        <input type="file" id="import" ref={fileInput} className="form-control" onChange={handleChoosedFile} accept=".csv" />
                        <button type="submit" disabled={isSaving} onClick={handleSubmit} className="btn btn-success"><i className="bi bi-send"></i> {t("Submit")}</button>
                    </div>
                    <small className="text-muted">{t("Only csv file allowed")}. <a href="/usager-import-template.csv" download>{t("Click here to download template file.")}</a></small>
                </div>

                <Table className="table table-borderless table-hover table-sm table-striped border w-100">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t("Column")}</th>
                            <th>{t("Details")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-bottom">
                            <td>1</td>
                            <td>{t("Denomination")}</td>
                            <td>{t("Require Particular")}</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>2</td>
                            <td>{t("First name")}</td>
                            <td>{t("Required")}</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>3</td>
                            <td>{t("Last name")}</td>
                            <td>{t("Required")}</td>
                        </tr>

                        <tr className="border-bottom">
                            <td>4</td>
                            <td>{t("Email")}</td>
                            <td>{t("Required")}</td>
                        </tr>
                    </tbody>
                </Table>
            </div> : <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <Table id="my-usagers">
                                <thead>
                                    <TableTr>
                                        {/* <th>{t("Civilite")}</th> */}
                                        <th>{t("Icon")}</th>
                                        <th>{t("Identity")}</th>
                                        <th>{t("Email")}</th>
                                        <th>{t("Mobile")}</th>
                                    </TableTr>
                                </thead>
                                <tbody></tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
}

export default MyUsagers