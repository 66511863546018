import {React} from 'react'

const AuthCard =  ({children}) => {

    return <div className="row">
    <div className="col-lg-4 offset-lg-4 col-sm-12 py-6" style={{ minHeight: '200px' }}>
        <div className="card py-3 shadow mx-auto position-relative">
            <div className="card-body">{children}</div>
        </div>
    </div>
</div>
}

export default AuthCard