import axios from "../lib/axios"
import { useState } from "react"
import { toast } from "react-toastify"
import UoInputs from "../components/UoInputs"
import ValidationErrors from "../components/ValidationErrors"
import { React } from 'react'
import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation()
    const [isProcessingFormData, setIsProcessingFormData] = useState(false)
    const [isRegistred, setIsRegistred] = useState(false)

    const [formData, setFormData] = useState({
        filiation_id: '',
        country_id: '',
        city_id: '',
        url: '',
        num_compte: '',
        telephone: '',
        fax: '',
        mobile: '',
        email: '',
        adresse: '',
        adresse_suite: '',
        csbp: '',
        typevoie_id: '',
        trigramme: '',
        raison_sociale: '',
        formejuridique_id: '',
        activite_id: '',
        type_id: '',
    })

    const [errors, setErrors] = useState({})
    function handleChange(name, value) {

        setFormData({
            ...formData,
            [name]: value
        })
    }

    function handleSubmit() {

        const form_data = new FormData();
        Object.keys(formData).map(key => {
            
            form_data.append(key, formData[key])
        })

        setIsProcessingFormData(true)
        axios.post(`/api/uos`, form_data)
        .then(r => r.data)
        .then(response => {

            setTimeout(() => {

                setIsProcessingFormData(false);
                if(response.errors) {
    
                    setErrors(response.errors)
                    toast.error(response.message)
                } else {
    
                    toast.success(response.message)
                    setIsRegistred(true)
                }
            }, 1000)
        })
        .catch(error => {

            setIsProcessingFormData(false)
            setErrors(Object.values(error.response.data.errors).flat())
          })
    }

    return <>
        <div className="row">
            <div className="col-lg-8 card shadow py-6 mx-auto position-relative">
                {isRegistred && <div className="alert alert-success">{t('Merci et à Bientôt / Thank you for registering with us!')}</div>}
                {!isRegistred && <>{ isProcessingFormData && <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100 bg-secondary bg-opacity-10" style={{ zIndex: 9999, marginTop: '-1.5rem !important',marginLeft: '-12px' }}>
                    <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}
                    <ValidationErrors errors={errors} />
                    <UoInputs uoData={formData} handleChange={handleChange} errors={errors} />
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <button type="button" className="btn btn-primary" disabled={isProcessingFormData} onClick={handleSubmit}>{t('Save')}</button>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    </>
}