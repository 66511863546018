import {React} from 'react'

export default ({status, className, ...props}) => (
    <>
        {status && (
            <div className={className} {...props}>
                {status}
            </div>
        )}
    </>
)