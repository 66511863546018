import { React, useContext, useEffect, useState } from "react"
import Select from "react-select"
import HelperContext from "../contexts/HelperContext"
import axios from "../lib/axios"
import {useAuth} from "../hooks/auth"
import { useTranslation } from 'react-i18next'

const UoInputs = ({uoData, handleChange, errors = {}}) => {

    const [countries, setCountries] = useState([])
    const [cities, setCities] = useState([])
    const [typesUos, setTypesUos] = useState([])

    const [activites, setActivites] = useState([])
    const [formejuridiques, setFormejuridiques] = useState([])
    const [typevoies, setTypevoies] = useState([])

    const helpers = useContext(HelperContext)
    const { t } = useTranslation()
    const {user} = useAuth()
    useEffect(async () => {

        const [typesUosList, activitesList, formejuridiquesList, typevoiesList, countriesList] = await Promise.all([
            axios.get(`/api/intitules/types-uos/by-group`).then(r => r.data),
            axios.get(`/api/intitules/activites/by-group`).then(r => r.data),
            axios.get(`/api/intitules/formes-juridiques/by-group`).then(r => r.data),
            axios.get(`/api/intitules/types-voies/by-group`).then(r => r.data),
            axios.get(`/api/countries`).then(r => r.data),
        ])

        setCountries(countriesList.map(country => {

            return {'value': country.id, 'label': country.name}
        }))

        setTypesUos(typesUosList.map(intitule => {

            return {'value': intitule.id, 'label': intitule.libelle}
        }))

        setActivites(activitesList.map(intitule => {

            return {'value': intitule.id, 'label': intitule.libelle}
        }))

        setFormejuridiques(formejuridiquesList.map(intitule => {

            return {'value': intitule.id, 'label': intitule.libelle}
        }))

        setTypevoies(typevoiesList.map(intitule => {

            return {'value': intitule.id, 'label': intitule.libelle}
        }))
    }, [])

    function handleCountryChange (country) {

        handleChange("country_id", country.value)
    }

    useEffect(()=> {

        if(uoData.country_id) {

            axios.get(`/api/countries/${uoData.country_id}/cities`)
                .then(r => r.data)
                .then(data => {
                    
                    if(!data.length) {
    
                        handleChange({ target: {name: "city_id", value: ''} })
                    }
    
                    setCities(data.map(city => {
    
                        return {'value': city.id, 'label': city.name}
                    }))
                });
        }
    }, [uoData.country_id])

    return <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="accordion  accordion-flush" id="uo-accordion">
                {user.usager.category_constante !== "LIB_CATEGORIE_PARTICULIER" && <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#information" aria-expanded="true" aria-controls="information">
                            {t("Administrative Information")}
                        </button>
                    </h2>

                    <div className="accordion-collapse collapse show" id="information" data-bs-parent="#uo-accordion">
                        <div className="accordion-body">
                            {/* <div className="row">
                            <div className="col-xs-12 col-sm-4 col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="num_compte">
                                        <abbr className="text-danger" data-id="num_compte" title="Obligatoire">*</abbr>Numéro Compte </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">##</span>
                                        </div>
                                        <input type="text" id="num_compte" name="num_compte" placeholder="Numéro de compte" value={uoData.num_compte} className={"form-control text-uppercase "+(errors.num_compte && "is-invalid")} required onInput={(e) => handleChange("num_compte", e.target.value)}/>
                                        {errors.num_compte && <span className="invalid-feedback">{errors.num_compte[0]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            <div className="row">
                                {!!typesUos.length && <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        {helpers.required_label('type_id', t('Type Uo'))}
                                        <Select defaultValue={typesUos.filter(type => type.value == uoData.type_id)[0] || ''} className={(errors.type_id && "is-invalid")} options={typesUos} onChange={(option) => handleChange('type_id', option.value)} />
                                        {errors.type_id && <span className="invalid-feedback">{errors.type_id[0]}</span>}
                                    </div>
                                </div>}
                                {!!activites.length && <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="activite_id">{t("Activite")}</label>
                                        <Select defaultValue={activites.filter(activite => activite.value == uoData.activite_id).length ? activites.filter(activite => activite.value == uoData.activite_id)[0] : ''} className={(errors.activite_id && "is-invalid")} options={activites} onChange={(option) => handleChange('activite_id', option.value)} />
                                        {errors.activite_id && <span className="invalid-feedback">{errors.activite_id[0]}</span>}
                                    </div>
                                </div>}
                            </div>
                            <div className="row">
                                {!!formejuridiques.length && <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="formejuridique_id">{t("Forme Juridique")}</label>
                                        <Select defaultValue={formejuridiques.filter(formejuridique => formejuridique.value == uoData.formejuridique_id).length ? formejuridiques.filter(formejuridique => formejuridique.value == uoData.formejuridique_id)[0] : ''} options={formejuridiques} onChange={(option) => handleChange('formejuridique_id', option.value)} />
                                        {errors.formejuridique_id && <span className="invalid-feedback">{errors.formejuridique_id[0]}</span>}
                                    </div>
                                </div>}

                                <div className="col-xs-12 col-sm-6 col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="raison_sociale">
                                            <abbr className="text-danger" data-id="raison_sociale" title="Obligatoire">*</abbr>{t("Dénomination")}</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="bi bi-bank"></i></span>
                                            </div>
                                            <input type="text" id="raison_sociale" name="raison_sociale" value={uoData.raison_sociale} required placeholder="Raison Sociale / Dénomination" className={"form-control text-uppercase "+(errors.raison_sociale && "is-invalid")} onInput={(e) => handleChange("raison_sociale", e.target.value)} />
                                            <div className="input-group-append">
                                                <span className="input-group-text"><i className="bi bi-question-circle"></i></span>
                                            </div>
                                            {errors.raison_sociale && <span className="invalid-feedback">{errors.raison_sociale[0]}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-2 col-md-2">
                                    <div className="mb-3">
                                        <label htmlFor="trigramme">{t("Acronyme")}</label>
                                        <input type="text" id="trigramme" name="trigramme" value={uoData.trigramme} placeholder="Trigramme / Sigle" className={"form-control text-uppercase "+(errors.trigramme && "is-invalid")} onInput={(e) => handleChange("trigramme", e.target.value)} />
                                        {errors.trigramme && <span className="invalid-feedback">{errors.trigramme[0]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#locinfo" aria-expanded="true" aria-controls="locinfo">
                        {t("Location Information")}
                        </button>
                    </h2>
                    <div className="accordion-collapse collapse" id="locinfo"  data-bs-parent="#uo-accordion">
                        <div className="accordion-body">
                            <div className="row">
                                {!!countries.length && <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        {helpers.required_label('country_id', t('Pays'))}
                                        <div className="input-group">
                                            <div className="input-group-text"><i className="bi bi-globe2"></i></div>
                                            <div className="flex-grow-1">
                                                <Select defaultValue={countries.filter(country => country.value == uoData.country_id)[0]} className={errors.country_id && "is-invalid"} options={countries} onChange={handleCountryChange} />
                                                {errors.country_id && <span className="invalid-feedback">{errors.country_id[0]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        {helpers.required_label('city_id', t('Ville'))}
                                        <div className="input-group">
                                            <div className="input-group-text"><i className="bi bi-signpost-split"></i></div>
                                            <div className="flex-grow-1">
                                                {
                                                    cities.length ? <Select defaultValue={cities.filter(city => city.value == uoData.city_id)[0] || ''} className={errors.city_id && "is-invalid"} options={cities} onChange={(option) => handleChange("city_id", option.value)} />
                                                                    : <select className="form-select" disabled><option value="">No cities found!</option></select>
                                                }
                                                {errors.city_id && <span className="invalid-feedback d-block">{errors.city_id[0]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        {helpers.required_label('typevoie_id', t('Type Voie'))}
                                        {!!typevoies.length && <Select defaultValue={typevoies.filter(typevoie => typevoie.value == uoData.typevoie_id)[0] || ''} className={errors.typevoie_id && "is-invalid"} options={typevoies} onChange={(option) => handleChange('typevoie_id', option.value)} />}
                                        {errors.typevoie_id && <span className="invalid-feedback">{errors.typevoie_id[0]}</span>}
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6 col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="adresse">
                                            <abbr className="text-danger" data-id="adresse" title="Obligatoire">*</abbr>Adresse</label>
                                        <div className="input-group">
                                            <input type="text" id="adresse" name="adresse" value={uoData.adresse} placeholder="Adresse" className={"form-control "+(errors.adresse && "is-invalid")} onInput={(e) => handleChange("adresse", e.target.value)} />
                                            <div className="input-group-prepend">
                                            <span className="input-group-text add-on">
                                                {/* <a href="javascript:void(0);" className="address-map-modal" data-modal="#address-map-modal" data-href="http://docsigner.local/get-address-map-modal"> */}
                                                <i className="bi bi-geo-alt"></i>
                                                {/* </a> */}
                                            </span>
                                            </div>
                                            {errors.adresse && <span className="invalid-feedback">{errors.adresse[0]}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="adresse_suite">{t("Adresse Suite")}</label>
                                        <input type="text" id="adresse_suite" name="adresse_suite" value={uoData.adresse_suite || ''} placeholder="Complément d'adresse" className="form-control" onInput={(e) => handleChange("adresse_suite", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="csbp">{t("CSBP")}</label>
                                        <input type="text" id="csbp" name="csbp" value={uoData.csbp || ''} className="form-control  text-uppercase" onInput={(e) => handleChange("csbp", e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <div className="accordion-header" id="uo1-accordion">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#coninfo" aria-expanded="true" aria-controls="coninfo">
                        {t("Contact Information")}
                        </button>
                    </div>
                    <div className="accordion-collapse collapse" id="coninfo"  data-bs-parent="#uo-accordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="telephone">{t("Phone")}</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="bi bi-phone"></i></span>
                                            </div>
                                            <input type="text" id="telephone" name="telephone" value={uoData.telephone || ''} placeholder="Téléphone" className="form-control bfh-phone" onInput={(e) => handleChange('telephone', e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="fax">{t("Fax")}</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="bi bi-file-break"></i></span>
                                            </div>
                                            <input type="text" id="fax" name="fax" value={uoData.fax || ''} placeholder="Fax." className="form-control bfh-phone" onInput={(e) => handleChange('fax', e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        {/* <label htmlFor="Mobile">Mobile<small>(+Indicatif)</small></label> */}
                                        {helpers.required_label('Mobile', t('Mobile'))}<small>(+Indicatif)</small>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="bi bi-phone"></i></span>
                                            </div>
                                            <input type="text" name="mobile" id="mobile" className="form-control" value={uoData.mobile || ''} onInput={(e) => handleChange("mobile", e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="email">{t("Email")}</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="bi bi-envelope"></i></span>
                                            </div>
                                            <input type="text" name="email" id="email" value={uoData.email || ''} placeholder="email" className="form-control" onInput={(e) => handleChange("email", e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-8 col-md-8">
                                    <div className="mb-3">
                                        <label htmlFor="url">{t("Url")}</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="bi bi-globe"></i></span>
                                            </div>
                                            <input type="text" name="url" id="url" value={uoData.url || ''} placeholder="url" className="form-control" onInput={(e) => handleChange("url", e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="accordion-item">
                    <div className="accordion-header" id="uo1-accordion">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#logoinfo" aria-expanded="true" aria-controls="logoinfo">
                            Logo&Cachet
                        </button>
                    </div>
                    <div className="accordion-collapse collapse" id="logoinfo" data-bs-parent="#uo-accordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="logo">Logo</label>
                                        <div className="input-group">
                                            <input type="file" name="logo" id="logo" className="form-control" accept="image/*"  onChange={(e) => handleChange("logo", e.target.files[0])} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="stamp">Cachet</label>
                                        <div className="input-group">
                                            <input type="file" name="stamp" id="stamp" className="form-control" accept="image/*" onChange={(e) => handleChange("stamp", e.target.files[0])} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
}

export default UoInputs