import React, { useContext, useEffect, useState } from "react"
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../Modal"
import jQuery from "jquery"
import { useTranslation } from 'react-i18next'
import HelperContext from "../../contexts/HelperContext"

const VisaDocumentNewAnnuaire = ({setDocumentCheckinAnnuaires, documentCheckinAnnuaires, intitule, visaDocuments, setVisaDocuments}) => {

    const [email, setEmail] = useState('')
    
    const [raisonSociale, setRaisonSociale] = useState('')
    
    const [mobile, setMobile] = useState('')

    const [phone, setPhone] = useState('')

    const { t } = useTranslation()
    
    useEffect(() => {

      if( visaDocuments[intitule.constante] && visaDocuments[intitule.constante]['annuaire'] ) {

          setEmail(visaDocuments[intitule.constante].annuaire.email)
          setRaisonSociale(visaDocuments[intitule.constante].annuaire.raison_sociale)
          setMobile(visaDocuments[intitule.constante].annuaire.mobile)
          setPhone(visaDocuments[intitule.constante].annuaire.phone)

      }
    }, [])

    const saveAndGoBack = () => {

      const data = {
        phone, mobile, email, 
        raison_sociale: raisonSociale
      };
      
      //data["id"] = JSON.stringify(data)
      setVisaDocuments({
      ...visaDocuments,
      [intitule.constante]: {
          file: (visaDocuments[intitule.constante] ? visaDocuments[intitule.constante]['file'] : null),
          annuaire: JSON.stringify(data)
      }})

      setDocumentCheckinAnnuaires({
        ...documentCheckinAnnuaires,
        [intitule.constante]: [
          ...documentCheckinAnnuaires[intitule.constante],
          {
            id: JSON.stringify(data),
            raison_sociale: data.raison_sociale
          }
        ]
      })

      setTimeout(() => {

        jQuery(`#${intitule.constante}_go_back`).trigger('click')
      }, 300)
    }

    const helpers = useContext(HelperContext)

    return <Modal key={intitule.constante} id={intitule.constante+"_modal"}>
    <ModalHeader>
      <button className="btn btn-link d-none" id={intitule.constante+"_go_back"} data-bs-toggle="modal" data-bs-target="#upload-file">
        <i className='bi bi-arrow-return-left'></i>
      </button>
    </ModalHeader>
    
    <ModalBody>
      <div className='row'>
        <div className='col-md-12'>
          <div className='mb-3'>
            {helpers.required_label("raison_sociale", "Denomination")}
            <input type="text" className='form-control' value={raisonSociale} onChange={e => setRaisonSociale(e.target.value)} />
          </div>
        </div>

        <div className='col-md-12'>
          <div className='mb-3'>
            {helpers.required_label("email", "Email")}
            <input type="text" className='form-control' value={email} onChange={e => setEmail(e.target.value)} />
          </div>
        </div>

        <div className='col-md-12'>
          <div className='mb-3'>
            {helpers.required_label("phone", "phone")}
            <input type="text" className='form-control' value={phone} onChange={e => setPhone(e.target.value)} />
          </div>
        </div>

        <div className='col-md-12'>
          <div className='mb-3'>
            <label className='form-label' htmlFor='mobile'>{t('Mobile')}</label>
            <input type="text" className='form-control' value={mobile} onChange={e => setMobile(e.target.value)} />
          </div>
        </div>
      </div>
    </ModalBody>

    <ModalFooter>
      <button type="button" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#upload-file">
        <i className='bi bi-arrow-return-left'></i> {t('Go back')} 
      </button>

      <button type="button" disabled={!phone || !email || !raisonSociale} className="btn btn-primary" onClick={saveAndGoBack}>
          <i className="bi bi-save"></i> {t('Save & Go back')}
      </button>
    </ModalFooter>
  </Modal>
}

export default VisaDocumentNewAnnuaire