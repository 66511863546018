import { React } from "react"
import { useTranslation } from "react-i18next"
import Step from "../../Step"

const SubmitForm = ({
        activeStep,
        isShowingPreviewPdfStep,
        adminstratifIntitule,
        etimbreIntitule,
        formData,
        submitForm,
        isProcessingFormData,
        formResponse,
        step
    }) => {

        const { t } = useTranslation()

        return <Step step={step} isActive={activeStep === step}>
            <button className="btn w-100 px-4" onClick={submitForm} disabled={isProcessingFormData || activeStep < step || formResponse.signer !== ''}>  
                <i className="bi bi-qr-code-scan"></i>&nbsp;{adminstratifIntitule.id === formData.type_doc_id ? t('Save Request') : t('Certify & Send')}..
            </button>
        </Step>

        /* return <Step step={!isShowingPreviewPdfStep ? step : step-1} isActive={!isShowingPreviewPdfStep ? (activeStep >= step) : (activeStep >= (step-1))}>
            <button className="btn w-100 px-4" onClick={submitForm} disabled={isProcessingFormData || (!isShowingPreviewPdfStep ? activeStep < step : activeStep < (step-1)) || formResponse.signer !== ''}>  
                <i className="bi bi-qr-code-scan"></i>&nbsp;{adminstratifIntitule.id === formData.type_doc_id ? t('Save Request') : t('Certify & Send')}..
            </button>
        </Step> */

        /* return <Step step={step} isActive={activeStep >= 4} step={![adminstratifIntitule.id, etimbreIntitule.id].includes(formData.type_doc_id) ? '5' : '4'}>
            <button className="btn w-100 px-4" onClick={submitForm} disabled={isProcessingFormData || activeStep < 4 || formResponse.signer !== ''}>  
                <i className="bi bi-qr-code-scan"></i>&nbsp;{t('Certify & Send')}..
            </button>
        </Step> */
}

export default SubmitForm