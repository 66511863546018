import { React } from "react"
import { useTranslation } from "react-i18next"

const MoveForwardButton = ({disabled, moveForward, className = 'btn btn-primary'}) => {

    const { t } = useTranslation()
    //const [isClicked, setIsClicked] = useState(false)

    return <button disabled={disabled} onClick={() => {

            //setIsClicked(true)
            moveForward()
        }} className={className}>
        {t("Next")}
    </button>
}

export default MoveForwardButton