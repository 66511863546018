import { React, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import useNotification from '../hooks/notification'
import axios from '../lib/axios'
import {toast} from "react-toastify"

const Notifications = () => {

    const { t } = useTranslation()
    
    const [notifications, setNotifications] = useState([])
    
    const [notificationsToDelete, setNotificationsToDelete] = useState([])

    const [isProcessing, setIsProcessing] = useState(false)

    const [selectedFrom, setSelectedFrom] = useState("All")

    const [selectedType, setSelectedType] = useState("unread")
    
    const [showNotification, setShowNotification] = useState(null)

    const [fromList, setFromList] = useState(["All"])
    

    const {markAsRead} = useNotification()

    
    const getNotifications = (data = {}) => {

        setIsProcessing(true)
        const form_data = new FormData();
        form_data.append('limit', 'all')
        Object.keys(data).map(k => {

            form_data.append(k, data[k])
        })

        axios.get('/api/notifications?'+new URLSearchParams(form_data).toString(),)
            .then(r => r.data)
            .then(data => {

                setFromList(["All", ...data.from_group])
                setNotifications(data.notifications)
                setTimeout(() => {

                    setIsProcessing(false)
                }, 1000)
            })
            .catch(error => {
                setIsProcessing(false)
            })
    }

    const deleteAll = () => {

        Swal.fire({
            title: t("Warning"),
            text: "Are you sure to delete?",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: t("Yes, delete it!"),
            cancelButtonText: t("No, cancel it!"),
            customClass: {
                confirmButton: "btn btn-danger mx-3",
                cancelButton: "btn btn-secondary"
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: async () => {

                return await axios.post('/api/delete-all-notifications')
                    .then(r => r.data)
                    .then(data => {

                      return data;  
                    })
                    .catch(error => {

                        Swal.showValidationMessage(`${t("Request Failed")}: ${error}`)
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {

            if(result.isConfirmed) { 
                toast.info(result.value.message);
                getNotifications();
            }
        })
    }

    const deleteSelectedNotifications = () => {

        Swal.fire({
            title: t("Warning"),
            text: t("Are you sure to delete?"),
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: t("Yes, Delete it!"),
            cancelButtonText: t("No, Cancel it!"),
            customClass: {
                confirmButton: "btn btn-danger mx-3",
                cancelButton: "btn btn-secondary"
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: async () => {

                const form_data = new FormData();
                notificationsToDelete.forEach(notification => {

                    form_data.append('notifications[]', notification) 
                })

                return await axios.post('/api/delete-notifications', form_data)
                    .then(r => r.data)
                    .then(data => {

                      return data;  
                    })
                    .catch(error => {

                        Swal.showValidationMessage(`${t("Request Failed")}: ${error}`)
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {

            if(result.isConfirmed) { 
                
                toast.info(result.value.message);
                getNotifications();
                setNotificationsToDelete([])
            }
        })
    }

    const checkAll = (e) => {

        if(e.target.checked) {

            setNotificationsToDelete(notifications.map(n => (n.id)))
        } 
        else { setNotificationsToDelete([]) }
    }

    const checkMe = (isChecked, notificationId) => {

        if(isChecked) {

            setNotificationsToDelete([...notificationsToDelete, notificationId])
        } else {
            
            const _notificationsToDelete = notificationsToDelete.filter(nId => (nId !== notificationId))
            setNotificationsToDelete(_notificationsToDelete)
        }
    }

    useEffect(() => { getNotifications() }, [])

    function handleFromChange(e) {
        
        setSelectedFrom(e.target.value)
        getNotifications({type: selectedType, from: e.target.value})
    }

    function handleTypeChange(e) {
        
        setSelectedType(e.target.value)
        getNotifications({type: e.target.value, from: selectedFrom})
    }

    return <div className='row'>
            {!showNotification ? <><div className='col-md-12 mb-4'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div>
                            <label className='form-label' htmlFor='type'>{t("Type")}</label>
                            <select value={selectedType} name='type' id='type' className="form-select" onChange={handleTypeChange}>
                                <option value="all">{t("All")}</option>
                                <option value="read">{t("Read")}</option>
                                <option value="unread">{t("Unread")}</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <div>
                            <label className='form-label' htmlFor='from'>{t("From")}</label>
                            <select value={selectedFrom} name='from' id='from' className="form-select" onChange={handleFromChange}>
                                {fromList.map(from => <option key={from} value={from}>{t(from)}</option>)}
                            </select>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='d-inline float-end'> 
                            <a href='/profile?tab=notifications' className="btn btn-primary btn-sm me-2">
                                <i className="bi bi-arrow-clockwise"></i>
                            </a>

                            {!!notificationsToDelete.length && <button onClick={deleteSelectedNotifications} className='btn btn-danger btn-sm mx-3'>{t("Delete")} {notificationsToDelete.length} {t("notifications")}</button>}
                            <button disabled={!notifications.length} onClick={deleteAll} className='btn btn-danger btn-sm'>
                                <i className='bi bi-trash2'></i> {t("Delete All")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-md-12'>
                <table className='table table-sm table-bordered table-hover'>
                    <thead>
                        <tr>
                            <th className='text-center'>
                                <input disabled={!notifications.length} type="checkbox" checked={notificationsToDelete.length === notifications.length} onChange={checkAll} />
                            </th>
                            {/* <th>{t('Url')}</th> */}
                            <th>{t('From')}</th>
                            <th>{t('Message')}</th>
                            <th style={{ minWidth: '135px' }}>{t('DateTime')}</th>
                            <th>{t('Action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isProcessing ? (notifications.length ? notifications.map(notification => <tr key={notification.id}>
                            <td className='text-center'>
                                <input type="checkbox" checked={notificationsToDelete.includes(notification.id)} onChange={(e) => checkMe(e.target.checked, notification.id)}  />
                            </td>
                            <td>{t(notification.from)}</td>
                            <td>{t(notification.data.message)}</td>
                            <td>
                                <div className='w-100 d-flex flex-nowrap'>{t(notification.date_time)}</div>
                            </td>
                            <td className='text-center'>
                                {!["#", "javascript:void(0)"].includes(notification.data.url) ? <a className='btn btn-sm btn-primary' href={notification.data.url}><i className='bi bi-link'></i></a> : ''}
                                &nbsp;
                                {!notification.read_at && <a className='btn btn-sm btn-success' onClick={(e) => {e.preventDefault(); markAsRead(notification, false, getNotifications)}}  href='#'>{t('Mark As Read')}</a>}
                                &nbsp; <button type="button" className='btn btn-info text-white btn-sm' onClick={() => setShowNotification(notification)} >
                                    <i className='bi bi-eye-fill'></i>
                                </button>
                            </td>
                        </tr>) : <tr>
                            <td className='text-center' colSpan={5}>{t("No Notifications Found!")}</td>
                        </tr>) : <tr>
                            <td className='text-center' colSpan={5}>{t("Processing")}...</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </>: <div className="row">
                <div className='col-md-12'>
                    <button type="button" className="btn btn-info btn-sm float-end text-white" onClick={() => setShowNotification(null)}><i className='bi bi-arrow-left'></i> {t("Go back")}</button>
                </div>
            
                <div className='col-md-12'>
                    {showNotification.data.subject && <h1>{showNotification.data.subject}</h1>}
                    <p><strong>{t("From")}</strong>: {showNotification.from}</p>
                    <p><strong>{t("DateTime")}</strong>: {showNotification.date_time}</p>
                    <hr />
                    {Object.keys(showNotification.data).filter(k => (!["url", "subject"].includes(k))).map(k => <p key={k}><strong>{t(k)}</strong> <span dangerouslySetInnerHTML={{ __html: showNotification.data[k] }}></span> </p>)}
                    {/* <p><strong>{t("Message")}</strong>: <br /> {showNotification.data.message}</p> */}
                </div>
            </div>}
    </div>
}

export default Notifications