import { React, useState } from 'react'
import fileDownload from 'js-file-download'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import axios from '../lib/axios'

const DownloadAttachmentButton = ({file, children, className = null, downloaded = null}) => {

  const [isDownloading, setIsDownloading] = useState(false)
  const {t} = useTranslation();
  
  const downloadAttachment = (file) => {
  
    setIsDownloading(true)
    axios.get(file.url, {
      responseType: 'blob'
    }).then(response => {

      fileDownload(response.data, file.filename)
      setIsDownloading(false)
      if(downloaded) { downloaded() }
    }).catch(error => {

      setIsDownloading(false)
      if([403, 404].includes(error.response.status)) { toast.error(t("Unauthorized Resource")) }
    })
  }

  return <button disabled={isDownloading} onClick={() => downloadAttachment(file)} title={t("Download")} type="button" className={"btn btn-primary "+className}>{children || <i className="bi bi-download"></i>}</button>
}

export default DownloadAttachmentButton