const Card = ({children, ...props}) => {

    let padding = "py-6"
    if(props.padding == "false") { padding = "" }

    return <div className={"card shadow mt-auto "+padding}>
        <div className="card-body">{children}</div>
    </div>
}

export default Card