import { React, useEffect, useState } from "react";
import { useHelpers } from "../../../hooks/helpers";
import Step from "../../Step";
import DocumentFormInputs from "../../DocumentFormInputs";
import { useTranslation } from "react-i18next";
import Modal from "../../Modal";
import axios from "../../../lib/axios";
import MoveForwardButton from "../../MoveForwardButton";

const ADMINISTRATIF_ETAT_CIVIL = "LIB_TYPE_ADMINISTRATIF_ETAT_CIVIL";

const DataDocument = ({
  activeStep, 
  step, 
  nextStep, 
  moveForward, 
  formResponse, 
  formData, 
  adminstratifIntitule,
  etimbreIntitule,
  docsTypes,
  handleFormDataChange,
  docTypesNeedFile,
  visaDocuments,
  errors,
  setFormData}) => {

    const [adminstratifIntituleEtatCivil, setAdminstratifIntituleEtatCivil] = useState({})

    const [iAmReady, setIAmReady] = useState(false)

    const { totalPayable } = useHelpers();

    const {t} = useTranslation()

    useEffect(() => {

      if(formData && adminstratifIntitule && formData.type_doc_id === adminstratifIntitule.id && !adminstratifIntituleEtatCivil) {

        axios.get(`/api/intitules/${ADMINISTRATIF_ETAT_CIVIL}/by-constante`)
          .then(r => r.data)
          .then(data => { setAdminstratifIntituleEtatCivil(data); })
      }

      setIAmReady(true)
    }, [formData.type_doc_id, adminstratifIntitule])


    return <>
        <Step isActive={activeStep === step} step={step}>
            <button disabled={activeStep < step || formResponse.signer !== ''} className="btn w-100 px-4" data-bs-toggle="modal" data-bs-target="#document-form">
                <i className="bi bi-card-list"></i>&nbsp;{t('Data Document')}
            </button>
        </Step>

      <Modal id="document-form" size="lg">
          <div className='modal-header'>
            <h5 className='modal-title'>{t('Visa Document Details')}</h5>
            <button disabled={!formData.title_doc || !formData.ref_doc} type="button" className='btn-close' data-bs-dismiss="modal" aria-label='Close'></button>
          </div>

          <div className="modal-body">
            <div className='row'>
              {
                activeStep >= step && 
                (iAmReady ? <DocumentFormInputs adminstratifIntitule={adminstratifIntitule} etimbreIntitule={etimbreIntitule} docsTypes={docsTypes} setFormData={setFormData} formData={formData} handleFormDataChange={handleFormDataChange} errors={errors} /> : 
                  t('Loading...'))
              }
            </div>
          </div>

          <div className="modal-footer">
            {/**TODO: check if we really need "docTypesNeedFile" */}
            {/* <button type="button" disabled={!formData.date_sign || !formData.title_doc || !formData.ref_doc} className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button> */}
            {!!docTypesNeedFile.length && etimbreIntitule.id !== formData.type_doc_id && <button className='btn btn-info text-white' data-bs-toggle="modal" data-bs-target="#upload-file">
              {formData.files ? <><i className='bi bi-cloud-plus'></i> {t('Document Uploaded')}</> : <><i className='bi bi-upload'></i> {t('Open Document Upload')}</>} ...
            </button>}

            <div className="btn-group btn-group-sm">              
              {/* <button disabled={showAddUsagerForm ? (!newUsagerInfo.email || !newUsagerInfo.nom || !newUsagerInfo.prenom) : false} className='btn btn-info btn-sm text-white' data-bs-toggle="modal" data-bs-target="#upload-file">
                {formData.files ? <><i className='bi bi-cloud-plus'></i> {t('Document Uploaded')}</> : <><i className='bi bi-upload'></i> {t('Open Document Upload')}</>} ...
              </button> */}
              <button type="button" className="btn btn-warning" disabled>{Object.keys(visaDocuments).length}</button>
            </div>
            
            <MoveForwardButton 
              moveForward={() => moveForward(step)} 
              disabled={( 
                !formData.title_doc || !formData.ref_doc || 
                !formData.document_password || !formData.visa_category_id || 
                !formData.visa_valid_from || !formData.visa_expire_at
              )} 
              className={"btn btn-"+((( 
                !formData.title_doc || !formData.ref_doc || 
                !formData.document_password || !formData.visa_category_id || 
                !formData.visa_valid_from || !formData.visa_expire_at
              )) ? "primary" : "success")}>{t('Next')}</MoveForwardButton>
          </div>
    </Modal>
    </>
}

export default DataDocument