export const useStyles = () => {

    const selectStyles = {
        menu: (provided, state) => ({...provided, zIndex: 2}),
        control: base => ({
            ...base,
            width: 180,
            minWidth: "100%"
        })
    }

    return {
        selectStyles
    }
}