import React from "react";

export const helpers = {
    required_label: (id, label) => {
  
      return <label htmlFor={id}><abbr className="text-danger" data-id={id} title="Obligatoire">*</abbr> {label}</label>
    },
    // api_endpoint: 'http://docsigner.local/api'
    api_endpoint: process.env.REACT_APP_API_ENDPOINT,
    required_input: (label) => {

      return `Le champ ${label} est obligatoire.`;
    },

    randomStr: (length) => {

      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
    
      return result;
    }
}

export default React.createContext(helpers)