import React, { useEffect, useState } from "react"
import axios from "../../../../lib/axios"
import IsLoading from "../../../IsLoading"
import Card from "../../..//Card"
import { t } from "i18next"
import { useAuth } from "../../../../hooks/auth"
import Swal from "sweetalert2"


export const OauthAnnuaireCard = ({oauth, deleted}) => {

    const [isRevoking, setIsRevoking] = useState(false)

    const {user} = useAuth()
    
    const revokePermissions = (oauth) => {

        Swal.fire({
            title: t("Warning"),
            text: t("Are you sure to revoke permissions from")+" "+oauth.annuaire.denomination,
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: t("Yes, I'm sure"),
            cancelButtonText: t("No, cancel it!")
        }).then(decision => {

            if(decision.isConfirmed) {

                setIsRevoking(true)
                axios.post(`/api/annuaire/${oauth.annuaire.id}/oauth/${user.usager.string_id}/revoke`, {})
                    .then(r => r.data)
                    .then(data => { deleted() })
            }
        })
    }

    return <Card padding="false">
        <div className="row">
            <div className="col-sm-12">
                <h5 className="card-title p-0 m-0">{oauth.annuaire.denomination}</h5>
            </div>

            <div className="col-sm-12 card-text mt-3">
                <small>{t('Has the permissions to read the following data:')}
                    <ul>
                        {oauth.readable.map(permission => <li key={permission}>{t(permission)}</li>)}
                    </ul>
                </small>
            </div>

            <div className="col-sm-12 mt-3">
                <button type="button" disabled={isRevoking} onClick={() => revokePermissions(oauth)} className="btn btn-danger btn-sm text-nowrap w-100">
                    <i className="bi bi-trash"></i> {t("Revoke Permissions")}
                </button>
            </div>
        </div>
    </Card>    
}

const UsagerOauths = () => {

    const [iAmReady, setIAmReady] = useState(false)

    const [annuaires, setAnnuaires] = useState([])

    useEffect(() => {

        axios.get('/api/annuaire/oauths')
            .then(r => r.data)
            .then(data => {

                setAnnuaires(data)
                setIAmReady(true)
            })
    }, [])

    const deleted = (oauth) => {

        const newAnnuaires = annuaires.filter(a => a.id != oauth.id)
        setAnnuaires(newAnnuaires)
    }
    

    return <>
        {!iAmReady ? <IsLoading /> : <div className="row">
            {annuaires.length ? annuaires.map(oauth => <div className="col-md-4" key={oauth.id}>
                <OauthAnnuaireCard oauth={oauth} deleted={() => deleted(oauth)} />
            </div>) : <div className="col-md-12">
                <p className="fs-4 text-center">{t("No Data Found")}</p>
            </div>}
        </div>}
    </>
}

export default UsagerOauths