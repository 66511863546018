import { React, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../hooks/auth";
import ValidationErrors from "../../../ValidationErrors";
import Select from "react-select";

import jQuery from "jquery"
import {Loader, LoaderOptions} from 'google-maps';
import Modal, { ModalBody, ModalHeader } from "../../../Modal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "../../../../lib/axios";
import GoogleMap from "../../../GoogleMap";

window.$ = window.jQuery = jQuery
require("geocomplete")

// or const {Loader} = require('google-maps'); without typescript

/* const GOOGLE_MAPS_API_KEY = "AIzaSyBZc8cubLAhAsJF3e2lCybpb41--KxILsI";
const loader = new Loader(GOOGLE_MAPS_API_KEY, { libraries: ["places"] }); */

const UsagerLocation = ({
    errors, usagerData, handleCountryChange, UpdateButton,
    countries, cities, handleUsagerChange, setUsagerData
    }) => {

    const { user } = useAuth()

    const { t } = useTranslation()

    const address_input = useRef()

    /* useEffect(async () => {

        const google = await loader.load();
        window.google = google
    }, []) */

    /* useEffect(() => {

        if(window.google) {

            jQuery("#map_address").bind("geocode:dragged", function(event, latLng){
                    
                var lat = latLng.lat();	
                var lng = latLng.lng();
                var latlng = new window.google.maps.LatLng(lat, lng);
                var geocoder = geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ 'latLng': latlng }, function (results, status) {
                     
                    if (status == window.google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            
                            const address_components = results[0]["address_components"];
                            const route = address_components.filter(ad => ad.types.includes('route'))
                            const locality = address_components.filter(ad => ad.types.includes('locality'));//city
                            const postal_code = address_components.filter(ad => ad.types.includes('postal_code'))
                            const street_number = address_components.filter(ad => ad.types.includes('street_number'))
                            let country;
                             
                            country = address_components.filter(ad => ad.types.includes('country'))
                            // if(!country.length) {
                            // 	country = address_components.filter(ad => ad.types.includes('political'))
                            // }
        
                            let _address_ = ''
                            let _cp_ = ''
                            let _country_ = ''
                            let _city_ = '';
    
                            if(postal_code.length) { _cp_ = postal_code[0].long_name }
                            if(country.length) { _country_ = country[0].long_name }
                            if(locality.length) { _city_ = locality[0].long_name }
                            if(route.length) {
        
                                _address_ = route[0].long_name;
                                if(street_number.length) { _address_ =  street_number[0].long_name+','+_address_; }
                            } else {
        
                                var formatted_address=results[0].formatted_address;
                                var adresse = results[0].address_components[0].short_name;
                                var long_name= results[0].address_components[0].long_name;
            
                                var lastIndex = formatted_address.lastIndexOf(",");
                                var short_address = formatted_address.substring(0, lastIndex);//remove country name
                                
                                var last = short_address.substring(short_address.lastIndexOf(",") + 1, short_address.length);
                                var last_string=jQuery.trim(last);
                                var ret = last_string.split(" ");
                                
                                _cp_ = ret[0];
                                var ville = ret[1]+' ('+_cp_+')';
                                var pays = ret[2];
                                var inputText=ret[1];
                                var SecondlastIndex = short_address.lastIndexOf(",");
                                var final_short_address = short_address.substring(0, SecondlastIndex);
                                _country_ = jQuery.trim(formatted_address.substring(formatted_address.lastIndexOf(",") + 1, formatted_address.length));
                                _address_ = (final_short_address ?? short_address);
                            }
    
                            Swal.fire({
                                title: t("Would you like to update the address?"),
                                icon: 'question',
                                text: _address_,
                                showConfirmButton: true,
                                showCancelButton: true,
                                allowOutsideClick: false,
                                confirmButtonText: t("Yes, I'm sure"),
                                cancelButtonText: t("No, cancel it!")
                            }).then(decision => {

                                if(decision.isConfirmed) {
                                    
                                    if(_address_ =='' || _address_ =='Unnamed Road'){
                                        
                                        
                                        setUsagerData({...usagerData, adresse: "NC"})
                                    }else {
            
                                        if(final_short_address == '' || final_short_address == ' ') {
            
                                            final_short_address = short_address;
                                        }
                                        
                                        const _c_ = countries.filter(c => c.label == _country_.trim())
                                        if(_c_.length) {

                                            _country_ = _c_[0].value
                                            if(_country_ != usagerData.country_id) {

                                                /* const url = `{{ route('cities.search') }}?country_id=${_country_}&term=${_city_}&_type=query&q=${_city_}&strict_check=1`;
                                                jQuery.get(url, response => {
                                                    
                                                    const {data} = response
                                                    if(Object.keys(data).length) {
                                                        
                                                        jQuery('#city_id').html(`<option selected value="${data[0].id}">${data[0].name}</option>`);
                                                        // jQuery('#city_id').append(data).select2();
                                                    } else {
                                                        toast.error(_city_+' '+t('City not exist'))
                                                    }
                                                }, "json")							
                                                jQuery('#city_id option')
                                                .filter(function() { return jQuery.trim( jQuery(this).text() ) == _city_; })
                                                .attr('selected', true);// * ///
                                            }
                                        } else { 
                                            
                                            toast.error(_country_ + t(" & city doesn't exist")) 
                                            _country_ = usagerData.country_id
                                            _city_ = usagerData.city_id
                                        }

                                        jQuery("#cp").trigger( "keyup" );  
                                    }                                

                                    jQuery("#choose-address-modal .btn-close").trigger('click')
                                    setUsagerData({
                                        ...usagerData, lat, lng, 
                                        adresse: _address_, 
                                        //cp: _cp_,
                                        country_id: _country_,
                                        city_id: _city_,
                                    })
                                }
                            })
                        }
                    }
                });
            });
    
            return () => jQuery('#map_address').unbind()
        }
    }, [usagerData, window.google]) */

    /* useEffect(() => {

        if(usagerData.lat && usagerData.lng) {

            if(usagerData.lat != latitude || usagerData.lng != latitude) {

                setLatitude(usagerData.lat)
                setLongitude(usagerData.lng)
            }
        } else {


            if(countries.length && cities.length && usagerData.country_id && usagerData.city_id) {

                const country_name = countries.filter(c => c.value == usagerData.country_id)[0].label
                const city_name = cities.filter(c => c.value == usagerData.city_id)[0].label
                fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${city_name},${country_name}&key=${GOOGLE_MAPS_API_KEY}`)
                    .then(r => r.json())
                    .then(({results}) => {
                        
                        setLatitude(results[0]['geometry']['lat'])
                        setLongitude(results[0]['geometry']['lng'])
                    })
            }
        }
    }, [countries, cities, usagerData.country_id, usagerData.city_id, usagerData.lat, usagerData.lng]) */

    /* useEffect(async () => {

        if(window.google && usagerData.adresse) {

            var myLatlng = new window.google.maps.LatLng(latitude, longitude);
            jQuery('#map_address').geocomplete({
                map: "#map",
                location: [latitude, longitude],
                mapOptions: {
                  zoom: 15
                  //center: myLatlng
                },
                markerOptions: {
                  draggable: true
                },
                details: "#map_form"
              });
            
            jQuery("#map_address").bind("geocode:result", function (event, result) {
            
                var adresse = jQuery("#map_address").val();
                var map = jQuery("#map_address").geocomplete("map"),
                marker = jQuery("#map_address").geocomplete("marker");
                
                //there is only a single marker, use only a single infowindow
                  if(!marker.get('infowindow')){
                    marker.set('infowindow',new window.google.maps.InfoWindow());
                    window.google.maps.event.addListener(marker, 'click', function() {
                      this.get('infowindow').open(map,this);
                    });
                
                }
                  marker.get('infowindow').close();    
                  map.setZoom(15);
                  map.setCenter(result.geometry.location);
                  marker.setMap(map);
                  marker.get('infowindow').setContent(result.formatted_address)
                 
            }).trigger('geocode:result',
                {
                    geometry:{location: myLatlng},
                    formatted_address: usagerData.adresse
                }
            );
        }
    }, [window.google, usagerData.adresse]) */

    function updateLocationInfo(result) {

        if(result.address =='' || result.address =='Unnamed Road'){
            
            result.address = "NC";
        }

        jQuery("#choose-address-modal .btn-close").trigger('click')
        setUsagerData({
            ...usagerData, 
            lat: usagerData.lat, 
            lng: usagerData.lng, 
            adresse: result.address
        })
    }

    return <div className="row">
        <ValidationErrors errors={errors} />

        <div className="col-sm-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="country_id">{t("Country")}</label>
                <Select placeholder={t('Choose')} value={countries.filter(country => country.value == (usagerData.country))[0]} options={countries} onChange={(option) => {

                    handleCountryChange(option)
                }} />
            </div>
        </div>

        <div className="col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="city_id">{t("City")}</label>
                {
                    cities.length ? <Select placeholder={t("City")}
                        defaultValue={cities.filter(city => city.value == usagerData.city_id)[0] || ''} 
                        options={cities} 
                        onChange={(option) => handleUsagerChange({target: {name: "city_id", value: option.value}})} />
                                    : <select value="" className="form-select" disabled><option value="">{t("No cities found!")}</option></select>
                }
            </div>
        </div>

        <div className="col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="adresse">{t("Address")}</label>
                <div className="input-group">
                    <input type="text" name="adresse" id="adresse" ref={address_input} className="form-control" onChange={handleUsagerChange} value={usagerData.adresse || ''} />
                    <button type="button" className="btn btn-primary" data-bs-target="#choose-address-modal" data-bs-toggle="modal">
                        <i className="bi bi-map"></i>
                    </button>
                </div>
            </div>
            <input type="hidden" id="map_address" />
        </div>

        <div className="col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="adresse_suite">{t("Addressnext")}</label>
                <input type="text" name="adresse_suite" id="adresse_suite" className="form-control" onChange={handleUsagerChange} value={usagerData.adresse_suite || ''} />
            </div>
        </div>

        <div className="col-xs-12 col-sm-4 col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="csbp">{t("CSBP")}</label>
                <input type="text" id="csbp" name="csbp" value={usagerData.csbp || ''} className="form-control text-uppercase" onChange={handleUsagerChange} />
            </div>
        </div>

        <UpdateButton />

        <Modal id="choose-address-modal">
            <ModalHeader>{t("Move the marker at your desire location")}</ModalHeader>
            <ModalBody>
                <GoogleMap 
                    address={usagerData.address} 
                    latitude={usagerData.lat} 
                    longitude={usagerData.lng} 
                    askIfWantsToUpdate="true"
                    ifConfirmed={(result) => { updateLocationInfo(result) }} 
                    autocompleteInput="#map_address" 
                    forceLatLng="true" />
            </ModalBody>
        </Modal>
    </div>
}

export default UsagerLocation