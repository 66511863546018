import {React} from 'react'

const ProgressBar = ({progress}) => {

    return <div className="progress-container position-absolute d-md-flex align-items-center w-100">
    <div className="progress w-100">
        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress+'%' }}></div>
    </div>
  </div>
}

export default ProgressBar