import { React, useEffect } from "react";
import Step from "../../Step";
import {
    openKkiapayWidget,
    addKkiapayListener,
    removeKkiapayListener,
} from "kkiapay";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/auth";
import { useHelpers } from "../../../hooks/helpers";

const PaymentButton = ({
        formData, 
        step, 
        setFormData, 
        formResponse, 
        nextStep, 
        moveForward, 
        etimbreIntitule, 
        adminstratifIntitule, 
        activeStep
    }) => {

        const {user} = useAuth()
        const { totalPayable } = useHelpers();
        useEffect( () => {

            addKkiapayListener('success', successHandler)
            return () => removeKkiapayListener('success', successHandler)
        }, [formData, user])
        
        /* useEffect(() => {
            console.log(localStorage['transactionId'])
            if(activeStep == step && localStorage['transactionId'] && localStorage['transactionId'] != "") { moveForward(step) }
        }) */

        const {t} = useTranslation()

        const successHandler = (response) => {

            localStorage.setItem("transactionId", response.transactionId)  
            setFormData({
                ...formData,
                transactionId: response.transactionId
            })
            nextStep(step+1)
            //moveForward()
        }

        const payNow = () => {

        let amount = totalPayable(formData);// || (+formData.etimbre_qty * +formData.etimbre_unit_price)
        if(!amount) {
            alert("Invalid amount!")
            return;
        }

        openKkiapayWidget({
                amount,
                api_key: process.env.REACT_APP_KKIAPAY_WIDGET_API_KEY,
                sandbox: true,
                email: user.email,
                phone: user.usager ? user.usager.mobile : user.annuaire.mobile,
                name: user.usager ? user.usager.prenom : user.annuaire.nom,
            });
        }

        return <>
            {[etimbreIntitule.id, adminstratifIntitule.id].includes(formData.type_doc_id) ? !!totalPayable(formData) && <Step isActive={activeStep === step} step={step}>
                <button disabled={activeStep < step || formResponse.signer !== ''} className='btn btn-primary w-100' onClick={payNow}><i className="bi bi-credit-card"></i> {t('Payment')}...</button>
            </Step> : <Step isActive={activeStep === step} step={step}>
            <button disabled={activeStep < step || formResponse.signer !== ''} className='btn btn-primary w-100' onClick={payNow}><i className="bi bi-credit-card"></i> {t('Payment')}...</button>
        </Step>}
    </>
}

export default PaymentButton