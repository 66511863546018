import {React} from 'react'
import { useTranslation } from 'react-i18next';
const Cryptographie = () => {
    const { t } = useTranslation()

    return <div className='container'>
        <div className="row opacity-50">
            <div className="col-md-4">
                <div className='card show-sm' Style='height: 550px;overflow:auto;'>
                    <div className='card-body'>
                        <h4 className='card-title'>{t('La cryptographie ?')}</h4>
                        <p>En général, la cryptographie est une technique d'écriture où un message chiffré est écrit à l'aide de codes secrets ou de clés de chiffrement. 
                        La cryptographie est principalement utilisée pour protéger un message considéré comme confidentiel.</p>
                        <p>Cette méthode est utilisée dans un grand nombre de domaines, tels que la défense, les technologies de l'information, la protection de la vie privée, etc. 
                        Il existe de nombreux algorithmes cryptographiques qui peuvent être utilisés pour chiffrer (et déchiffrer pour le destinataire) le message.</p>
                        <p>Certains sont considérés comme basiques (par exemple, la lettre de l'alphabet est décalée vers la droite ou la gauche avec un certain nombre de notes), d'autres offrent un niveau de sécurité presque absolu.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className='card show-sm' Style='height: 550px;overflow:auto;'>
                    <div className='card-body'>
                        <h4 className='card-title'>{t('Le cryptage à clé publique ?')}</h4>
                        <p>Le cryptage à clé publique, ou cryptographie à clé publique, est une méthode de cryptage des données avec deux clés différentes et de mise à disposition de l’une des clés, la clé publique, pour que chacun puisse l’utiliser.
                        L’autre clé est connue sous le nom de clé privée.</p>
                        <p>Les données cryptées avec la clé publique ne peuvent être décryptées qu’avec la clé privée, et les données cryptées avec la clé privée ne peuvent être décryptées qu’avec la clé publique.</p>
                        <p>Le cryptage à clé publique est également connu sous le nom de cryptage asymétrique.</p>
                        <p>Il est largement utilisé, en particulier pour le TLS/SSL, qui rend possible le HTTPS.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className='card show-sm' Style='height: 550px;overflow:auto;'>
                    <div className='card-body'>
                        <h4 className='card-title'>{t('Les avantages de la cryptographie')}</h4>
                        <p>Tant que la clé privée d'une personne n'est pas compromise, le chiffrement des données et des messages offre des avantages tels que l'intégrité et la confidentialité.</p>
                        <p>Lorsqu'un message ou un document est décrypté, son contenu est vérifié par rapport au message, comme c'était le cas avec le processus de cryptage. 
                        La moindre modification du contenu original entraîne l'échec du processus de décryptage.</p>
                        <p>En ce qui concerne la confidentialité, la clé publique est utilisée pour crypter le message ou le document et la clé privée est utilisée pour décrypter le message ou le document. 
                        Cela permet de garantir que seul un destinataire sélectionné peut décrypter et lire le contenu.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Cryptographie