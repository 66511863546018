import { React, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../hooks/auth";
import ValidationErrors from "../../../ValidationErrors";
import Inputmask from "inputmask";
import DatePicker from "../../../DatePicker";
import { format, parse } from 'date-fns'


const AnnuaireLegalInformation = ({
    errors, annuaireData, setAnnuaireData,
    handleAnnuaireChange, UpdateButton
    }) => {

    const { user } = useAuth()

    const { t } = useTranslation()

    const [dateRccm, setDateRccm] = useState('')

    const dateRccmInput = useRef(null)

    useEffect(() => {

        const date_rccm = new Inputmask('99-99-9999')
        date_rccm.mask(dateRccmInput.current.input)
        if(annuaireData.date_rccm) { setDateRccm(parse(annuaireData.date_rccm, 'dd-MM-yyyy', new Date())) }
        else { setDateRccm(new Date()) }
    }, [])

    return <div className="row">
        <ValidationErrors errors={errors} />

        <div className="col-sm-6">
            <div className="mb-3">
                <label className="form-label" htmlFor="representant">{t('Representing')}</label>
                <input type="text" name="representant" id="representant" onChange={handleAnnuaireChange} value={annuaireData.representant} className="form-control text-uppercase" />
            </div>
        </div>

        <div className="col-sm-6">
            <div className="mb-3">
                <label className="form-label" htmlFor="capital">{t('Capital')}</label>
                <input type="text" name="capital" id="capital" onChange={handleAnnuaireChange} value={annuaireData.capital} className="form-control text-uppercase" />
            </div>
        </div>

        <div className="col-sm-6">
            <div className="mb-3">
                <label className="form-label" htmlFor="rccm">{t('Rccm')}</label>
                <input type="text" name="rccm" id="rccm" onChange={handleAnnuaireChange} value={annuaireData.rccm} className="form-control text-uppercase" />
            </div>
        </div>

        <div className="col-sm-6">
            <div className="mb-3">
                <label className="form-label" htmlFor="date_rccm">{t('Date Rccm')}</label>
                <DatePicker
                    ref={dateRccmInput}
                    selected={dateRccm} 
                    onChange={(date) => {

                        setDateRccm(date)
                        setAnnuaireData({...annuaireData, date_rccm: (date ? format(date, 'dd-MM-yyyy') : '')}) 
                    }}
                />
            </div>
        </div>

        <div className="col-sm-6">
            <div className="mb-3">
                <label className="form-label" htmlFor="siren">{t('Siren')}</label>
                <input type="text" name="siren" id="siren" onChange={handleAnnuaireChange} value={annuaireData.siren} className="form-control text-uppercase" />
            </div>
        </div>

        <div className="col-sm-6">
            <div className="mb-3">
                <label className="form-label" htmlFor="idfiscal">{t('Idfiscal')}</label>
                <input type="text" name="idfiscal" id="idfiscal" onChange={handleAnnuaireChange} value={annuaireData.idfiscal} className="form-control text-uppercase" />
            </div>
        </div>

        <UpdateButton />
    </div>
}

export default AnnuaireLegalInformation