import { React, useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import HelperContext from "../contexts/HelperContext";
import DatePicker from "react-datepicker";
import { format, getYear, getMonth } from 'date-fns'
import axios from "../lib/axios";
import { useTranslation } from "react-i18next";
import CommonCheckoutData from "./CommonCheckoutData";
import CollapsibleCard from "./CollapsibleCard";

const years = ((start, end, step) => {
    return (
      Array.from(
        Array.from(
          Array(Math.ceil(
            (end - start) / step
          )).keys()
        ),
        x => start + x * step
      )
    );
  })(1990, getYear(new Date()) + 1, 1);

const DocumentFormInputs = ({docsTypes = [], setFormData, formData, handleFormDataChange, errors = null}) => {

    /* const [docsTypes, setDocsTypes] = useState([]) */

    const [iAmReady, setIAmReady] = useState(false)

    const [dateSign, setDateSign] = useState(new Date())
    
    const [dateRequest, setDateRequest] = useState(new Date())

    const [dateVisaFrom, setDateVisaFrom] = useState(new Date())

    const [countries, setCountries] = useState([])

    const [civilites, setCivilites] = useState([])

    const [countriesData, setCountriesData] = useState([])

    const [nationalities, setNationalities] = useState([])

    const [defaultCountry, setDefaultCountry] = useState(null)
    
    const [cities, setCities] = useState([])

    const [categoriesVisa, setCategoriesVisa] = useState([])

    const [showManagementInformation, setShowManagementInformation] = useState(true)

    const { t } = useTranslation()

    const months = [
        t("January"),
        t("February"),
        t("March"),
        t("April"),
        t("May"),
        t("June"),
        t("July"),
        t("August"),
        t("September"),
        t("October"),
        t("November"),
        t("December"),
    ];

    const dobInput = useRef(null)

    const resetPassword = () => {

        setFormData({
            ...formData,
            document_password: randomStr(8)
        })
    }

    const helpers = useContext(HelperContext)
    const {randomStr} = helpers

    useEffect(async () => {

        const [civilitesList, countriesList, categoriesVisaList] = await Promise.all([
            axios.get(`/api/intitules/civilites/by-group`).then(r => r.data),
            axios.get(`/api/countries`).then(r => r.data),
            axios.get('/api/intitules/categories-visas/by-group').then(r => r.data)
        ])
        
        setCivilites(civilitesList.map(civilite => ({value: civilite.id, label: civilite.libelle})))

        setCountriesData(countriesList)

        setNationalities(countriesList.filter(country => !!country.nationality).map(country => ({value: country.id, label: country.nationality})))
        
        setCountries(countriesList.map(country => ({value: country.id, label: country.name})))

        setCategoriesVisa(categoriesVisaList.map(category => ({value: category.id, label: category.libelle})))
        //setCategoriesVisa(categoriesVisaList.map(type =>{ return {value: type.id, label: type.libelle} }))

        axios.get('/api/parametres/DEFAULT_COUNTRY')
            .then(r => r.data)
            .then(data => {

                setDefaultCountry(data)
                handleCountryChange({value: data.valeur})
            })
        
        setIAmReady(true)
    }, []);

    function handleChange(name, option) {

        handleFormDataChange({target: {name, value: option.value}})
    }

    function handleCountryChange (country) {

        if(country) {
            
            setFormData({
                ...formData,
                administratif_extra: {
                    ...formData.administratif_extra,
                    country_id: country.value,
                    city_id: ''
                }
            })
            
            axios(`/api/countries/${country.value}/cities`)
                .then(r => r.data)
                .then(data => setCities(data.map(city => ({'value': city.id, 'label': city.name}))) );
        } else { setCities([]) }
    }

    return <> {iAmReady ? <div className="col-md-12">
        <CollapsibleCard show={showManagementInformation} title={<><span className="badge rounded-pill bg-success">1</span> {t('Management Information')}</>}> 
            <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <div className="mb-3">
                        {helpers.required_label('ref_doc', t('Document Reference'))}
                        <div className="input-group">
                            <input type="text" readOnly name="ref_doc" value={formData.ref_doc} style={{ flexGrow: 2 }} className={"form-control "+((errors.ref_doc || !formData.ref_doc) && "is-invalid")} onChange={handleFormDataChange}/>
                            <span className="input-group-text">-</span>
                            <input className="form-control flex-grow-1" value={formData.ref_doc_suffix} name="ref_doc_suffix" id="ref_doc_suffix" onChange={handleFormDataChange} placeholder={t("Suffix")} width="20" max="6" maxLength="6" />
                        </div>
                        {(!formData.ref_doc || errors.ref_doc) && <span className="invalid-feedback">{errors.ref_doc ? errors.ref_doc[0] : 'Le champ Référence Document est obligatoire.'}</span>}
                    </div>
                </div>

                <div className="col-xs-6 col-sm-6 col-md-6">
                    <div className="mb-3">
                        {helpers.required_label('request_date', t('Date Request'))}
                        <DatePicker readOnly  className={"form-control"} dateFormat="dd-MM-yyyy HH:MM:SS" selected={dateRequest} onChange={(date) => { setDateRequest(date)}} />
                    </div>
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4">
                    <div className="mb-3">
                        {helpers.required_label('visa_category_id', t('Visa Category'))}
                        <Select options={categoriesVisa} onChange={option => {

                            setFormData({...formData, visa_category_id: option.value})
                        }} />
                    </div>
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4">
                    <div className="mb-3">
                        {helpers.required_label('visa_valid_from', t('Valid From'))}
                        <DatePicker className={"form-control"} dateFormat="dd-MM-yyyy" 
                            selected={formData.visa_valid_from} onChange={(date) => { setFormData({...formData, visa_valid_from: date})}} />
                    </div>
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4">
                    <div className="mb-3">
                        {helpers.required_label('visa_expire_at', t('Valid To'))}
                        <DatePicker className={"form-control"} dateFormat="dd-MM-yyyy" 
                            selected={formData.visa_expire_at} onChange={(date) => { setFormData({...formData, visa_expire_at: date})}} />
                    </div>
                </div>
            </div>
        </CollapsibleCard>

        {/* <button className="btn btn-primary btn-sm my-2 w-100" type="button" onClick={() => setShowManagementInformation(!showManagementInformation)}>{t('Toggle Document Information')}</button> */}

        <div className="col-sm-12">
            <CollapsibleCard title={<><span className="badge rounded-pill bg-success">2</span> {t('Encryption & Pricing')}</>}>

                <div className="row">
                    <div className="col-sm-8">
                        <div className="input-group">
                            <span className="input-group-text">{t('Password')} <span className="text-danger">*</span></span>
                            <input type="text" className="form-control" id="document_password" value={formData.document_password} onChange={e => setFormData({...formData, document_password: e.target.value})} />
                            <button type="button" className="btn btn-primary" onClick={resetPassword}>
                                <i className="bi bi-arrow-counterclockwise"></i>
                            </button>
                        </div>
                    </div>

                    <div className="col-sm-12 mt-3">
                        <p className="border-bottom"><strong>{t('Unit Price')}</strong> <span className="float-end">{docsTypes.filter(type => type.id === +formData.type_doc_id)[0].valeur}</span></p>
                        <CommonCheckoutData formData={formData} setFormData={setFormData} />
                    </div>
                </div>
            </CollapsibleCard>
        </div>
    </div> : <>{t('Loading...')}</>}</>
}

export default DocumentFormInputs;