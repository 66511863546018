import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/auth";
import IsLoading from "./../components/IsLoading";
import axios from "./../lib/axios";

const Oauth = () => {

    const [iAmReady, setIAmReady] = useState(false)

    const [isAuthorizing, setIsAuthorizing] = useState(false)

    const [annuaire, setAnnuaire] = useState('')

    const {t} = useTranslation()

    const {user} = useAuth({middleware: 'auth'})

    const params = useParams();

    const navigate = useNavigate('')

    const doAllow = () => {

        setIsAuthorizing(true)
        axios.post(`/api/annuaire/${params.uuid}/oauth/${user.usager.string_id}/allowed`, {})
            .then(r => r.data)
            .then(data => {

                if(data.success) {

                    toast.success(annuaire.denomination+" "+t("is now authorized to read profile data")) 
                    setTimeout(() => {
    
                        navigate('/profile#authorized-annuaires')
                    }, 1500)
                }

            })
    }

    useEffect(() => {

        axios.get(`/api/annuaire/${params.uuid}`)
            .then(r => r.data)
            .then(data => {

                setAnnuaire(data)
                setIAmReady(true)
            })
            .catch(error => {

                toast.error(t("Something went wrong, please check later!"))
                navigate('/')
            })
    }, []);

    return <>{iAmReady ? <div className="row">
        <div className="col-lg-4 offset-lg-4 col-sm-12 py-6" style={{ minHeight: '200px' }}>
            <div className="card py-3 shadow mx-auto position-relative">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="card-title fw-light">{t("Give data read access to")} {annuaire.denomination}</h4>
                            <hr />
                        </div>

                        <div className="col-sm-12">
                            {annuaire.denomination} {t("Would like to read the folowing data from your profile:")} 
                            <ul>
                                <li>{t("Name")}</li>
                                <li>{t("E-mail")}</li>
                                <li>{t("Mobile")}</li>
                                <li>{t("Address")}</li>
                            </ul>
                        </div>

                        <div className="col-sm-6">
                            <button type="submit" onClick={doAllow} disabled={isAuthorizing} className="btn btn-primary w-100">{t("Authorize")}</button>
                        </div>

                        <div className="col-sm-6">
                            <Link to="/" className="btn btn-light w-100">{t("Decline")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> : <IsLoading />}
</>
}

export default Oauth;