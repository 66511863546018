import { React, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import axios from '../lib/axios'
import useNotification from '../hooks/notification'
import { Nav } from 'react-bootstrap'

const NotificationsDropdown = () => {

    const [notifications, setNotifications] = useState([])
    const [totalUnreadNotifications, setTotalUnreadNotifications] = useState(0)
    const { t } = useTranslation()
    const { markAsRead } = useNotification()
    
    useSWR('/api/notifications', () => {

        axios.get('/api/notifications')
            .then(r => r.data)
            .then(data => {

                setNotifications(data.notifications)
                setTotalUnreadNotifications(data.unread_notifications)
            })
    })

    return <Link className="nav-link rounded position-relative" to="/profile#notifications">
            <i className="bi bi-bell"></i> <span className="badge bg-info position-absolute start-100 translate-middle rounded-pill">{totalUnreadNotifications}</span>
        </Link>
    /* return <li className="nav-item dropdown">
        <a className="nav-link text-primary position-relative rounded px-3 py-3" href="#" id="notifications-dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="bi bi-bell"></i> <span className="badge bg-info position-absolute start-100 translate-middle rounded-pill">{totalUnreadNotifications}</span>
        </a>

        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="notifications-dropdown">
            <li><h6 className='dropdown-header text-center'>{totalUnreadNotifications} {t('Notifications')}</h6></li>
            <li><hr className='dropdown-divider' /></li>
            {notifications && notifications.filter(notification => !notification.read_at).map(notification => <li key={notification.id}>
                <a className="dropdown-item" href={notification.data.url || '#' } title={t(notification.data.message)} onClick={(e) => {e.preventDefault(); markAsRead(notification)}}>
                    {t(notification.data.message).substr(0, 40)}...

                    <span className="float-end text-muted text-sm">{notification.created_at}</span>
                </a>
            </li>)}
            <li><hr className='dropdown-divider' /></li>
            {!!notifications.length && <li><Link className="dropdown-item active text-center mt-3" to="/profile#notifications">{t('See all notifications')}</Link></li>}
            {!notifications.length && <li className='dropdown-item'>{t("You've no notifcations yet!")}</li>}
        </ul>
    </li> */
}

export default NotificationsDropdown