import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DownloadAttachmentButton from "../../components/DownloadAttachmentButton";
import IsLoading from "../../components/IsLoading";
import { useAuth } from "../../hooks/auth";
import axios from "../../lib/axios";

export default function Handle() {

    const [document, setDocument] = useState({})

    const [iAmReady, setIAmReady] = useState(false)

    const {user} = useAuth()
    
    const params = useParams()

    const {t} = useTranslation()

    const navigate = useNavigate()

    useEffect(() => {

        const timr = setTimeout(() => {

            axios.get(`/api/file-manager/${params.uuid}/visa-document`)
                .then(r => r.data)
                .then(data => { 

                    setDocument(data) 
                    setIAmReady(true)
                })
                .catch(error => {
    
                    console.error("Whoops, look like find doesn't exist or you don't have permission to access the file")
                })
        
        }, 700)

        return () => clearTimeout(timr)
    }, [user, params])

    const handleDocumentResponse = (response) => {

        axios.post(`/api/visa-requests/${params.uuid}/${response}`, {})
            .then(r => r.data)
            .then(data => {

                toast.success(t("Thank you"))
                navigate("/")
            })
    }

    return <>
        {iAmReady ? 
            <div className="row">
                <div className="col-sm-12 text-center">
                    <button type="submit" onClick={() => handleDocumentResponse("LIB_CHECK_IN_VALIDE")} className="btn btn-success mr-2">{t("Valid")}</button>
                    <button type="submit" onClick={() => handleDocumentResponse("LIB_CHECK_IN_INVALIDE")} className="btn btn-danger">{t("Invalid")}</button>
                </div>

                <div className="col-sm-12">
                    <iframe title={t('Document Preview')} type="application/pdf" className='w-100 mt-5' style={{ minHeight: '90vh', height: '90vh' }} src={document.url}></iframe>
                </div>
            </div> : 
            <IsLoading />
        }
    </>
    /* return <>{iAmReady ? <div className="row">
        <div className="col-lg-4 offset-lg-4 col-sm-12 py-6" style={{ minHeight: '200px' }}>
            <div className="card py-3 shadow mx-auto position-relative">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="card-title fw-light">{t("Please verify the following document:")}</h4>
                            <hr />
                        </div>

                        <div className="col-sm-12">
                            {!!Object.keys(document).length && <DownloadAttachmentButton className="w-100 mb-3" file={document}>Download file</DownloadAttachmentButton> }
                        </div>

                        <div className="col-sm-6">
                            <button type="submit" onClick={() => handleDocumentResponse("valid")} className="btn btn-success w-100">{t("Valid")}</button>
                        </div>

                        <div className="col-sm-6">
                            <button type="submit" onClick={() => handleDocumentResponse("invalid")} className="btn btn-danger w-100">{t("Invalid")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> : <IsLoading />}</> */
}