import { useTranslation } from "react-i18next"
import { useAuth } from "../hooks/auth"
import { useHelpers } from "../hooks/helpers"

const CommonCheckoutData = ({formData, setFormData}) => {

    const smsCost = process.env.REACT_APP_SMS_NOTIFICATION_COST
    const { t } = useTranslation()
    const { user } = useAuth()
    const { totalPayable } = useHelpers()
    const handleChange = (e) => {

        if(e.target.checked) { setFormData({ ...formData, sms_cost: smsCost }) }
        else { setFormData({ ...formData, sms_cost: 0 }) }
    }

    return <>
        {user.annuaire && <p className="border-bottom">
            <strong>{t("Number Usagers")}</strong>
            <span className="float-end">{formData.send_to_all ? formData.list_of_usagers.length : formData.selected_usagers.length}</span>
        </p>}
        
        <p className="border-bottom">
            <strong>
                <label htmlFor="sms_notification">
                    <input type="checkbox" id="sms_notification" onChange={handleChange} /> {t('Notify by SMS')} 
                </label>
            </strong>
            <span className="float-end">{smsCost}</span>
        </p>
        <p className="border-bottom">
            <strong>{t('Total Payble')} (<small>{t('Excluding gateway charges')}</small>)</strong> <span className="float-end">{totalPayable(formData)}</span>
        </p>
    </>
}

export default CommonCheckoutData