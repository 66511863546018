import React, { useContext, useEffect, useRef, useState } from "react";
import Select from 'react-select'
import AsyncCreateableSelect from 'react-select/async-creatable'
import HelperContext from "../contexts/HelperContext";
import DatePicker from "react-datepicker";
import { format, getYear, getMonth } from 'date-fns'
import axios from "../lib/axios";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Inputmask from "inputmask";
import bootstrap from  'bootstrap/dist/js/bootstrap'
import Modal, {ModalBody, ModalHeader} from "./Modal"
import jQuery from "jquery";
import GoogleMap from "./GoogleMap";
import { GoogleContext } from "../contexts/GoogleContext";
window.$ = window.jQuery = jQuery
require("geocomplete")

//const required_data = ["category","country_id","city_id", "fn", "gn","rccm","civilite","denomination","registreid","type_id","pid","adresse", "mobile"]
const years = ((start, end, step) => {
  return (
    Array.from(
      Array.from(
        Array(Math.ceil(
          (end - start) / step
        )).keys()
      ),
      x => start + x * step
    )
  );
})(1990, getYear(new Date()) + 1, 1);//range(1990, getYear(new Date()) + 1, 1);

const RegisterUsagerInputs = ({setUsagerData, formData, handleFormDataChange, errors = null}) => {

    const ref = useRef(null);

    const [map, setMap] = useState();

    const {google} = useContext(GoogleContext)

    const [isLoadingCities, setIsLoadingCities] = useState(false)

    const [cityName, setCityName] = useState('')

    const [categories, setCategories] = useState([])
    const [categoriesData, setCategoriesData] = useState([])
    const [typesPieces, setTypesPieces] = useState([])
    const [civilites, setCivilites] = useState([])
    const [countries, setCountries] = useState([])
    const [cities, setCities] = useState([])
    const [professions, setProfessions] = useState([])
    const [fonctions, setFonctions] = useState([])
    const [dob, setDob] = useState('')
    const [registreIdPrefix, setRegistreIdPrefix] = useState([])
    const [usagerErrors, setUsagerErrors] = useState({})
    const [uos, setUos] = useState({})
    const [defaultCountry, setDefaultCountry] = useState(null)
    const [defaultRegistreId, setDefaultRegistreId] = useState("PA")
    const [countriesData, setCountriesData] = useState([])
    const [formesjuridiques, setFormesjuridiques] = useState([])
    const [iAmReady, setIAmReady] = useState(false)
    const { t } = useTranslation()
    const [address, setAddress] = useState(null)

    const months = [
        t("January"),
        t("February"),
        t("March"),
        t("April"),
        t("May"),
        t("June"),
        t("July"),
        t("August"),
        t("September"),
        t("October"),
        t("November"),
        t("December"),
    ];

    const [searchParams] = useSearchParams();
    const dobInput = useRef(null)    

    useEffect(async () => {

        const [categoriesList, typesPiecesList, civilitesList, professionsList, registreIdsPrefixList, fonctionsList, formesjuridiquesList, uosList, countriesList] = await Promise.all([
            axios.get(`/api/intitules/categories/by-group`).then(r => r.data),
            axios.get(`/api/intitules/types-pieces/by-group`).then(r => r.data),
            axios.get(`/api/intitules/civilites/by-group`).then(r => r.data),
            axios.get(`/api/intitules/professions/by-group`).then(r => r.data),
            axios.get(`/api/intitules/registreids-prefixes/by-group`).then(r => r.data),
            axios.get(`/api/intitules/fonctions/by-group`).then(r => r.data),
            axios.get(`/api/intitules/formes-juridiques/by-group`).then(r => r.data),
            axios.get(`/api/uos`).then(r => r.data),
            axios.get(`/api/countries`).then(r => r.data),
        ])

        axios.get('/api/parametres/DEFAULT_COUNTRY').then(r => r.data).then(data => {
            
            setDefaultCountry(data)
            handleCountryChange({value: data.valeur})
        });

        setCategories(categoriesList.map(type => ({value: type.id, label: type.libelle})))

        setCategoriesData(categoriesList)

        setTypesPieces(typesPiecesList.map(type => ({value: type.id, label: type.libelle})))

        setCivilites(civilitesList.map(civilite => ({value: civilite.id, label: civilite.libelle})))
        
        setProfessions(professionsList.map(profession => ({value: profession.id, label: profession.libelle})))

        setCountriesData(countriesList)

        setFormesjuridiques(formesjuridiquesList.map(fj => ({value: fj.id, label: fj.libelle})))

        setCountries(countriesList.map(country => ({'value': country.id, 'label': country.name})))

        setUos(uosList.map(uo => ({value: uo.id, label: uo.raison_sociale})))
        
        //handleFormDataChange("uo_id", uosList[0].id)

        setRegistreIdPrefix(registreIdsPrefixList.map(registreId => ({value: registreId.id, label: registreId.libelle})))

        setFonctions(fonctionsList.map(fonction => ({value: fonction.id, label: fonction.libelle})))

        setUsagerData({
            ...formData,
            uo_id: uosList[0].id,
            registreid: "PA"+(+new Date),
            category: categoriesList.filter(c => c.constante == "LIB_CATEGORIE_PARTICULIER")[0].id
        })
        //handleFormDataChange('registreid', "PA"+(+ new Date))

        setIAmReady(true)
        
        const dob_mask = new Inputmask('99-99-9999')
        dob_mask.mask(dobInput.current.input)
    }, []);

    useEffect(() => {

        let _address = formData.adresse
        if(formData.city_id) {

            if(_address) { _address = _address+','; }
            _address += cities.filter(c => c.value == formData.city_id)[0].label
        } else if(formData.city_name) {

            if(_address) { _address = _address+','; }
            _address += formData.city_name;
        }

        if(formData.country_id) {

            if(_address) { _address = _address+','; }
            _address += countries.filter(c => c.value == formData.country_id)[0].label
        }

        setAddress(_address)
    }, [formData.adresse, formData.country_id, formData.city_id, formData.city_name])

    function openMapModal() {

        const m = new bootstrap.Modal("#map-modal")
        m.show()
    }

    function updateLocationInfo(result) {

        if(result.address == '' || result.address =='Unnamed Road'){
            
            result.address = "NC"
        }                               

        jQuery("#map-modal .btn-close").trigger('click')
        setUsagerData({
            ...formData, 
            lat: formData.lat, 
            lng: formData.lng, 
            adresse: result.address, 
        })
    }

    function handleCountryChange (country) {

        if(country) {
            
            if(countriesData.length) {

                const cntry = countriesData.filter(c => c.id == country.value)[0]
                setUsagerData({
                    ...formData,
                    country_id: country.value,
                    mobile: cntry.telephone_prefix,
                    city_id: ''
                })
                //handleFormDataChange("mobile", cntry.telephone_prefix)
            } else {

                setUsagerData({
                    ...formData,
                    country_id: country.value,
                    city_id: ''
                })
            }
            
            axios(`/api/countries/${country.value}/cities`)
                .then(r => r.data)
                .then(data => {
                    
                    //if(!data.length) { handleFormDataChange('city_id', '') }
                    setCities(data.map(city => {
    
                        return {'value': city.id, 'label': city.name}
                    }))

                    if(!data.length) {

                        if(!window._autocomplete_) {

                            var options = {
                                language: "en",
                                types: ['(cities)'],
                                componentRestrictions: {country: countriesData.filter(c => c.id == country.value)[0].code.toLowerCase()}
                            };
                            
                            var input = document.getElementById('city_name');
                            window._autocomplete_ = new google.maps.places.Autocomplete(input, options);
                            window._autocomplete_.addListener("place_changed", () => {

                                setUsagerData({...formData, country_id: country.value, city_name: window._autocomplete_.getPlace().name})
                                setCityName( window._autocomplete_.getPlace().name )
                            })
                        } else {

                            window._autocomplete_.setComponentRestrictions({country: countriesData.filter(c => c.id == country.value)[0].code.toLowerCase()})
                        }
                    }
                });
        } else { 
            
            setCities([])
        }
    }

    React.useEffect(() => {
        if (ref.current && !map) {
          setMap(new google.maps.Map(ref.current, {}));
        }
      }, [ref, map]);

    useEffect(() => {

        if(countriesData.length && defaultCountry) {

            setUsagerData({
                ...formData,
                "mobile": countriesData.filter(c => c.id == defaultCountry.valeur)[0].telephone_prefix,
                "country_id": defaultCountry.valeur
            })
            //handleFormDataChange("mobile", countriesData.filter(c => c.id == defaultCountry.valeur)[0].telephone_prefix)
        }
    }, [countriesData, defaultCountry])

    const helpers = useContext(HelperContext)
    return <>{!iAmReady ? <div className="spinner-border" role="status">
        <span className="visually-hidden">{t('Loading')}...</span>
    </div> : <>
        <div className="row">

            {/* {!!uos.length && <div className="col-xs-12 col-sm-4 col-md-4">
                <label htmlFor="uo_id">Uo</label>
                <Select defaultValue={uos[0]} options={uos} onChange={option => handleFormDataChange('uo_id', option.value)} />
            </div>} */}
            {!!categories.length && <div className="col-xs-12 col-sm-4 col-md-4">
                <div className="mb-3">
                    {helpers.required_label('category', t('Category'))}
                    <div className="input-group">
                        <div className="input-group-text"><i className="bi bi-card-list"></i></div>
                        <Select placeholder="Choix category" className="flex-grow-1" defaultValue={categories[0]} options={categories} onChange={(option) => {
                            
                            let registreid;
                            if(!option.value) {setUsagerErrors({...usagerErrors, category: helpers.required_input("Categorie")})} 
                            if(categoriesData.filter(c => c.id == option.value)[0].constante === 'LIB_CATEGORIE_PARTICULIER') {
                                
                                registreid = "PA"+(+new Date())
                                setDefaultRegistreId("PA")
                            } else {

                                registreid =  "EN"+(+new Date())
                                setDefaultRegistreId("EN")
                            }

                            setUsagerData({...formData, category: option.value, registreid})
                        } }/>
                        {(errors.category || !usagerErrors.category) && <span className="invalid-feedback d-block">{errors.category ? errors.category[0] : usagerErrors.category}</span>}
                    </div>
                </div>
            </div>}
        
            {/*{!!registreIdPrefix.length && <div className="col-xs-12 col-sm-4 col-md-4">
                <div className="mb-3">
                    {helpers.required_label('registreid', 'RegisterID')}
                    <div className="input-group">
                        <select className="form-select" onChange={(e) => {

                            const option = registreIdPrefix.filter(registreid => registreid.value == e.target.value)[0]
                            const registreid = option.label+(new Date()).valueOf();
                            handleFormDataChange('registreid', registreid)
                            setDefaultRegistreId(option.label)
                        }} value={registreIdPrefix.filter(registreid => registreid.label == defaultRegistreId)[0].value}>
                            {registreIdPrefix.map(registreid => <option key={registreid.value} value={registreid.value}>{registreid.label}</option>)}
                        </select>
                        <input type="text" name="registreid" value={formData.registreid} className={"form-control "+((errors.registreid || usagerErrors.registreid ) && "is-invalid")} onChange={(e) => {

                            if(!e.target.value) {setUsagerErrors({...usagerErrors, registreid: helpers.required_input("RegisterID")})} 
                            else { const _error = usagerErrors; delete _error['registreid']; setUsagerErrors({..._error}) }

                            handleFormDataChange('registreid', e.target.value)
                        }}/>
                        {(errors.registreid || usagerErrors.registreid) && <span className="invalid-feedback">{errors.registreid ? errors.registreid[0] : usagerErrors.registreid}</span>}
                    </div>
                </div>
            </div>}*/}
        
            {categoriesData.filter(c => (formData.category ? c.id === formData.category : c.constante === 'LIB_CATEGORIE_PARTICULIER'))[0].constante !== 'LIB_CATEGORIE_PARTICULIER' && <>

                <div className="col-xs-12 col-sm-12 col-md-6">
                    <div className="mb-3">
                        <label htmlFor="formejuridique_id">{t("Formejuridique")}</label>
                        <Select placeholder={t("Choose")} options={formesjuridiques} onChange={(option) => handleFormDataChange("formejuridique_id", option.value)} />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6">
                    <div className="mb-3">
                        {helpers.required_label('denomination', t('Denomination'))}
                        <input type="text" name="denomination" value={formData.denomination} className={"form-control "+((errors.denomination || usagerErrors.denomination) && "is-invalid")} onChange={(e) => {
                            
                            if(!e.target.value) { setUsagerErrors({...usagerErrors, denomination: helpers.required_input('Dénomination')}) }
                            else { const _error = usagerErrors; delete _error['denomination']; setUsagerErrors({..._error}) }

                            handleFormDataChange('denomination', e.target.value)
                        }}/>
                        {(errors.denomination || usagerErrors.denomination) && <span className="invalid-feedback">{errors.denomination ? errors.denomination[0] : helpers.required_input("Dénomination")}</span>}
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6">
                    <div className="mb-3">
                        {helpers.required_label('rccm', t('RCCM'))}
                        <input type="text" name="rccm" value={formData.rccm} className={"form-control "+(errors.rccm && "is-invalid")} onChange={(e) => {

                            if(!e.target.value) { setUsagerErrors({...usagerErrors, denomination: helpers.required_input('RCCM')}) }
                            else { const _error = usagerErrors; delete _error['rccm']; setUsagerErrors({..._error}) }

                            handleFormDataChange('rccm', e.target.value)
                        }}/>
                        {(errors.rccm || usagerErrors.rccm) && <span className="invalid-feedback">{errors.rccm ? errors.rccm[0] : helpers.required_input('RCCM')}</span>}
                    </div>
                </div>
            </>}
        </div>
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="mb-3">
                    {helpers.required_label('civilite', t('Civility'))}
                    <Select placeholder="Choix civilite" options={civilites} onChange={(option) => handleFormDataChange("civilite", option.value)} />
                    {(errors.civilite || usagerErrors.civilite) && <span className="invalid-feedback d-none">{errors.civilite ? errors.civilite[0] : usagerErrors.civilite}</span>}
                </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="mb-3">
                    {helpers.required_label('fn', t('Name'))}
                    <input type="text" name="fn" value={formData.fn} className={"form-control text-uppercase "+((errors.fn || usagerErrors.fn) && "is-invalid")} onChange={(e) => {

                        if(!e.target.value) { setUsagerErrors({...usagerErrors, fn: helpers.required_input('Nom')}) }
                        else { const _error = usagerErrors; delete _error['fn']; setUsagerErrors({..._error}) }

                        handleFormDataChange('fn', e.target.value)
                    }}/>
                    {(errors.fn || usagerErrors.fn) && <span className="invalid-feedback">{errors.fn ? errors.fn[0] : usagerErrors.fn}</span>}
                </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="mb-3">
                    {helpers.required_label('gn', t('FirstName'))}
                    <input type="text" name="gn" value={formData.gn} className={"form-control text-capitalize "+((errors.gn || usagerErrors.gn) && "is-invalid")} onChange={(e) => {

                        if(!e.target.value) { setUsagerErrors({...usagerErrors, gn: helpers.required_input('Prenom')}) }
                        else { const _error = usagerErrors; delete _error['gn']; setUsagerErrors({..._error}) }
                        
                        handleFormDataChange('gn', e.target.value)
                    }}/>
                    {(errors.gn || usagerErrors.gn) && <span className="invalid-feedback">{errors.gn ? errors.gn[0] : usagerErrors.gn}</span>}
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="mb-3">
                    {helpers.required_label('type_id',  t('Type Pid'))}
                    <Select placeholder="Choix type pièce" options={typesPieces} onChange={(option) => {
                        
                        if(!option.value) { setUsagerErrors({...usagerErrors, type_id: helpers.required_input('Type Pièce')}) }
                        else { const _error = usagerErrors; delete _error['type_id']; setUsagerErrors({..._error}) }
                        handleFormDataChange("type_id", option.value)
                    }} />
                    {(errors.type_id || usagerErrors.type_id) && <span className="invalid-feedback d-block">{errors.type_id ? errors.type_id[0] : usagerErrors.type_id}</span>}
                </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="mb-3">
                    {helpers.required_label('pid', t('No Pièce'))}
                    <input type="text" name="pid" value={formData.pid} className={"form-control text-uppercase"+((errors.pid || usagerErrors.pid) && "is-invalid")} onChange={(e) => {

                        if(!e.target.value) { setUsagerErrors({...usagerErrors, pid: helpers.required_input('No Pièce')}) }
                        else { const _error = usagerErrors; delete _error['pid']; setUsagerErrors({..._error}) }
                        handleFormDataChange('pid', e.target.value)
                    }}/>
                    {(errors.pid || usagerErrors.pid) && <span className="invalid-feedback">{errors.pid ? errors.pid[0] : usagerErrors.pid}</span>}
                </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="mb-3">
                    {helpers.required_label('dob', t('Dob'))}
                    {/* <input type="text" placeholder="dd-mm-yyyy" name="dob" value={formData.dob} className={"form-control "+(errors.fn && "is-invalid")} onChange={handleFormDataChange}/> */}
                    <DatePicker
                        renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                
                          <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                
                          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                    ref={dobInput} className={"form-control "+((errors.dob || usagerErrors.dob) && 'is-invalid')} dateFormat="dd-MM-yyyy" selected={dob} onChange={(date) => {
                        
                        if(!date) { setUsagerErrors({...usagerErrors, dob: helpers.required_input('Date Naissance')}) }
                        else { const _error = usagerErrors; delete _error['dob']; setUsagerErrors({..._error}) }

                        setDob(date)
                        handleFormDataChange("dob", date ? format(date, 'dd-MM-yyyy') : '')
                        }} />
                    {(errors.dob || usagerErrors.dob) && <span className="invalid-feedback d-block">{errors.dob ? errors.dob[0] : usagerErrors.dob}</span>}
                </div>
            </div>
        </div>
        <div className="row">
            {categoriesData.filter(c => (formData.category ? c.id === formData.category : c.constante === 'LIB_CATEGORIE_PARTICULIER'))[0].constante === 'LIB_CATEGORIE_PARTICULIER' && <div className="col-sm-12 col-lg-6">
                <div className="mb-3">
                    <label htmlFor="profession">{t('Occupation')}</label>
                    <Select placeholder="Choix profession" options={professions} onChange={(option) => handleFormDataChange("profession", option.value)} />
                    {errors.profession && <span className="invalid-feedback">{errors.profession[0]}</span>}
                </div>
            </div>}

            {categoriesData.filter(c => (formData.category ? c.id === formData.category : c.constante === 'LIB_CATEGORIE_PARTICULIER'))[0].constante !== 'LIB_CATEGORIE_PARTICULIER' && <div className="col-sm-12 col-lg-6">
                <div className="mb-3">
                    <label htmlFor="fonction_id">{t('Function')}</label>
                    <Select placeholder="Choix fonction" options={fonctions} onChange={(option) => handleFormDataChange("fonction_id", option.value)} />
                    {errors.fonction_id && <span className="invalid-feedback">{errors.fonction_id[0]}</span>}
                </div>
            </div>}
        </div>
        <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
                <div className="mb-3">
                    {helpers.required_label('country_id', t('Country'))}
                    <Select placeholder={t('Choose')} value={countries.filter(country => country.value == (formData.country_id || defaultCountry?.valeur))[0]} options={countries} onChange={(option) => {

                        if(!option.value) { setUsagerErrors({...usagerErrors, country_id: helpers.required_input('Pays')}) }
                        else { const _error = usagerErrors; delete _error['country_id']; setUsagerErrors({..._error}) }

                        handleCountryChange(option)
                    }} />

                    {(errors.country_id || usagerErrors.country_id) && <span className="invalid-feedback d-block">{errors.country_id ? errors.country_id[0] : usagerErrors.country_id}</span>}
                </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-6">
                <div className="mb-3">
                    {helpers.required_label('city_id', t('City'))}
                    {
                        cities.length ? <AsyncCreateableSelect 
                            
                            onCreateOption={(option) => { 

                                setIsLoadingCities(true)
                                axios.post('/api/get-google-cities', {country_id: formData.country_id, city: option})
                                .then(function (response) {
                                    
                                    setCities(response.data);
                                    setIsLoadingCities(false)
                                }).catch(function (error) {
                                    console.log(error);
                                });
                            }}
                            isDisabled={isLoadingCities}
                            placeholder="Choix ville" 
                            defaultOptions={cities}
                            defaultValue={cities.filter(city => city.value == formData.city_id)[0] || ''}
                            loadOptions={(inputValue) => new Promise((resolve) => {

                                setTimeout(() => {
                                    
                                    resolve(cities.filter((i) =>
                                        i.label.toLowerCase().includes(inputValue.toLowerCase())
                                    ))
                                }, 700)
                            })} 
                            onChange={(option) => handleFormDataChange("city_id", option.value)} />
                                        : <input type="text" className="form-control" placeholder={t("Please type your city name")} id="city_name" name="city_name" value={cityName} onChange={e => (setCityName(e.target.value))} />
                    }
                    {(errors.city_id || usagerErrors.city_id) && <span className="invalid-feedback">{errors.city_id ? errors.city_id[0] : usagerErrors.city_id}</span>}
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6">
                <div className="mb-3">
                    {helpers.required_label('adresse', t('Address'))}
                    <div className="input-group">
                        <input type="text" name="adresse" value={formData.adresse} className={"form-control "+((errors.adresse || usagerErrors.adresse) && "is-invalid")} onChange={(e) => {
                        // <input type="text" name="adresse" readOnly value={formData.adresse} className={"form-control "+((errors.adresse || usagerErrors.adresse) && "is-invalid")} onChange={(e) => {
                            if(!e.target.value) { setUsagerErrors({...usagerErrors, adresse: helpers.required_input('Adresse')}) }
                            else { const _error = usagerErrors; delete _error['adresse']; setUsagerErrors({..._error}) }
                            handleFormDataChange('adresse', e.target.value)
                        }}/>
                        <button className="btn btn-primary" type="button" onClick={openMapModal}>
                            <i className="bi bi-pin-map-fill"></i>
                        </button>
                    </div>
                    {(errors.adresse || usagerErrors.adresse) && <span className="invalid-feedback">{errors.adresse ? errors.adresse[0] : usagerErrors.adresse}</span>}
                </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6">
                <div className="mb-3">
                    <label htmlFor="adrsuite">{t("Addressnext")}</label>
                    <input type="text" name="adrsuite" value={formData.adrsuite} className={"form-control "+(errors.adrsuite && "is-invalid")} onChange={e => {

                        handleFormDataChange('adrsuite', e.target.value)
                    }}/>
                    {errors.adrsuite && <span className="invalid-feedback">{errors.adrsuite[0]}</span>}
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="mb-3">
                    {helpers.required_label('mobile', t('Mobile'))}
                    <input type="text" name="mobile" value={formData.mobile} className={"form-control "+((errors.mobile || usagerErrors.mobile) && "is-invalid")} onChange={(e) => {

                        if(!e.target.value) { setUsagerErrors({...usagerErrors, mobile: helpers.required_input('Mobile')}) }
                        else { const _error = usagerErrors; delete _error['mobile']; setUsagerErrors({..._error}) }
                        handleFormDataChange('mobile', e.target.value)
                    }}/>
                    {(errors.mobile || usagerErrors.mobile) && <span className="invalid-feedback">{errors.mobile ? errors.mobile[0] : usagerErrors.mobile}</span>}
                </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="mb-3">
                    {helpers.required_label('email', t('Email'))}
                    <input type="text" name="email" disabled={searchParams.has('email')} value={formData.email} autoComplete="off" className={"form-control "+(errors.email && "is-invalid")} onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}/>
                    {errors.email && <span className="invalid-feedback">{errors.email[0]}</span>}
                </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="mb-3">
                    {helpers.required_label('password', t('Password'))}
                    <input type="password" name="password" value={formData.password} className={"form-control "+(errors.password && "is-invalid")} onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}/>
                    {errors.password && <span className="invalid-feedback">{errors.password[0]}</span>}
                </div>
            </div>
        </div>

        <Modal id="map-modal">
            <ModalHeader>{t("Please move marker & drop at your location")}</ModalHeader>
            <ModalBody>
                <input type="hidden" id="map_address" />
                <GoogleMap 
                    address={address} 
                    askIfWantsToUpdate="true"
                    ifConfirmed={(result) => { updateLocationInfo(result) }} 
                    autocompleteInput="#map_address" 
                    latitude={formData.lat} longitude={formData.lng} />
            </ModalBody>
        </Modal>
    </> }</>
}

export default RegisterUsagerInputs