import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const CollapsibleCard = ({children, title, ...props}) => {

    const {t} = useTranslation()
    const [showCard, setShowCard] = useState(props.show === undefined || true)
    
    useEffect(() => {

        if(props.show !== undefined) setShowCard(props.show)
    }, [props.show])

    return <div className="card shadow-sm rounded mb-3"> 
        <div className="card-header">
            <button type="button" onClick={() => setShowCard(!showCard)} className="btn btn-tool text-dark">{title}</button>
            <div className="card-tools">
                <button type="button" onClick={() => setShowCard(!showCard)} className="btn btn-tool" title={t("Collapse")}>
                    <i className={"bi bi-"+(!showCard ? "plus" : "dash")+"-circle"}></i>
                </button>
            </div>
        </div>

        {showCard && <div className="card-body">
            {children} 
        </div>}
    </div>
}

export default CollapsibleCard