import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useAuth } from "../../hooks/auth";
import axios from "../../lib/axios";

export default function PassportDetails() {

    const {user} = useAuth({middleware: 'auth'})

    const [errors, setErrors] = useState([])

    const [status, setStatus] = useState('')

    const [countries, setCountries] = useState([])

    const [processing, setProcessing] = useState(false)

    const [isDisabled, setIsDisabled] = useState(true)

    const [selectedCountry, setSelectedCountry] = useState({})

    const navigate = useNavigate();

    const [data, setData] = useState({
        passport_no: '',
        country_id: '',
        dob: '',
        place_of_birth: '',
        height: '',
        eyes_color: '',
        issue_date: '',
        expiry_date: '',
        issued_from: '',
    })

    const handleChange = (name, value) => {

        setData({
            ...data,
            [name]: value
        })

        let have_all = true;
        Object.keys(data).map(d => {

            if(!data[d]) { have_all = false; }
        });
        
        console.log( "Required", !have_all )
        setIsDisabled( !have_all )
    }

    useEffect(() => {

        axios.get(`/api/countries`)
            .then(r => r.data)
            .then(data => {
                
                let _countries = data.map(country => ({'value': country.id, 'label': country.name}));
                //_countries.shift({value: '', label: t("Select Country")})
                
                setCountries(_countries)
            })
    }, [])

    useEffect(() => {

        if(countries.length && user && user.usager && user.usager.passport) {
            
            setSelectedCountry( countries.filter(country => country.value == user.usager.passport.country_id)[0] )
            
            setData({
                passport_no: user.usager.passport.passport_no,
                country_id: user.usager.passport.country_id,
                dob: user.usager.passport.dob,
                place_of_birth: user.usager.passport.place_of_birth,
                height: user.usager.passport.height,
                eyes_color: user.usager.passport.eyes_color,
                issue_date: user.usager.passport.issue_date,
                expiry_date: user.usager.passport.expiry_date,
                issued_from: user.usager.passport.issued_from,
            })
        }

    }, [countries, user])

    const handleSubmit = () => {

        setProcessing(true)
        axios.post("/api/usager/my-passport", data)
            .then(r => r.data)
            .then(data => {

                if(data.success) {

                    setStatus(t("Redirect After Passport"));
                    setTimeout(() => {

                        navigate("/")
                    }, 2000); 
                } else {

                    setProcessing(false)
                    setErrors(["Something went wrong"])
                }
            }).catch(err => {

                setProcessing(false)
                if(err.response.status === 422)  setErrors( Object.values(err.response.data.errors).flat() )
            })
    }

    return <div className='"container-fluid'>{}


        <div className="card-columns mx-auto d-flex justify-content-center col-6 card shadow">

            <div class="card-header bg-primary text-white"> <h3>{t("Passport Details")}</h3></div>
            <div class="card-body">

                {status && <div className="alert alert-info">
                    <strong>{t("Info")}</strong> {status}
                </div>}
                {!!errors.length && <div className="alert alert-danger">
                {t("Something went wrongWhoops,")}:
                    <ul>
                        {errors.map((error, index) => <li key={index}>{error}</li>)}
                    </ul>
                </div>}

                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="passport">{t("Passport N°")}</label>
                            <input type="text" className="form-control" value={data.passport_no} onChange={e => handleChange("passport_no", e.target.value)} />
                        </div>
                    </div>

                    {!!countries.length && <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="country_id">{t("Country")}</label>
                            <Select id="country_id" placeholder={t("Select Country")} value={selectedCountry} options={countries} onChange={option => {
                                
                                setSelectedCountry(option)
                                handleChange("country_id", option.value)}
                            } />
                        </div>
                    </div>}
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="dob">{t("Dob")}</label>
                            <input type="date" format="d/m/Y" className="form-control" value={data.dob} onChange={e => handleChange("dob", e.target.value)} />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="place_of_birth">{t("Place Of Birth")}</label>
                            <input type="text" className="form-control" value={data.place_of_birth} onChange={e => handleChange("place_of_birth", e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="height">{t("Height In Passport")}</label>
                            <input type="text" className="form-control" value={data.height} onChange={e => handleChange("height", e.target.value)} />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="eyes_color">{t("Eyes Color")}</label>
                            <input type="text" className="form-control" value={data.eyes_color} onChange={e => handleChange("eyes_color", e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="issue_date">{t("Issue Date")}</label>
                            <input type="date" className="form-control" value={data.issue_date} onChange={e => handleChange("issue_date", e.target.value)} />
                        </div>
                    </div>
                    
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="expiry_date">{t("Expiry Date")}</label>
                            <input type="date" className="form-control" value={data.expiry_date} onChange={e => handleChange("expiry_date", e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="issued_from">{t("Issued From")}</label>
                            <input type="text" className="form-control" value={data.issued_from} onChange={e => handleChange("issued_from", e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-muted">
                <div className="row ">
                    <div className="col-12 text-center">
                        <button type="submit" className="btn btn-primary" disabled={processing || isDisabled} onClick={handleSubmit}>
                            <i className="fa fa-paper-plane"></i> {t("Submit")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

}