import Axios from 'axios'
import axiosRetry from 'axios-retry'
import { fallbackLng } from '../i18next.conf'

const axios = Axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
    },
    withCredentials: true,
})

axios.interceptors.request.use(function (config) {

    const token = localStorage.getItem('token')
    const locale = localStorage.getItem('i18nextLng') || fallbackLng
    config.headers.Authorization = token && `Bearer ${token}`
    config.headers["X-Locale"] = locale.substr(0, 2)
    return config;
})



axiosRetry(axios, {
    retries: 1,
    retryDelay: (retryCount) => {

        //axiosRetry.exponentialDelay
        return retryCount * 5000;
    },
    retryCondition: (error) => [429,500].includes(error.response.status)
})

export default axios
