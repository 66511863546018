import { React, useEffect, useState } from "react"
import i18next from "../i18next.conf"
import {fallbackLng} from "../i18next.conf"

const languages = {
    fr: {label: "Français", dir: "ltr", active: true},
    en: {label: "English", dir: "ltr", active: false},
    ll: {label: "Custom", dir: "ltr", active: false},
}

const LanguageDropdown = () => {
    
    let selected_lang = localStorage.getItem('i18nextLng') || fallbackLng
    selected_lang = selected_lang.substr(0, 2)
    const [isLanguageChanged, setIsLanguageChanged] = useState(false)
    
    useEffect(() => {

        document.body.dir = languages[selected_lang]['dir']
    }, [isLanguageChanged, selected_lang])

    return <>
        <li className="nav-item dropdown">
            <a className="nav-link rounded" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bi bi-translate"></i> {languages[selected_lang]['label']}
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {Object.keys(languages).map(code => <li key={code} onClick={() => {
                    
                    setIsLanguageChanged(!isLanguageChanged)
                    i18next.changeLanguage(code)
                }} className={"dropdown-item " +(selected_lang === code && "active")} value={code}>{languages[code]['label']}</li>)}
            </ul>
        </li>
    </>
}

export default LanguageDropdown