import { React, useEffect, useState } from "react"
import Profile from "./TabContents/Profile"
import AnnuaireDetails from "./TabContents/AnnuaireDetails"
import AnnuaireLegalInformation from "./TabContents/AnnuaireLegalInformation"
import AnnuaireLocation from "./TabContents/AnnuaireLocation"
import Notifications from "../../Notifications"
import MyUsagers from "../../../pages/MyUsagers"
import { useTranslation } from "react-i18next"
import NavLinkItem from "../../Nav/NavLinkItem"

export const Tabs = () => {

    const [locationHash, setLocationHash] = useState('')

    const { t } = useTranslation()
    useEffect(() => {

        setLocationHash(window.location.hash)
    }, [window.location.hash])

    const _t = [
        {
            id: "profile",
            icon: "bi bi-person-circle",
            label: "Profile Information",
            is_active: (!["#notifications"].includes(locationHash) && "active")
        },
        {
            id: "annuaire",
            icon: "bi bi-building",
            label: "Annuaire Details",
            is_active: ""
        },
        {
            id: "location-information",
            icon: "bi bi-geo-fill",
            label: "Location information",
            is_active: ""
        },
        {
            id: "annuaire-legal-information",
            icon: "bi bi-book",
            label: "Legal Information",
            is_active: ""
        },
        {
            id: "my-usagers-data",
            icon: "bi bi-card-checklist",
            label: "List Data",
            is_active: ""
        },
        {
            id: "notifications",
            icon: "bi bi-bell",
            label: "Notifications",
            is_active: (locationHash === "#notifications" && "active")
        }
    ];

    return <>{_t.map(item =>  <NavLinkItem key={item.id} href={"#"+item.id} isActive={item.is_active}>
        <i className={item.icon}></i> {t(item.label)}
    </NavLinkItem>)}</>
}

export const TabsContent = ({
    userData,
    annuaireData,
    errors,
    countries,
    cities,
    UpdateButton,
    handleChange,
    setAnnuaireData,
    handleUserDataChange,
    handleAnnuaireChange,
    handleCountryChange,
    }) => {

        const [locationHash, setLocationHash] = useState('')

        const { t } = useTranslation()
        useEffect(() => {

            setLocationHash(window.location.hash)
        }, [window.location.hash])
        const _t = [
            {
                id: "profile",
                component: Profile,
                label: "Profile Information",
                is_active: (!["#notifications"].includes(locationHash) && "active")
            },
            {
                id: "annuaire",
                component: AnnuaireDetails,
                label: "Annuaire Details",
                is_active: ""
            },
            {
                id: "annuaire-legal-information",
                component: AnnuaireLegalInformation,
                label: "Legal Information",
                is_active: ""
            },
            {
                id: "my-usagers-data",
                component: MyUsagers,
                label: "List Data",
                is_active: ""
            },
            {
                id: "location-information",
                component: AnnuaireLocation,
                label: "Location information",
                is_active: ""
            },
            {
                id: "notifications",
                component: Notifications,
                label: "Notifications",
                is_active: (locationHash === "#notifications" && "active")
            }
        ]

        return <>
            {_t.map(item => <div key={item.id} className={"tab-pane "+item.is_active} id={item.id}>
            <h6 className="text-uppercase">{t(item.label)}</h6>
            <hr />

            {<item.component key={item.id} 
                userData={userData} 
                annuaireData={annuaireData} 
                errors={errors} 
                countries={countries}
                cities={cities}
                UpdateButton={UpdateButton}
                handleChange={handleChange}
                setAnnuaireData={setAnnuaireData}
                handleUserDataChange={handleUserDataChange}
                handleAnnuaireChange={handleAnnuaireChange}
                handleCountryChange={handleCountryChange}
            />}
        </div>)}
    </>
}

const Annuaire = () => {

    return <></>
}

export default Annuaire