import { React, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import IndexContent from "../components/Pages/IndexContent"
import { useAuth } from "../hooks/auth"


export default () => {

  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const {user} = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  
  useEffect(() => {

    if(user && Object.keys(user).length) {

      if(!user.email_verified_at || user.email_verified_at == 'null' || user.email_verified_at == '') {
    
        navigate('email/verify')
      } else { setIsEmailVerified(true) }
    }
  }, [user])


  return <>
    {(user !== undefined && user !== null) ? (user && isEmailVerified ? <IndexContent /> : <div className="mt-5">{t('Loading')}....</div>) : <IndexContent />}
  </>
}