import axios from '../lib/axios'
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import useSWR from "swr"

export const useAuth = ({middleware, redirectIfAuthenticated} = {}) => {
    
    const navigate = useNavigate()
    const params = useParams()

    const {data: user, error, mutate} = useSWR('/api/user', () => 

        axios.get('/api/user')
            .then(res => {
                
                if(res.status === 200 && (!res.data.usager && !res.data.annuaire)) { logout(); return null; }
                return res.data
            })
            .catch(error => {

                //if(error.response.status === 401) return null
                if(error.response.status !== 409) throw error

                navigate('/verify-email')
            })
    )

    const csrf = () => axios.get(`/sanctum/csrf-cookie`)

    const register = async ({ setErrors, ...props }) => {
        await csrf()

        setErrors([])

        axios
            .post('/register', props)
            .then(() => mutate())
            .catch(error => {
                if (error.response.status != 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const login = async ({ setErrors, setStatus, setIsLoggingIn, ...props }) => {
        //await csrf()

        setErrors([])
        setStatus(null)

        axios.post('/api/login', props)
            .then((res) => {

                if(res.status === 200 && (!res.data.usager && !res.data.annuaire)) { 
                
                    setIsLoggingIn(false)
                    setErrors([
                        "These credentials do not match with our record or you don't have a usager account!"
                    ]);
                    return null; 
                }

                localStorage.setItem('token', res.data.token)
                return mutate();
            })
            .catch(error => {
                
                setIsLoggingIn(false)
                if (error.response.status != 422) throw error

                setErrors(Object.values(error.response.data.data).flat())
            })
    }

    const forgotPassword = async ({ setErrors, setStatus, email, setIsSubmitting }) => {
        ///await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/api/forgot-password', { email })
            .then(response => {
                
                setIsSubmitting(false)
                setStatus(response.data.status)
            })
            .catch(error => {
                
                setIsSubmitting(false)
                if (error.response.status != 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resetPassword = async ({ setErrors, setStatus, setIsSubmitting, ...props }) => {
        //await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/api/reset-password', { token: params.token, ...props })
            .then(response => navigate('/login?reset=' + btoa(response.data.status)))
            .catch(error => {
                
                setIsSubmitting(false)
                if (error.response.status != 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resendEmailVerification = ({ setStatus }) => {
        axios
            .post('/email/verification-notification')
            .then(response => setStatus(response.data.status))
    }

    const logout = async () => {
        if (! error) {
            //await axios.post('/logout')
            localStorage.removeItem("token")
            mutate(null)
        }

        navigate('/')// navigate('/login')
    }

    useEffect(() => {
        
        if (middleware == 'guest' && redirectIfAuthenticated && user) navigate(redirectIfAuthenticated)
        if (middleware == 'auth' && error) {
             
            navigate('/login?redirect='+(window.location.href))
        }

        const searchParams = new URLSearchParams(window.location.search.substring(1))
        if(user && searchParams.has('redirect')) { window.location.href = searchParams.get('redirect') }

    }, [user, error])

    return {
        user,
        register,
        login,
        forgotPassword,
        resetPassword,
        resendEmailVerification,
        logout,
        mutate
    }
}