import { React, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import DownloadAttachmentButton from '../components/DownloadAttachmentButton'
import { useAuth } from '../hooks/auth'
import axios from '../lib/axios'

const Download = () => {

    const {user} = useAuth({ middleware: 'auth' })
    const [document, setDocument] = useState({})
    const [isDocumentNotAvailable, setIsDocumentNotAvailable] = useState(false)
    const [isDownloaded, setIsDownloaded] = useState(false)
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    useEffect(() => {

        axios.get(`/api/file-manager/${params.uuid}/protected-file`)
            .then(r => r.data)
            .then(data => { setDocument(data) })
            .catch(error => {

                if([403, 404].includes(error.response.status)) { setIsDocumentNotAvailable(true)  }
            })

    }, [user, params])

    return <div className='container'>
        <div className='row'>
            <div className='col-md-12'>
                <div className='card shadow-sm'>
                    <div className='card-body'>
                        {isDocumentNotAvailable && <div className="callout callout-danger">
                            <strong>{t("Error")}!</strong> {t("Unauthorized access or resource may not available or permission revoked.")}
                        </div>}

                        {isDownloaded && <div className="callout callout-info">
                            <strong>{t("Info")}!</strong> {t("Once a file downloaded, you'll be redirect to the home page and link to download file, will not be available after the download.")}
                        </div>}
                        <p>{t('Click the button below and fill password to download the file...')}</p>
                        {!!Object.keys(document).length && <DownloadAttachmentButton file={document} downloaded={() => {

                            setIsDownloaded(true)
                            setTimeout(() => {

                                navigate('/')
                            }, 3000)
                        }} />}
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default Download