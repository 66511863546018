import {React, useContext} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import "react-datepicker/dist/react-datepicker.css"
import './App.css'

import 'bootstrap-icons/font/bootstrap-icons.css'
import Index from './pages/Index'
import Signup from './pages/Signup'
import Register from './pages/auth/Register'
import Layout from './components/Layouts/AppLayout'
import Login from './pages/auth/Login'
import Cryptographie from './pages/Cryptographie'
import Ewallet from './pages/Ewallet'
import Company from './pages/Company'
import Everifier from './pages/Everifier'
import Profile from './pages/Profile'
import ForgetPassword from './pages/ForgetPassword'
import ResetPassword from './pages/auth/ResetPassword'
import VerifyEmail from './pages/auth/VerifyEmail'
import Download from './pages/Download'
import DownloadSigner from './pages/DownloadSigner'
import Oauth from './pages/Oauth';
import HandleVisaRequest from './pages/requests/Handle';
import PassportDetails from './pages/submit/PassportDetails';
//import Notifications from './pages/Notifications'

function App() { 

  return <Router>
        <Routes>
          <Route path='/requests/:uuid/handle' element={<HandleVisaRequest />} />
          <Route path="/" element={<Layout />}>
            <Route path="/submit/passport-details" element={<PassportDetails />} />
            <Route path="/cryptographie"  element={<Cryptographie />} />
            <Route path="/ewallet"  element={<Ewallet />} />
            <Route path="/company"  element={<Company />} />
            <Route path="/everifier"  element={<Everifier />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/register' element={<Register />} />
            <Route index element={<Index />} />
            <Route path='/login' element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/password/reset/:token" element={<ResetPassword />} />
            <Route path="/email/verify" element={<VerifyEmail />}>
              <Route path=":id/:token" element={<VerifyEmail />} />
              <Route path="" element={<VerifyEmail />} />
            </Route>

            <Route path="/oauth/:uuid/share-data" element={<Oauth />} />
            <Route path="/downloads/:uuid/thank-you" element={<DownloadSigner />} />

            <Route path="/download/:uuid" element={<Download />} />
            {/* <Route path="/notifications" element={<Notifications />} /> */}

            <Route path="/profile" element={<Profile />} />
          </Route>
        </Routes>
  </Router>
}

export default App;
