import {React} from 'react'

const Modal = ({children, size, ...props}) => {

    return <div data-bs-backdrop="static" className="modal" {...props }>
        <div className={`modal-dialog ${size && `modal-${size}`}`}>
            <div className='modal-content'>
                {children}
            </div>
        </div>
    </div>
}

export const ModalHeader = ({children}) => {

    return <div className='modal-header'>
        <h5 className='modal-title'>{children}</h5>
        <button type="button" className='btn-close' data-bs-dismiss="modal" aria-label='Close'></button>
    </div>
}

export const ModalBody = ({children}) => {

    return <div className='modal-body'>{children}</div>
}

export const ModalFooter = ({children}) => {

    return <div className='modal-footer'>{children}</div>
}

export default Modal