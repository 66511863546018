import {React, useEffect} from 'react'

const Step = ({step, children, isActive = false}) => {

    return <div className={`step ${isActive && 'active'} col-auto flex-grow-1 d-flex flex-column align-items-center px-4`}>
    {/*<h6 className="step-number-mobile d-block d-md-none text-grey-4 text-center">Step {step}</h6>*/}
    <div className="step-number d-flex align-items-center justify-content-center h3 bg-green-accent-3 text-white rounded-circle mb-4"></div>

    <div className="w-100">{children}</div>
  </div>
}

export default Step