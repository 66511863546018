import { React, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import AuthCard from "../../components/AuthCard"
import AuthSessionStatus from "../../components/AuthSessionStatus"
import ValidationErrors from "../../components/ValidationErrors"
import { useAuth } from "../../hooks/auth"

const ResetPassword = () => {

    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const {resetPassword} = useAuth()
    const [searchParams] = useSearchParams()

    const handleSubmit = async event => {
        event.preventDefault()

        setIsSubmitting(true)
        resetPassword({email, password, password_confirmation: passwordConfirmation, setErrors, setStatus, setIsSubmitting})
    }

    useEffect(() => {

        setEmail(searchParams.get('email') || '')
    }, [searchParams])

    return <AuthCard>
        <div className="row">
            <div className="col-sm-12">
                <form onSubmit={handleSubmit}>
                    <h1 className="h3 mb-3 text-center fw-normal">Forget password</h1>
                    <AuthSessionStatus className="alert alert-success mb-4" status={status} />
                    {!!errors.length && <ValidationErrors errors={errors} />}

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <div className="form-floating">
                                    <input type="email" name="email" id="email" placeholder="E-mail" className="form-control" onChange={(e) => setEmail(e.target.value) } value={email} />
                                    <label htmlFor="email">E-mail</label>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <div className="form-floating">
                                    <input type="password" name="password" id="password" placeholder="Password" className="form-control" onChange={(e) => setPassword(e.target.value) } value={password} />
                                    <label htmlFor="password">Password</label>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <div className="form-floating">
                                    <input type="password" name="password_confirmation" id="password_confirmation" placeholder="password_confirmation" className="form-control" onChange={(e) => setPasswordConfirmation(e.target.value) } value={passwordConfirmation} />
                                    <label htmlFor="password_confirmation">Confirm Password</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <button disabled={isSubmitting || !email} className="w-100 btn btn-primary" type="submit">
                                {isSubmitting && <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>} Se Connecter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </AuthCard>
}

export default ResetPassword