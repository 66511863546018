import { React, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const Settings = ({refreshPreview, showSettings, settings, setSettings, isSettingsReady, formData, setFormData}) => {

    const [signatureBase64, setSignatureBase64] = useState('')
    const [logoBase64, setLogoBase64] = useState('')
    const [stampBase64, setStampBase64] = useState('')
    
    const [showStamp, setShowStamp] = useState(false)
    const [showSignature, setShowSignature] = useState(false)
    const [showLogo, setShowLogo] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {

        const delayDebounceFn = setTimeout(() => {

            refreshPreview(false, {...formData, settings})
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [settings])

    useEffect(() => {

        if(localStorage.getItem('signature_base64')) { setSignatureBase64(localStorage.getItem('signature_base64'))  }
      
        if(localStorage.getItem('stamp_base64')) {  setStampBase64(localStorage.getItem('stamp_base64'))  }
      
        if(localStorage.getItem('logo_base64')) {  setLogoBase64(localStorage.getItem('logo_base64'))  }
    }, [])

    const handleSignatureChange = (e) => {

        const file = e.target.files[0]
        const reader = new FileReader();
        reader.onloadend = () => {

            setSignatureBase64(reader.result)
            localStorage.setItem('signature_base64', reader.result)
            setFormData({
                ...formData,
                signature_base64: reader.result
            })

            refreshPreview()
        }

            reader.readAsDataURL(file)
    }

    const handleStampChange = (e) => {

        const file = e.target.files[0]
        const reader = new FileReader();
        reader.onloadend = () => {

            setStampBase64(reader.result)
            localStorage.setItem('stamp_base64', reader.result)
            setFormData({
                ...formData,
                stamp_base64: reader.result
            })

            refreshPreview()
        }

        reader.readAsDataURL(file)
    }

    const handleLogoChange = (e) => {

        const file = e.target.files[0]
        const reader = new FileReader();
        reader.onloadend = () => {

            setLogoBase64(reader.result)
            localStorage.setItem('logo_base64', reader.result)
            setFormData({
                ...formData,
                logo_base64: reader.result
            })

            refreshPreview()
        }

        reader.readAsDataURL(file)
    }

    function handleChange(key, value) {

        const newSettings = {
            ...settings,
            [key]: value
        }

        setSettings(newSettings)
        //refreshPreview(false, {...formData, settings: {...newSettings}})
    }

    function handleChanges(data) {

        const newSettings = {
            ...settings,
            ...data
        }
        setSettings(newSettings)
        /* Object.keys(data).map(async (key, index) => {

            const value = data[key]
            await axios.post('/api/settings?group_name=docs-types&group_name_value='+type_doc_id, {key,value})
            if((index+1) === Object.keys(data).length) { refreshPreview(false, newSettings) }
        }) */
        //refreshPreview(false, {...formData, settings: {...newSettings}})
    }

    return <>
    {isSettingsReady && showSettings && <><div className="row">
    <div className="col-md-12">
        <div className="callout callout-info">
            <strong>{t('Note')}!</strong> {t("Note Preview!")}
        </div>
    </div>

    <div className="col-md-3">
        <div className="row">
            <div className="col-md-12">
                <fieldset className="border p-1">
                    <legend className="text-muted w-auto">{t('Qrcode Positions')}</legend>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="qrcode_position_x_axis">X-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.qrcode_position_x_axis} name="qrcode_position_x_axis" id="qrcode_position_x_axis" className="form-control" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="qrcode_position_y_axis">Y-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.qrcode_position_y_axis} name="qrcode_position_y_axis" id="qrcode_position_y_axis" className="form-control" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="qrcode_width">Width</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => {
                                        handleChanges({qrcode_width:e.target.value, qrcode_height: e.target.value})
                                    } } value={settings.qrcode_width} name="qrcode_width" id="qrcode_width" className="form-control" min="1" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="qrcode_height">Height</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => {
                                        handleChanges({qrcode_width: e.target.value, qrcode_height: e.target.value})
                                    } } value={settings.qrcode_height} name="qrcode_height" id="qrcode_height" className="form-control" min="1" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </fieldset>
            </div>

            {!!settings.show_sign_file_initials && <div className="col-md-12">
                <fieldset className="border p-1">
                    <legend className="text-muted w-auto">
                        {/* <div className="checkbox">
                            <label className="form-label" htmlFor="show_sign_file_initials"> */}
                                {/* <input type="checkbox" className="input-icheck" data-target=".initials-inputs" onChange={(e) => handleChange(e.target.name, e.target.checked ? 1 : 0) } checked={!!settings.show_sign_file_initials} name="show_sign_file_initials" id="show_sign_file_initials" /> */}
                                {t('Initials Positions')}
                            {/* </label>
                        </div> */}
                    </legend>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_initials_position_x_axis">X-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } disabled={!settings.show_sign_file_initials} value={settings.sign_file_initials_position_x_axis} name="sign_file_initials_position_x_axis" id="sign_file_initials_position_x_axis" className="form-control initials-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_initials_position_y_axis">Y-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } disabled={!settings.show_sign_file_initials} value={settings.sign_file_initials_position_y_axis} name="sign_file_initials_position_y_axis" id="sign_file_initials_position_y_axis" className="form-control initials-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>}
        </div>
    </div>

    {!!settings.show_sign_file_signature && <div className="col-md-3">
        <div className="row">
            <div className="col-md-12">
                <fieldset className="border p-1">
                    <legend className="text-muted w-auto">
                        {/* <input type="checkbox" className="input-icheck" onChange={(e) => handleChange(e.target.name, e.target.checked ? 1 : 0) } checked={!!settings.show_sign_file_signature} name="show_sign_file_signature" id="show_sign_file_signature" /> */}
                        {t('Signature Positions')}
                    </legend>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="signature_position_x_axis">X-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.signature_position_x_axis} disabled={!settings.show_sign_file_signature} name="signature_position_x_axis" id="signature_position_x_axis" className="form-control" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="signature_position_y_axis">Y-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.signature_position_y_axis} disabled={!settings.show_sign_file_signature} name="signature_position_y_axis" id="signature_position_y_axis" className="form-control" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="signature_width">Width</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.signature_width} disabled={!settings.show_sign_file_signature} name="signature_width" id="signature_width" className="form-control" min="40" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="signature_height">Height</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.signature_height} disabled={!settings.show_sign_file_signature} name="signature_height" id="signature_height" className="form-control" min="40" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-12'>
                            <div className='mb-3'>
                            <label htmlFor='signature' className='form-label'>{t('Signature')}</label>
                            <div className='input-group'>
                                {signatureBase64 && <div className="input-group-text">
                                <input type="checkbox" name="show_signature" id="show_signature" checked={formData.show_signature} onChange={(e) => { 
                                    
                                    localStorage.setItem('show_signature', (e.target.checked ? 1 : 0)); 
                                    setFormData({...formData, show_signature: e.target.checked ? 1 : 0});
                                    refreshPreview(false, {...formData, show_signature: e.target.checked ? 1 : 0}); 
                                }} />
                                </div>}
                                <input type="file" name="signature" id="signature" className='form-control' onChange={handleSignatureChange} accept="image/*" />
                                {signatureBase64 && <button type="button" className="btn btn-primary"  onClick={() => {setShowSignature(!showSignature); }}>
                                <i className={`bi bi-eye${!showSignature ? "-slash" : ''}-fill`}></i>
                                </button>}
                            </div>
                            {showSignature && signatureBase64 && <div className='position-relative'>
                                <button type='button' style={{ top: '15px', left: '5px' }} className='btn btn-danger btn-sm position-absolute' onClick={() => { localStorage.removeItem('signature_base64'); setSignatureBase64('') }}><i className='bi bi-x'></i></button>
                                <img src={signatureBase64} className="img-thumbnail mt-2" width="250" height="250" alt="Signature" />
                            </div> }
                            </div>
                        </div>
                    </div>  
                </fieldset>
            </div>
        </div>
    </div>}

    {!!settings.show_sign_file_first_name && <div className="col-md-3">
        <div className="row">
            <div className="col-md-12">
                <fieldset className="border p-1">
                    <legend className="text-muted w-auto">
                        <label className="form-label" htmlFor="show_sign_file_first_name">
                            {/* <input type="checkbox" value="1" onChange={(e) => handleChange(e.target.name, e.target.checked ? 1 : 0) } checked={!!settings.show_sign_file_first_name} name="show_sign_file_first_name" id="show_sign_file_first_name" className="input-icheck"/> */}
                            {t('FirstName Positions')}
                        </label>
                    </legend>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_first_name_position_x_axis">X-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.sign_file_first_name_position_x_axis} disabled={!settings.show_sign_file_first_name} name="sign_file_first_name_position_x_axis" id="sign_file_first_name_position_x_axis" className="form-control first-name-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_first_name_position_y_axis">Y-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.sign_file_first_name_position_y_axis} disabled={!settings.show_sign_file_first_name} name="sign_file_first_name_position_y_axis" id="sign_file_first_name_position_y_axis" className="form-control first-name-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_first_name_charachters_limit">Chars Limit</label>
                                <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.sign_file_first_name_charachters_limit} disabled={!settings.show_sign_file_first_name} name="sign_file_first_name_charachters_limit" id="sign_file_first_name_charachters_limit" className="form-control first-name-inputs" />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>}

    {!!settings.show_sign_file_last_name && <div className="col-md-3">
        <div className="row">
            <div className="col-md-12">
                <fieldset className="border p-1">
                    <legend className="text-muted w-auto">
                        {/* <input type="checkbox"  data-target=".last-name-inputs" onChange={(e) => handleChange(e.target.name, e.target.checked ? 1 : 0) } checked={!!settings.show_sign_file_last_name} name="show_sign_file_last_name" id="show_sign_file_last_name" className="input-icheck" /> */}
                        {t('LastName Positions')}
                    </legend>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_last_name_position_x_axis">X-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.sign_file_last_name_position_x_axis} disabled={!settings.show_sign_file_last_name} name="sign_file_last_name_position_x_axis" id="sign_file_last_name_position_x_axis" className="form-control last-name-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_last_name_position_y_axis">Y-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.sign_file_last_name_position_y_axis} disabled={!settings.show_sign_file_last_name} name="sign_file_last_name_position_y_axis" id="sign_file_last_name_position_y_axis" className="form-control last-name-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_last_name_charachters_limit">Chars Limit</label>
                                <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } value={settings.sign_file_last_name_charachters_limit} disabled={!settings.show_sign_file_last_name} name="sign_file_last_name_charachters_limit" id="sign_file_last_name_charachters_limit" className="form-control last-name-inputs" />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>}

    {!!settings.show_sign_file_logo && <div className="col-md-3">
        <div className="row">
            <div className="col-md-12">
                <fieldset className="border p-1">
                    <legend className="text-muted w-auto">
                        {/* <div className="checkbox">
                            <label className="form-label" htmlFor="show_sign_file_logo"> */}
                                {/* <input type="checkbox" className="input-icheck" data-target=".logo-inputs" onChange={(e) => handleChange(e.target.name, e.target.checked ? 1 : 0) } checked={!!settings.show_sign_file_logo} name="show_sign_file_logo" id="show_sign_file_logo" /> */}
                                {t('Logo positions')}
                            {/* </label>
                        </div> */}
                    </legend>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_logo_position_x_axis">X-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } disabled={!settings.show_sign_file_logo} value={settings.sign_file_logo_position_x_axis} name="sign_file_logo_position_x_axis" id="sign_file_logo_position_x_axis" className="form-control logo-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_logo_position_y_axis">Y-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } disabled={!settings.show_sign_file_logo} value={settings.sign_file_logo_position_y_axis} name="sign_file_logo_position_y_axis" id="sign_file_logo_position_y_axis" className="form-control logo-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_logo_width">Width</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } disabled={!settings.show_sign_file_logo} value={settings.sign_file_logo_width} name="sign_file_logo_width" id="sign_file_logo_width" className="form-control logo-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">px</span>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_logo_height">Height</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } disabled={!settings.show_sign_file_logo} value={settings.sign_file_logo_height} name="sign_file_logo_height" id="sign_file_logo_height" className="form-control logo-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">px</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-12'>
                            <div className='mb-3'>
                            <label htmlFor='logo' className='form-label'>{t('Logo')}</label>
                            <div className='input-group'>
                                {logoBase64 && <div className="input-group-text">
                                <input type="checkbox" name="show_logo" id="show_logo" checked={formData.show_logo} onChange={(e) => {
                                    
                                    localStorage.setItem('show_logo', (e.target.checked ? 1 : 0)); 
                                    setFormData({...formData, show_logo:  e.target.checked ? 1 : 0}); 
                                    refreshPreview(false, {...formData, show_logo:  e.target.checked ? 1 : 0});
                                }} />
                                </div>}
                                <input type="file" name="logo" id="logo" className='form-control' onChange={handleLogoChange} accept="image/*" />
                                {logoBase64 && <button type="button" className="btn btn-primary"  onClick={() => {setShowLogo(!showLogo); }}>
                                <i className={`bi bi-eye${!showLogo ? "-slash" : ''}-fill`}></i>
                                </button>}
                            </div>
                            {showLogo && logoBase64 && <div className='position-relative'>
                                <button type='button' style={{ top: '15px', left: '5px' }} className='btn btn-danger btn-sm position-absolute' onClick={() => { localStorage.removeItem('logo_base64'); setLogoBase64('') }}><i className='bi bi-x'></i></button>
                                <img src={logoBase64} className="img-thumbnail mt-2" width="150" height="150" alt="Logo" /> 
                            </div>}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>}

    {!!settings.show_sign_file_stamp && <div className="col-md-3">
        <div className="row">
            <div className="col-md-12">
                <fieldset className="border p-1">
                    <legend className="text-muted w-auto">
                        <div className="checkbox">
                            <label className="form-label" htmlFor="show_sign_file_stamp">
                                {/* <input type="checkbox" className="input-icheck" data-target=".stamp-inputs" onChange={(e) => handleChange(e.target.name, e.target.checked ? 1 : 0) } checked={!!settings.show_sign_file_stamp} name="show_sign_file_stamp" id="show_sign_file_stamp" /> */}
                                {t('Stamp positions')}
                            </label>
                        </div>
                    </legend>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_stamp_position_x_axis">X-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } disabled={!settings.show_sign_file_stamp} value={settings.sign_file_stamp_position_x_axis} name="sign_file_stamp_position_x_axis" id="sign_file_stamp_position_x_axis" className="form-control stamp-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_stamp_position_y_axis">Y-Axis</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } disabled={!settings.show_sign_file_stamp} value={settings.sign_file_stamp_position_y_axis} name="sign_file_stamp_position_y_axis" id="sign_file_stamp_position_y_axis" className="form-control stamp-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_stamp_width">Width</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } disabled={!settings.show_sign_file_stamp} value={settings.sign_file_stamp_width} name="sign_file_stamp_width" id="sign_file_stamp_width" className="form-control stamp-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">px</span>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="sign_file_stamp_height">Height</label>
                                <div className="input-group">
                                    <input type="number" onChange={(e) => handleChange(e.target.name, e.target.value) } disabled={!settings.show_sign_file_stamp} value={settings.sign_file_stamp_height} name="sign_file_stamp_height" id="sign_file_stamp_height" className="form-control stamp-inputs" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">px</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-xs-12 col-sm-12 col-md-12'>
                            <div className='mb-3'>
                            <label htmlFor='stamp' className='form-label'>{t('Cachet')}</label>
                            <div className='input-group'>
                                {stampBase64 && <div className='input-group-text'>
                                <input type="checkbox" name="show_stamp" id="show_stamp" checked={formData.show_stamp} onChange={(e) => { 
                                    
                                    localStorage.setItem('show_stamp', (e.target.checked ? 1 : 0)); 
                                    setFormData({...formData, show_stamp:  e.target.checked ? 1 : 0}); 
                                    refreshPreview(false, {...formData, show_stamp:  e.target.checked ? 1 : 0})
                                }} />
                                </div>}
                                <input type="file" name="stamp" id="stamp" className='form-control' onChange={handleStampChange} accept="image/*" />
                                {stampBase64 && <button type="button" className="btn btn-primary" onClick={() => {setShowStamp(!showStamp)}}>
                                <i className={`bi bi-eye${!showStamp ? "-slash" : ''}-fill`}></i>
                                </button>}
                            </div>
                            {showStamp && stampBase64 && <div className='position-relative'>
                                <button type='button' style={{ top: '15px', left: '5px' }} className='btn btn-danger btn-sm position-absolute' onClick={() => { localStorage.removeItem('stamp_base64'); setStampBase64('') }}><i className='bi bi-x'></i></button>
                                <img src={stampBase64} className="img-thumbnail mt-2" width="250" height="250" alt="Stamp" />
                            </div> }
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>}
</div>
</>}
    </>
}

export default Settings