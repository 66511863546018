import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../hooks/auth";
import ValidationErrors from "../../../ValidationErrors";
import Select from "react-select";
import axios from "../../../../lib/axios";


const AnnuaireDetails = ({
    errors, annuaireData, setAnnuaireData,
    handleAnnuaireChange, UpdateButton
    }) => {

    const { user } = useAuth()

    const { t } = useTranslation()

    const [categories, setCategories] = useState([])

    const [typeannuaires, setTypeannuaires] = useState([])

    const [formejuridiques, setFormejuridiques] = useState([])

    useEffect(async () => {

        await axios.get('/api/intitules/formes-juridiques/by-group')
            .then(r => r.data)
            .then(data => {

                setFormejuridiques(data.map(formjuridique => ({value: formjuridique.id, label: formjuridique.libelle})))
            })

            await axios.get('/api/intitules/categories/by-group')
            .then(r => r.data)
            .then(data => {

                setCategories(data.map(categorie => ({value: categorie.id, label: categorie.libelle})))
            })

            await axios.get('/api/intitules/types-annuaires/by-group')
            .then(r => r.data)
            .then(data => {

                setTypeannuaires(data.map(typeanu => ({value: typeanu.id, label: typeanu.libelle})))
            })
    }, [])

    return <div className="row">
        <ValidationErrors errors={errors} />

        <div className="col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="category_id">{t("Categorie")}</label>
                <Select options={categories} value={categories.filter(categorie => categorie.value === annuaireData.category_id)[0]} onChange={(option) => {

                    setAnnuaireData({...annuaireData, category_id: option.value})
                }} />
            </div>
        </div>
        <div className="col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="type_id">{t("Type")}</label>
                <Select options={typeannuaires} value={typeannuaires.filter(typeanu => typeanu.value === annuaireData.type_id)[0]} onChange={(option) => {
                    
                    setAnnuaireData({...annuaireData, type_id: option.value})
                }} />
            </div>
        </div>
        <div className="col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="num_compte">{t('Num Compte')}</label>
                <input type="text" value={annuaireData.num_compte} name="num_compte" readOnly className="form-control" />
            </div>
        </div>

        <div className="col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="formejuridique_id">{t("Forme Juridique")}</label>
                <Select options={formejuridiques} value={formejuridiques.filter(formjuridique => formjuridique.value === annuaireData.formejuridique_id)[0]} onChange={(option) => {

                    setAnnuaireData({
                        ...annuaireData,
                        formejuridique_id: option.value
                    })
                }} />
            </div>
        </div>

        <div className="col-md-4">
            <div className="mb-3">
                <label className="form-label" htmlFor="raison_sociale">{t('Raison Sociale')}</label>
                <input type="text" value={annuaireData.raison_sociale} name="raison_sociale" onChange={handleAnnuaireChange} className="form-control" />
            </div>
        </div>
        
        <UpdateButton />
    </div>
}

export default AnnuaireDetails