import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from './locales/en/translations.json';
import frTranslations from './locales/fr/translations.json';
import llTranslations from './locales/ll/translations.json';

export const fallbackLng = ['en'];
const availableLanguages = ['fr', 'en', 'll'];

const resources = {
  en: {translation: enTranslations},
  fr: {translation: frTranslations},
  ll: {translation: llTranslations},
}

i18next
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.
    resources,

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18next