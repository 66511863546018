import { React, useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAuth } from "../../hooks/auth"
import axios from "../../lib/axios"

const VerifyEmail = () => {

    const [success, setSuccess] = useState(null)
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const {id, token} = useParams()
    const [searchParams] = useSearchParams()

    const {mutate} = useAuth({ middleware: 'auth' })
    const navigate = useNavigate()
    const submitForm = async event => {
        event.preventDefault()

        setIsLoggingIn(true)
        axios.post('/api/email/verification-notification')
            .then(r => r.data)
            .then(data => {

                setSuccess(data.message)
                if(data.redirect) { setTimeout(() => navigate('/'), 1000) }
                else { setIsLoggingIn(false) }
                
            }).catch(error => {

                console.error(error.response)
                setIsLoggingIn(false)
            })
    }

    

        useEffect(() => {
    
            if(id && token) {
                axios.get(`/api/email/verify/${id}/${token}?`+searchParams.toString())
                    .then(r => r.data)
                    .then(data => {
        
                        if(data.message === 'email-verified') {
        
                            setSuccess("Thank you for verifying yoru email!");
                            mutate()
                            setTimeout(() => {
                                
                                navigate('/')
                            }, 1000);
                        }
                    })
                    .catch(error => {
        
                        console.error(error.response)
                    })
            }
        }, [mutate, id, token, searchParams, navigate])

    return <div className="row">
            <div className="col-lg-4 offset-lg-4 col-sm-12 py-6" style={{ minHeight: '200px' }}>
                <div className="card py-3 shadow mx-auto position-relative">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <form className="text-center" onSubmit={submitForm}>
                                    {success && <div className="alert alert-success alert-dismissible" data-bs-dismiss="alert">{success}</div>}
                                    <div className="row g-1">
                                        <div className="col">
                                            <button disabled={isLoggingIn} className="w-100 btn btn-lg btn-primary" type="submit">
                                                {isLoggingIn && <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                    </div>} Resend verification link!</button>
                                        </div>
                                    </div>
                                    <p className="mt-3 text-muted">© 2017–2022</p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

}

export default VerifyEmail