import React, { useEffect, useState } from "react"
import Step from "../../Step"
import AsyncCreateableSelect from 'react-select/async-creatable'
import { useTranslation } from "react-i18next"
import Modal, { ModalBody, ModalHeader } from "../../Modal"
import axios from "../../../lib/axios"
import {Modal as MT_Modal} from  'bootstrap'
import jQuery from "jquery"

const SelectUo = ({
    formData, 
    setFormData,
    moveForward,
    formResponse,
    step
    }) => {

    const [uos, setUos] = useState([])

    const [raisonSociale, setRaisonSociale] = useState('')
    
    const [email, setEmail] = useState('')
    
    const [mobile, setMobile] = useState('')

    const [isSaveDisabled, setIsSaveDisabled] = useState(false)

    const saveUo = () => {

        const uo_id = JSON.stringify({email, mobile, raison_sociale: raisonSociale})
        setUos([
            ...uos, 
            {value: uo_id, label: raisonSociale}
        ])
        
        setFormData({...formData, uo_id})
        moveForward(step)
    }

    useEffect(() => {

        axios.get('/api/uos')
            .then(r => r.data)
            .then(data => {

                setUos(data.map(d => ({value: d.id, label: d.raison_sociale})))
            })
    }, [])

    const {t} = useTranslation()

    return <Step>
        <AsyncCreateableSelect 
            isDisabled={formResponse.signer && 1}
            onCreateOption={(option) => { 

                const modal = new MT_Modal("#uo_invitation_modal")
                modal.show()
            }}
            placeholder={t("Choose Destination")} //UO
            defaultOptions={uos}
            defaultValue={uos.filter(uo => uo.value == formData.uo_id)[0] || ''}
            value={uos.filter(uo => uo.value == formData.uo_id)[0] || ''}
            loadOptions={(inputValue) => new Promise((resolve) => {

                setTimeout(() => {
                    
                    resolve(uos.filter((i) =>
                        i.label.toLowerCase().includes(inputValue.toLowerCase())
                    ))
                }, 700)
            })}
            onChange={(option) => {
                
                setFormData({...formData, uo_id: option.value})
                moveForward(step)
        }} />

        <Modal id="uo_invitation_modal">
            <ModalHeader>{t("Invite uo")}</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label htmlFor="raison_sociale">{t("raison_sociale")}</label>
                            <input type="raison_sociale" className="form-control" value={raisonSociale} onChange={e => setRaisonSociale(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="mb-3">
                            <label htmlFor="mobile">{t("Mobile")}</label>
                            <input type="text" className="form-control" value={mobile} onChange={e => setMobile(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="mb-3">
                            <label htmlFor="email">{t("Email")}</label>
                            <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <button disabled={!email || !mobile || !raisonSociale || isSaveDisabled} onClick={saveUo} type="button" className="btn btn-success">
                            {t("Save")}
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </Step>
}

export default SelectUo