import { React, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAuth } from "../../../hooks/auth"
import NavLinkItem from "../../Nav/NavLinkItem"
import Notifications from "../../Notifications"
import Profile from "./TabContents/Profile"
import UsagerLocation from "./TabContents/UsagerLocation"
import UsagerOauths from "./TabContents/UsagerOauths"

export const Tabs = () => {

    const [locationHash, setLocationHash] = useState('')
    
    const [searchParams] = useSearchParams()

    const { t } = useTranslation()

    const navigate = useNavigate()

    useEffect(() => {

        if(searchParams.get('tab')) {

            //setLocationHash( "#"+searchParams.get('tab') )
            navigate(window.location.pathname+"#"+searchParams.get('tab'))
        } else {
            
            setLocationHash(window.location.hash)
        }
    }, [window.location.hash])

    const _t = [
        {
            id: "profile",
            icon: "bi bi-person-circle",
            label: "Profile Information",
            is_active: (!["#notifications", "#authorized-annuaires"].includes(locationHash) && "active")
        },
        {
            id: "location-information",
            icon: "bi bi-geo-fill",
            label: "Location information",
            is_active: ""
        },
        {
            id: "authorized-annuaires",
            icon: "bi bi-person-check-fill",
            label: "Authorized annuaires",
            is_active: (locationHash === "#authorized-annuaires" && "active")
        },
        {
            id: "notifications",
            icon: "bi bi-bell",
            label: "Notifications",
            is_active: (locationHash === "#notifications" && "active")
        }
    ]


    return <>{_t.map(item =>  <NavLinkItem key={item.id} href={"#"+item.id} isActive={item.is_active}>
        <i className={item.icon}></i> {t(item.label)}
    </NavLinkItem>)}</>
}

export const TabsContent = ({
    userData,
    usagerData,
    setUsagerData,
    errors,
    countries,
    cities,
    UpdateButton,
    handleChange,
    handleUserDataChange,
    handleUsagerChange,
    handleCountryChange,
    }) => {

    const [locationHash, setLocationHash] = useState('')
    const { t } = useTranslation()
    const {user} = useAuth()
    
    useEffect(() => {

        setLocationHash(window.location.hash)
    }, [window.location.hash])

    const _t = [
        {
            id: "profile",
            component: Profile,
            label: t("Profile Information"),
            is_active: (!["#notifications", "#authorized-annuaires"].includes(locationHash) && "active"),
            label_second: user.usager && <span className="float-end text-uppercase">{t("Registerid")} ({user.usager.registreid})</span>
        },
        {
            id: "location-information",
            component: UsagerLocation,
            label: t("Location Information"),
            is_active: ""
        },
        {
            id: "authorized-annuaires",
            component: UsagerOauths,
            label: t("Authorizations"),
            is_active: (locationHash === "#authorized-annuaires" && "active"),
        },
        {
            id: "notifications",
            component: Notifications,
            label: t("Notifications"),
            is_active: (locationHash === "#notifications" && "active"),
            label_second: user.usager && <span className="float-end text-uppercase">{t("Registerid")} ({user.usager.registreid})</span>
        }
    ]

    return <>{_t.map(item => <div key={item.id} className={"tab-pane "+item.is_active} id={item.id}>
        <h6 className="text-uppercase">{t(item.label)} {item.label_second}</h6>
        <hr />

        {<item.component key={item.id} 
            userData={userData} 
            usagerData={usagerData} 
            setUsagerData={setUsagerData} 
            errors={errors} 
            countries={countries}
            cities={cities}
            UpdateButton={UpdateButton}
            handleChange={handleChange}
            handleUserDataChange={handleUserDataChange}
            handleUsagerChange={handleUsagerChange}
            handleCountryChange={handleCountryChange}
            />}
    </div>)}
    </>
}

const Usager = () => {

    return <></>
}

export default Usager