import { React, useEffect } from "react"
import { useTranslation } from "react-i18next"

const ValidationErrors = ({errors = [], ...props}) => {

    const { t } = useTranslation()
    return <>
        {errors.length > 0 && (
            <div className="alert alert-danger" {...props}>
                <div className="text-start">{t('Whoops! Something went wrong.')}</div>

                <ul>
                    {errors.map(error => (
                        <li key={error}>{t(error)}</li>
                    ))}
                </ul>
            </div>
        )}
    </>
}

export default ValidationErrors