import { React, useEffect, useState } from "react";
import { useAuth } from "../../../hooks/auth";
import { useStyles } from "../../../hooks/styles";
import Step from "../../Step";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import axios from "../../../lib/axios";

const ChooseDocumentType = ({
        docsTypes,
        setDocsTypes,
        selectedTypeDoc, 
        setSelectedTypeDoc,
        etimbreIntitule,
        formResponse, 
        activeStep, 
        step, 
        moveForward, 
        formData, 
        setFormData
    }) => {

    const [iAmReady, setIAmReady] = useState(false)
    
    const [selectedTypeDocId, setSelectedTypeDocId] = useState(null)

    const {user} = useAuth()
    
    const {selectStyles} = useStyles()

    const {t} = useTranslation()

    useEffect(() => {

        if(user) {
            
            axios(`/api/intitules/docs-types/by-group`)
            .then(r => r.data)
            .then(response => {
                
                setDocsTypes(response)
                setIAmReady(true)
            });
        }
    }, [])

    useEffect(() => {

        if(selectedTypeDocId) moveForward(step)
    }, [selectedTypeDocId])

    return <Step isActive={activeStep === step} step={step}>
            <Select isDisabled={!(user && iAmReady) || (formResponse.signer !== '')} placeholder={t('Choose Service')} styles={selectStyles} value={selectedTypeDoc} options={docsTypes.map(type => ({value: type.id, label: type.libelle}))} id="type_doc_id" onChange={(option) => {
            
                let should_pay = null;
                if(etimbreIntitule.id !== formData.type_doc_id) {
                    
                    should_pay = docsTypes.filter(type => type.id === +option.value)[0].valeur * formData.number_of_pages
                }

                setFormData({
                    ...formData,
                    type_doc_id: option.value,
                    title_doc: option.label,
                    ref_doc: +new Date(),
                    should_pay
                });

                const type_doc = option.value && docsTypes.filter(d => d.id === option.value).map(type => ({value: type.id, label: type.libelle}))
                if(type_doc) { setSelectedTypeDoc(type_doc[0]) }
                window.type_doc_id = option.value
                
                setSelectedTypeDocId(option.value)
            }} />
        </Step>
}

export default ChooseDocumentType