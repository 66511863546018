import axios from "../lib/axios"

const useNotification = () => {

    const markAsRead = (notification, redirect = true, callback = null) => {

        axios.post(`/api/notifications/${notification.id}/mark-as-read`)
            .then(r => r.data)
            .then(data => { 

                if(callback) { callback(); }
                if(redirect) window.location.href = notification.data.url }
            )
    }

    return { markAsRead }
}

export default useNotification