import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { redirect, useNavigate, useParams } from "react-router-dom";
import bootstrap from  'bootstrap/dist/js/bootstrap'
import $ from 'jquery'
import DownloadAttachmentButton from "../components/DownloadAttachmentButton";
import axios from "../lib/axios";
import Modal from "../components/Modal";
import ValidationErrors from "../components/ValidationErrors";

window.$ = $;
window.bootstrap = bootstrap

const inviteeDataTemplate = {
    email: '',
    mobile: ''
}

export default function DownloadSigner() {

    const {t} = useTranslation()

    const [signer, setSigner] = useState({})

    const [searchForwardUsagers, setSearchForwardUsagers] = useState('')

    const [isSubmittingInviteeForm, setIsSubmittingInviteeForm] = useState(false)

    const [invitee, setInvitee] = useState(inviteeDataTemplate)

    const [isForwardingDocument, setIsForwardingDocument] = useState(false)

    const [isDocumentForwarded, setIsDocumentForwarded] = useState(false)

    const [documents, setDocuments] = useState([])

    const [isFileDownloaded, setIsFileDownloaded] = useState(false)

    const [shouldForwardDocument, setShouldForwardDocument] = useState(false)

    const [isSearchingForwardUsagers, setIsSearchingForwardUsagers] = useState(false)

    const [hideForwardUsagersList, setHideForwardUsagersList] = useState(false)

    const [forwardUsagersList, setForwardUsagersList] = useState([])

    const [usagerToForward, setUsagerToForward] = useState({})

    const [forwardingDocumentResponse, setForwardingDocumentResponse] = useState({})

    const [isReady, setIsReady] = useState(false)

    const postSearchForwardUsagers = (e) => {
    
        setSearchForwardUsagers(e.target.value)
        if(e.target.value.length < 3) { return; }
    
        setIsSearchingForwardUsagers(true)
        setUsagerToForward({})
        const search = e.target.value;
        axios.post('/api/search-usagers-for-forwarding-document', {search})
          .then(response => response.data)
          .then(data => {
    
            setForwardUsagersList(data)
            setIsSearchingForwardUsagers(false)
            setHideForwardUsagersList(false)
          }).catch(error => {
    
            setHideForwardUsagersList(false)
            setIsSearchingForwardUsagers(false)
          })
    }

    const sendInvitationAndFile = () => {

        setIsSubmittingInviteeForm(true)
        setInviteeValidationErrors([])
        axios.post('/api/send-invitation-with-file', {
          ...invitee,
          document_id: documents[0].document_id
        })
        .then(r => r.data)
        .then(data => {
    
          setIsSubmittingInviteeForm(false)
          setStatusInviteeForm(t('Invitation email is sent to the user!'))
        })
        .catch(error => { 
          setIsSubmittingInviteeForm(false)
          setInviteeValidationErrors(Object.values(error.response.data.errors).flat()) 
        })
    }

    const [inviteeValidationErrors, setInviteeValidationErrors] = useState([])

    const params = useParams()

    const fileDownloaded = () => { setIsFileDownloaded(true) }

    const forwardDocument = () => { setShouldForwardDocument(true) }

    const [statusInviteeForm, setStatusInviteeForm] = useState('')

    const openInvitationModal = () => {

        const modal = new bootstrap.Modal('#registration-invitation')
        modal.show()
    }

    const selectUagerToForward = (user) => {

        setForwardUsagersList([])
        setHideForwardUsagersList(true)
        setSearchForwardUsagers(user.full_name)
        setIsSearchingForwardUsagers(true)
        axios.get(`/api/forward-usager-details/${user.id}`)
          .then(r => r.data)
          .then(data => { setIsSearchingForwardUsagers(false); setUsagerToForward(data) })
          .catch(error => {
            setIsSearchingForwardUsagers(false)
          })
    }

    const forwardDocumentToUsager = () => {

        setIsForwardingDocument(true)
        axios.post('/api/forward-document-to-usager', {
          document_id: documents[0].document_id,
          user: usagerToForward.id
        })
        .then(r => r.data)
        .then(data => {
    
          setIsForwardingDocument(false)
          if(data.status === 200) { setIsDocumentForwarded(true) }
          setForwardingDocumentResponse(data)
        })
        .catch(error => {
    
          setIsForwardingDocument(false)
        })
    }

    console.log(documents)
    useEffect(() => {

        axios.get(`/api/signers/${params.uuid}`)
            .then(r => r.data)
            .then(data => {

                setSigner(data.signer)
                setDocuments(data.documents)
            })
    }, [])

    const navigate = useNavigate();

    const moveToHome = () => {

        window.location.href = '/'
        //navigate('/', {state: false})
        //return redirect('/')
    }

    return <div className="container">
        <div className="row"> 
            <div className="col-md-12">
                <div className="card px-3 py-2">
                    <div className="card-body">
                        <h3 className="card-title">{ t('Document Ready To Download') }</h3>

                        <div className="row">
                            <div className="col-md-6">
                            <div className="mb-3 input-group">
                                {shouldForwardDocument ? 
                                (
                                    <div className="is-loading position-relative">
                                        <input type="text" className="form-control" placeholder={t('Search Usagers')} onChange={postSearchForwardUsagers} value={searchForwardUsagers} disabled={isForwardingDocument || isDocumentForwarded} />
                                        {isSearchingForwardUsagers && <div className="spinner-border spinner-border-sm"></div>}
                                    </div>
                                ) : ''}
                                {/* (documents.length ? <input readOnly type="text" value={documents[0].filename} className="form-control" /> : '') */}
                            </div>


                            {documents.length ? <ol type="1">
                                {documents.map((doc) => <li>{doc.filename}</li>)}
                            </ol> : ''}

                                {searchForwardUsagers && !isSearchingForwardUsagers && !hideForwardUsagersList && <ul className="dropdown-menu show">
                                    {
                                    searchForwardUsagers.length >= 3 ? 
                                        (!isSearchingForwardUsagers && (!!forwardUsagersList.length ? 
                                        forwardUsagersList.map((user) => <li key={user.id}><a onClick={(e) => {e.preventDefault(); selectUagerToForward(user)}} className="dropdown-item" href="#">{user.full_name}</a></li>) : 
                                        <li><a className="dropdown-item" href="#" onClick={(e) => {e.preventDefault(); setHideForwardUsagersList(true); openInvitationModal()}}>{t('No results found!')} <span className='text-primary'>{t('Registration Invitation')}</span></a></li>)) :
                                        <li><a href="#" className='dropdown-item'>{t('Please Enter')} "{3-searchForwardUsagers.length}" {t('Characters To Search')}</a></li>
                                    }
                                </ul>}

                                {!!Object.keys(usagerToForward).length && <>{!Object.keys(forwardingDocumentResponse).length ? (<div className="callout callout-info text-start">
                                    <p><strong>{t('Full Name')}</strong>: {usagerToForward.full_name}</p>
                                    <p><strong>{t('Mobile')}</strong>: {usagerToForward.mobile}</p>
                                    <p><strong>{t('Country')}</strong>: {usagerToForward.country}</p>
                                    <p><strong>{t('City')}</strong>: {usagerToForward.city}</p>
                                    <p><strong>{t('Address')}</strong>: {usagerToForward.address}</p>
                                    </div>) : ''}
                                    {!!Object.keys(forwardingDocumentResponse).length && <div className={`mt-3 alert alert-${forwardingDocumentResponse.status !== 200 ? 'error' : 'success'}`}>{t(forwardingDocumentResponse.message)}</div>}
                                    <button disabled={isForwardingDocument || isDocumentForwarded} onClick={forwardDocumentToUsager} className="btn btn-primary btn-sm w-100">{isForwardingDocument ? <span className='spinner-border spinner-border-sm'></span> : <i className='bi bi-send-fill'></i>} {t('Forward Document')}</button>
                                    </>
                                }
                            </div>
                            <div className="col-md-6">
                                <img src="/communication.png" className="w-100" />
                            </div>
                        </div>
                    </div>

                    <div className="py-3">
                        {/* <button title={t("Forward Document To Receiver")} disabled={shouldForwardDocument} onClick={forwardDocument} type="button" className="btn btn-success">
                            <i className="bi bi-send-fill"></i> {t("Receiver")}
                        </button> &nbsp;
                        
                        <DownloadAttachmentButton downloaded={fileDownloaded} file={documents[0]}>{t("Download")}</DownloadAttachmentButton> &nbsp; */}
                        
                        <button type="button" className='btn btn-danger' onClick={moveToHome}>
                            <i className='bi bi-arrow-clockwise'></i> {t("Reset & Add New")}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <Modal id="registration-invitation">
            <div className='modal-header'>
                <h5 className='modal-title'>{t('Send Registration Invitation & Notification')}</h5>
                <button type="button" className='btn-close' data-bs-dismiss="modal" aria-label='Close'></button>
            </div>

            <div className='modal-body'>
                <ValidationErrors errors={inviteeValidationErrors} />

                <form>
                <div className='row'>
                    {statusInviteeForm && <div className='col-md-12'>
                    <div className='alert alert-success'>
                        <strong>{t('Success!')}</strong> {statusInviteeForm}
                    </div>
                    </div>}
                    <div className='col-md-12'>
                    <div className='mb-3'>
                        <label htmlFor='email'>{t('Email')}</label>
                        <input type="email" id="email" disabled={isSubmittingInviteeForm || statusInviteeForm} value={invitee.email} onChange={e => setInvitee({...invitee, email: e.target.value})} className='form-control' />
                    </div>
                    </div>

                    <div className='col-md-12'>
                    <div className='mb-3'>
                        <label htmlFor='mobile'>{t('Mobile')}</label>
                        <input type="text" id="mobile" disabled={isSubmittingInviteeForm || statusInviteeForm} value={invitee.mobile} onChange={e => setInvitee({...invitee, mobile: e.target.value})} className='form-control' />
                    </div>
                    </div>
                </div>
                </form>
            </div>

            <div className="modal-footer">
                <button type="submit" disabled={isSubmittingInviteeForm || statusInviteeForm} onClick={sendInvitationAndFile} className="btn btn-primary">{isSubmittingInviteeForm ? t('Submitting....') : t('Send Invitation & Document')}</button>
            </div>
            </Modal>
    </div>
}