import { React, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../hooks/auth";
import { parse, format } from "date-fns";
import ValidationErrors from "../../../ValidationErrors";
import Select from "react-select";
import Inputmask from "inputmask";
import axios from "../../../../lib/axios";
import IsLoading from "../../../IsLoading";
import DatePicker from "../../../DatePicker";
import UsagerProfile from "./UsagerProfile";

const Profile = ({
    errors, userData, handleUserDataChange,
    usagerData, handleUsagerChange,
    handleChange, UpdateButton
    }) => {

        const { user } = useAuth()
        
        const { t } = useTranslation()

        return <div className="row gap-2">
            <ValidationErrors errors={errors} />
            <div className="col-md-12">
                <fieldset className="border p-1">
                    <legend className="text-muted w-auto">
                        <i className="bi bi-shield-lock"></i>{t('Authentication')}
                    </legend>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="username">{t('Username')}</label>
                                <input type="text" value={userData.username} name="username" className="form-control" readOnly disabled />
                            </div>
                        </div>

                        {/* <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="name">{t('Name')}</label>
                                <input type="text" value={userData.name} name="name" onChange={handleChange} className="form-control" readOnly />
                            </div>
                        </div> */}

                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="email">{t('E-mail')}</label>
                                <input type="email" value={userData.email} className="form-control" readOnly />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="password">{t('Password')}</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="bi bi-key"></i>
                                    </span>
                                    <input type="password" name="password" value={userData.password} className={"form-control is-valid"} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>

            {user.usager && <UsagerProfile 
                usagerData={usagerData} 
                userData={userData} 
                handleUsagerChange={handleUsagerChange}
                handleUserDataChange={handleUserDataChange}
            />}

            {/* {user.usager && haveUoData && <UoInputs uoData={uoData} handleChange={handleUoChange} errors={errors} />} */}

            <UpdateButton />
        </div>
}

export default Profile