import {React} from 'react'
import { useTranslation } from 'react-i18next';

const Company = () => {
    const { t } = useTranslation()
    
    return <div className='container'>
        <div className="row opacity-50">
            <div className="col-md-12">
                <div className='card show-sm mb-3'>
                    <div className='card-body'>
                        <h5 className='card-title'>{t("L'Entreprise - Company ?")}</h5>
                        <p className="card-text">SICOGES ou ByGAMS, est une entreprise spécialisée dans l'édition de systèmes d'information et intégration de services liés aux nouvelles technologies, basée en France et en Afrique.</p>
                        <p className="card-text">SICOGES or ByGAMS is a company specialized in the publishing of information systems and integration of services related to the new technologies, based in France and in Africa.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row opacity-50">        
            <div className="col-md-3">
                <div className='card show-sm mb-3' Style='height: 450px;overflow:auto;'>
                    <div className='card-body'>
                        <h5 className='card-title'>{t('Audit & Gestion de Projet')}</h5>
                        <p className="card-text">
                            Pour mener à bien votre projet informatique il est impératif d'analyser, de contrôler et d'optimiser les systèmes de l’entreprise tout en garantissant l'adhésion de votre équipe est un vrai défit!.
                        </p>
                        <p className="card-text">
                            Completing your IT project it is imperative to analyze, control and optimise the systems of the company while guaranteeing the membership of your team is a real challenge!
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className='card show-sm mb-3'Style='height: 450px;overflow:auto;'>
                    <div className='card-body'>
                        <h5 className='card-title'>{t('Integration Solutions')}</h5>
                        <p className="card-text">
                            Les entreprises, administrations et institutions sont amenés à optimiser leur productivité et ceci en utilisant les nouvelles technologies de l'information et de communication pour structurer les données échangées.
                        </p>
                        <p className="card-text">
                            Companies, administrations and institutions are encouraged to optimize their productivity by using the new information and communication technologies to structure the exchanged.                        
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className='card show-sm mb-3' Style='height: 450px;overflow:auto;'>
                    <div className='card-body'>
                        <h5 className='card-title'>{t('Infogérance / Outsourcing')}</h5>
                        <p className="card-text">
                            Objectif primaire déchargé une entreprise ou une administration des tâches et services fortement liés à l'informatique afin qu'elle se concenter sur sa réelle activité pour atteindre ses objectifs. Nos partenaires nous accompagnent.
                        </p>
                        <p className="card-text">
                            Primary objective unloaded company or administration of tasks and services strongly related to IT so that it will focus on its real activity to achieve its objectives by outsourcing. Our partners support us.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className='card show-sm mb-3' Style='height: 450px;overflow:auto;'>
                    <div className='card-body'>
                        <h5 className='card-title'>{t('Nos Solutions/Our Solutions')}</h5>
                        <ul>
                            <li>SIE COLLABORATIF</li>
                            <li>SIE e-SERVICE</li>
                            <li>SIE e-SANITAIRE</li>
                            <li>SIE e-COLLECTE </li>
                            <li>SIE e-FILE ATTENTE</li>
                            <li>SIE SECURE DOC</li>
                            <li>SIE ATTENDANCE</li>
                        </ul>
                        <p className="card-text">
                            Ces Systèmes d'Information ont pour dénominateur la gestion d'une entité (Administration, Institution, Entreprise et association, etc.).
                            Finalité structuration, authenticité, confidentialité, Intégrité des données et une optimisation de la circulation de l'information.
                        </p>
                        <p className="card-text">
                            These Information Systems have as denominator the management of an entity (Administration, Institution, Company and association, etc.)
                            Purpose structuring, authenticity, confidentiality, data integrity and optimization of the flow of information.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Company