import { React } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import LanguageDropdown from '../LanguageDropdown';
import NotificationsDropdown from '../NotificationsDropdown';
import { useTranslation } from 'react-i18next';

const Navigation = () => {
    const { t } = useTranslation()
    const {user, logout} = useAuth()

    return <Navbar bg="white" expand="lg" className="shadow-sm mb-5 ">
        <Container>
            <Link className="navbar-brand" to="/">
                <img src={'/logoent75.png'} alt={t('Logo')} />
            </Link>
            <Navbar.Toggle aria-controls='navbarScroll' />
            <Navbar.Collapse id="navbarScroll">
                <Nav className="ml-auto" navbarScroll>

                        {!user ?
                            <>
                                <Nav.Item>
                                    <Link className="nav-link rounded" to="/register"><i className="bi bi-ticket"></i> {t('Create Account')}</Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item ">
                                    <Link className="nav-link rounded" to="/login"><i className="bi bi-box-arrow-in-right"></i> {t('Login')}</Link>
                                </Nav.Item>
                            </>
                            : <NavDropdown id="nav-dropdown" title={<> <i className="bi bi-emoji-sunglasses"></i> {user.name}</>}>
                                <NavDropdown.Item>
                                    <Link className='dropdown-item' to="/profile">{t('Profile')}</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Divider />

                                <NavDropdown.Item>
                                    <a className="dropdown-item rounded cursor-pointer" onClick={logout}><i className="bi bi-power"></i>  {t('Logout')}</a>
                                </NavDropdown.Item>
                            </NavDropdown>}

                            {/* <li className="nav-item dropdown">
                                <a className="nav-link text-primary rounded px-3 py-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-emoji-sunglasses"></i> {user.name}
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li>
                                        <Link className='dropdown-item' to="/profile">{t('Profile')}</Link>
                                    </li>
                                    <li>
                                        <hr className='dropdown-divider' />
                                    </li>
                                    <li>
                                        <a className="dropdown-item text-primary rounded cursor-pointer" onClick={logout}><i className="bi bi-power"></i>  {t('Logout')}</a>    
                                    </li>
                                </ul>
                            </li> */}

                        <Nav.Item>
                            <LanguageDropdown />
                        </Nav.Item>

                        {user !== null && user !== undefined && <Nav.Item className='d-flex align-items-center'>
                            <NotificationsDropdown />
                        </Nav.Item>}
                </Nav>
            </Navbar.Collapse>
        </Container>
  </Navbar>
}

export default Navigation