///import * as fs from 'fs'
import { React, useContext, useEffect, useState} from 'react'
import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import {format} from "date-fns";
import $ from 'jquery'
import bootstrap from  'bootstrap/dist/js/bootstrap'
import axios from '../../lib/axios'
import Modal, { ModalBody, ModalHeader } from '../../components/Modal'
import { useAuth } from '../../hooks/auth'
import Steps from '../../components/Index/Steps'
import ProgressBar from '../../components/ProgressBar'
import ValidationErrors from '../../components/ValidationErrors'
import { PDFDocument } from 'pdf-lib';

import { useTranslation } from 'react-i18next'
import DownloadAttachmentButton from '../DownloadAttachmentButton'
import { useHelpers } from '../../hooks/helpers'
import IsLoading from '../IsLoading'
import VisaDocumentNewAnnuaire from '../Index/VisaDocumentNewAnnuaire'
import VisaDocumentInputs from '../Index/VisaDocumentInputs'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import HelperContext from '../../contexts/HelperContext';

window.$ = $;
window.bootstrap = bootstrap

const settingsTemplate = {
  show_sign_file_logo: 1,
  sign_file_logo_position_x_axis: 10,
  sign_file_logo_position_y_axis: 20,
  sign_file_logo_width: 50,
  sign_file_logo_height: 50,

  qrcode_position_x_axis: 10,
  qrcode_position_y_axis: 30,
  qrcode_width: 50,
  qrcode_height: 50,

  show_sign_file_initials: 0,
  sign_file_initials_position_x_axis: 5,
  sign_file_initials_position_y_axis: 285,

  show_sign_file_stamp: 1,
  sign_file_stamp_position_x_axis: 130,
  sign_file_stamp_position_y_axis: 200,
  sign_file_stamp_width: 50,
  sign_file_stamp_height: 50,

  show_sign_file_signature: 1,
  signature_position_x_axis: 130,
  signature_position_y_axis: 220,
  signature_width: 50,
  signature_height: 50,

  show_sign_file_first_name: 0,
  sign_file_first_name_position_x_axis: '',
  sign_file_first_name_position_y_axis: '',
  sign_file_first_name_charachters_limit: '',

  show_sign_file_last_name: 0,
  sign_file_last_name_position_x_axis: '',
  sign_file_last_name_position_y_axis: '',
  sign_file_last_name_charachters_limit: '',
}

const formDataTemplate = {
  uo_id : '',
  visa_data: '',
  visa_category_id: '',
  visa_valid_from: '',
  visa_expire_at: '',
  //annuaire related data, only need when logged in user is annuaire 
  selected_usagers: [],
  send_to_all: null,
  list_of_usagers: [],

  sms_cost: 0,
  document_password: '',
  transactionId: '',
  usager_id: '',
  pid: '',
  fn: '',
  gn: '',
  dob: '',
  mobile: '',
  epidil: '',
  category: '',
  fonction_id:'',
  civilite: '',
  adresse: '',
  adrsuite: '',
  type_id: '',
  country_id: '',
  city_id: '',
  title_doc: '',
  type_doc_id: '',
  ref_doc: '',
  ref_doc_suffix: '',
  files: '',
  denomination: '',
  editor: '',
  registreid: '',
  rccm: '',
  cp: '',
  //date_sign: format(new Date(), 'dd-MM-yyyy'),
  profession: '',
  source_type: 'upload',
  email_document: 0,
  //signed_by: '',
  administratif_id: '',
  administratif_unit_price: '',
  administratif_qty: 1,
  administratif_extra: {
    dob:"",
    dob_location:"",
    country_id:"",
    city_id:"",
    identity_father:"",
    identity_mother:"",
    nationality_id:"",
  },

  etimbre_id: '',
  etimbre_qty: 1,
  etimbre_unit_price: '',
  number_of_pages: 1,
  should_pay: null,
  data1: '',
  data2: '',
  data3: '',
  data4: '',
  
  signature_base64: '',
  show_signature: 0,

  stamp_base64: '',
  show_stamp: 0,

  logo_base64: '',
  show_logo: 1,

  //previews
  previews: [],
  
  //settings
  settings: settingsTemplate
}

const inviteeDataTemplate = {
  email: '',
  mobile: ''
}

const totalSteps = 5
const IndexContent = () => {

  const [formData, setFormData] = useState(formDataTemplate)  
  const [docsTypes, setDocsTypes] = useState([])
  const [progress, setProgress] = useState(0)
  const [errors, setErrors] = useState({})
  const [activeStep, setActiveStep] = useState(1)
  
  const [usagers, setUsagers] = useState([
      {value: 'add_new', label: 'Nouvel Ajout'}
  ]);

  const [documentsNeedForVisa, setDocumentsNeedForVisa] = useState([])
  const [documentCheckinAnnuaires, setDocumentCheckinAnnuaires] = useState([])
  const [visaDocuments, setVisaDocuments] = useState({})

  const [docTypesNeedFile, setDocTypesNeedFile] = useState([])
  const [invitee, setInvitee] = useState(inviteeDataTemplate)
  const [inviteeValidationErrors, setInviteeValidationErrors] = useState([])
  const [isSubmittingInviteeForm, setIsSubmittingInviteeForm] = useState(false)
  const [statusInviteeForm, setStatusInviteeForm] = useState('')

  const [haveVisaDocuments, setHaveVisaDocuments] = useState(false)
  const [isProcessingFormData, setIsProcessingFormData] = useState(false)
  const [formResponse, setFormResponse] = useState({signer: '', documents: ''})
  
  const [etimbreIntitule, setEtimbreIntitule] = useState({})
  const [adminstratifIntitule, setAdminstratifIntitule] = useState({})

  const [shouldForwardDocument, setShouldForwardDocument] = useState(false)
  const [searchForwardUsagers, setSearchForwardUsagers] = useState('')
  const [forwardUsagersList, setForwardUsagersList] = useState([])
  const [isSearchingForwardUsagers, setIsSearchingForwardUsagers] = useState(false)
  const [hideForwardUsagersList, setHideForwardUsagersList] = useState(false)
  const [isForwardingDocument, setIsForwardingDocument] = useState(false)
  const [isDocumentForwarded, setIsDocumentForwarded] = useState(false)
  const [isFileDownloaded, setIsFileDownloaded] = useState(false)
  const [forwardingDocumentResponse, setForwardingDocumentResponse] = useState({})
  const [usagerToForward, setUsagerToForward] = useState({})
  const [selectedTypeDoc, setSelectedTypeDoc] = useState('')
  const [iAmReady, setIAmReady] = useState(false)

  const helpers = useContext(HelperContext)
  const {randomStr} = helpers

  const navigate = useNavigate()

  const {user} = useAuth();
  const { t } = useTranslation()
  const { totalPayable } = useHelpers()

  useEffect(async () => {

    if(user && (user.usager || user.annuaire)) {

      setIAmReady(false)
      if(user.usager) {

        setUsagers([
            ...usagers,
            {value: user.usager.id, label: (user.usager.nom+' '+user.usager.prenom)}
        ])
      }

      axios.get('/api/intitules/type-visas/by-group')
          .then(r => r.data)
          .then(data => {

            setDocumentsNeedForVisa(data)

            const checkinAnnuaires = {};
            data.map(async (d) => {

                await axios.get(`/api/${d.valeur}/annuaires/by-type-constante`)
                  .then(r => r.data)
                  .then(da => {

                    checkinAnnuaires[d.constante] = da
                  })
            })

            setDocumentCheckinAnnuaires(checkinAnnuaires)
          })
  
        const form_d = {
            ...formData,
            signature_base64: localStorage.getItem('signature_base64'),
            stamp_base64: localStorage.getItem('stamp_base64'),
            logo_base64: localStorage.getItem('logo_base64'),
            show_signature: localStorage.getItem('show_signature') ? 1 : 0,
            show_stamp: localStorage.getItem('show_stamp') ? 1 : 0,
            show_logo: localStorage.getItem('show_logo') ? 1 : 0,
            transactionId: localStorage.getItem('transactionId') || ''
        }

        if(user.usager) { form_d["usager_id"] = user.usager.id }

        form_d['document_password'] = randomStr(8)

        setFormData(form_d)
        setIAmReady(true)
    } else {
      setIAmReady(true)
    }

  }, [])

  const toggleCheckInCompanyInput = (constante) => {

    console.log('TOGGLING')
    $(`#${constante}_COMPANY`).toggleClass("d-none")
  }

  const handleVisaDocumentUpload = (e) => {

    if( e.target.files[0].size <  (1024*2*1024)) {
      
      setVisaDocuments({
        ...visaDocuments,
        [e.target.name]: {
          file: e.target.files[0],
          annuiare: (visaDocuments[e.target.name] ? visaDocuments[e.target.name]['annuiare'] : '')
        }
      })

      toggleCheckInCompanyInput(e.target.name)
    } else {
      
      Swal.fire({
        icon: "info",
        title: t("Info"),
        text: t("File size should not exceed 2MB."),
        showConfirmButton: false,
        showCancelButton: false,
        showCloseButton: true
      })
    }
  }

  const handleFormDataChange = (e) => {

    if(e.target.name !== "files") {

      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    } else {

      if(e.target.files.length) {

        const file = e.target.files[0]
        const reader = new FileReader();
        reader.onload = async function (evt) {

          const existingPdfBytes = evt.target.result
          // Load a PDFDocument from the existing PDF bytes
          const pdfDoc = await PDFDocument.load(existingPdfBytes)

          // Get the first page of the document
          const pages = pdfDoc.getPages()
          let should_pay = null;
          //if(etimbreIntitule.id !== formData.type_doc_id) {
              
              should_pay = docsTypes.filter(type => type.id === +formData.type_doc_id)[0].valeur * +pages.length
          //}

          setFormData({
            ...formData,
            number_of_pages: pages.length,
            [e.target.name]: e.target.files[0],
            should_pay
          })

          $('#upload-file').find('[data-bs-target="#document-form"]').trigger('click')
        }

        reader.readAsArrayBuffer(file)
      } else {
        
        setFormData({
          ...formData,
          number_of_pages: 1,
          [e.target.name]: null
        })
      }
    }
  }

  const getFormData = () => {

    let data = formData;
    const type_doc_constante = docsTypes.filter(d => d.id == formData.type_doc_id)[0].constante
    if(type_doc_constante === "LIB_DOC_TYPE_VISA") {

      data.visa_data = visaDocuments
    }

    if(user && user.usager) {

      //if(!data.signed_by) { data = {...data, signed_by: user.id} }
      if(!data.usager_id && user.usager) { data = {...data, usager_id: user.usager.id} }
  
      if(!data.usager_id) { setFormData(data) }
    }
    
    return formatFormData(data)
  }

  const formatFormData = (data) => {

    const form_data = new FormData();
    Object.keys(data).map(function (key) {

      switch(key) {
        case "files":

          form_data.append('files[]', data[key])
        break;
        case "visa_data":

          Object.keys(data[key]).forEach(function (k) {

            form_data.append(`visa_data[${k}][annuaire]`, data[key][k].annuaire)
            form_data.append(`visa_data[${k}][file]`, data[key][k].file)
          })
          break;
        case "ref_doc_suffix":
            break;
        case "ref_doc":

          let v = data[key]
          if(data["ref_doc_suffix"]) { v += "-"+data["ref_doc_suffix"] }
            form_data.append(key, v)
          break;
        case "previews":
          
          form_data.append(key, JSON.stringify(data[key]))
          break;
        case "visa_valid_from":
        case "visa_expire_at":

          form_data.append(key, format(data[key], 'dd-MM-yyyy'));
          break;
        case "settings":
          
          Object.keys(data[key]).forEach(function (k) { form_data.append(`settings[${k}]`, data[key][k]) });
          break;
        case "administratif_extra":
          
          Object.keys(data[key]).forEach(function (k) { form_data.append(`administratif_extra[${k}]`, data[key][k]) });
          break;
        case "list_of_usagers":
          break;
        case "selected_usagers":

          data[key].forEach(u => {

            form_data.append(`selected_usagers[]`, u)
          })
          break;

        default: 

        form_data.append(key, data[key])
        break;
      }

      return key
    })

    return form_data
  }

  const submitForm = (e) => {
    e.preventDefault()

    /* if(activeStep < 4) {

      alert("Please complete all the steps and use 'next' button to go to next step!");
      return;
    } */

    const form_data = getFormData()
    setIsProcessingFormData(true)
    setProgress(100)
    
    axios.post(`/api/qrcode/generate`, form_data)
    .then(r => r.data)
    .then(data => {

      setIsProcessingFormData(false)
      setErrors([])
      if(data.success) {

        localStorage.removeItem('transactionId');
        // setFormData({...formData, transactionId: ''})
        // toast.success(data.message);
        // setFormResponse({signer: data.signer, documents: data.documents});

        navigate(`/downloads/${data.signer.uuid}/thank-you`)
      } else {

        toast.error(data.message)
        if(data.errors) {

          setErrors(data.errors)
        }
      }
    }).catch(err => {

      if(err.response.status === 422)  setErrors(err.response.data.errors)
      setIsProcessingFormData(false)
    })
  }

  const moveForward = (_from_step_ = '', name = '', value = '') => { 
  
    let _next_step_;
    if(_from_step_) { _next_step_ = +_from_step_ +1 }
    else { _next_step_ = activeStep+1 }

    nextStep(_next_step_, name, value) 
  }

  const nextStep = (nextStep, name = '', value = '') => {

    if(name && value) {

      setFormData({
        ...formData,
        [name]: value
      })
    }
  
    ///close if any modal is opened
    if( $('.modal.show').length ) {

      $('.modal.show').find('.btn-close').trigger('click');
      $(".modal-backdrop.show").remove();
    }
    
    const type_doc_constante = docsTypes.filter(d => d.id == formData.type_doc_id)[0].constante
    if(type_doc_constante === "LIB_DOC_TYPE_VISA") {

      switch (nextStep) {
        case 3:
      
          let _modal_;
          if(!user.annuaire) { _modal_ = new bootstrap.Modal("#document-form"); } 
          else { _modal_ = new bootstrap.Modal("#usagers-modal"); }
  
          _modal_.show()
          break;
        case 4:
  
          if(!user.annuaire) { 
            //$('#document-form').find('.btn-close').trigger('click');
            if((Object.keys(visaDocuments).length !== documentsNeedForVisa.length)) {
            
              $('[data-bs-target="#upload-file"]').trigger('click');
              return;
            }
          } else {
  
            //$('#usagers-modal').find('.btn-close').trigger('click');
            const _modal_ = new bootstrap.Modal("#document-form");
            _modal_.show();
          }
          break;
        case 5:
  
          if(!totalPayable(formData)) {
  
            if((Object.keys(visaDocuments).length !== documentsNeedForVisa.length)) {
          
              //$('body #document-form').find('.btn-close').trigger('click');
              $('[data-bs-target="#upload-file"]').trigger('click');
              return;
            }
            
            //$('body #document-form').find('.btn-close').trigger('click');
          }
          break;
      }

      if( nextStep > 3 && !totalPayable(formData)) {

        setFormData({...formData, transactionId: 'FREE'})
        localStorage.setItem('transactionId', 'FREE')
      }
    } else {

      switch (nextStep) {
        case 2:
      
          let _modal_;
          if(!user.annuaire) { _modal_ = new bootstrap.Modal("#document-form"); } 
          else { _modal_ = new bootstrap.Modal("#usagers-modal"); }
  
          _modal_.show()
          break;
        case 3:
  
          if(!user.annuaire) { 
            //$('#document-form').find('.btn-close').trigger('click');
            if(!formData.files && etimbreIntitule.id !== formData.type_doc_id && adminstratifIntitule.id !== formData.type_doc_id) {
            
              $('[data-bs-target="#upload-file"]').trigger('click');
              return;
            }
          } else {
  
            //$('#usagers-modal').find('.btn-close').trigger('click');
            const _modal_ = new bootstrap.Modal("#document-form");
            _modal_.show();
          }
          break;
        case 4:
  
          if(!totalPayable(formData)) {
  
            if(!formData.files && etimbreIntitule.id !== formData.type_doc_id && adminstratifIntitule.id !== formData.type_doc_id) {
          
              //$('body #document-form').find('.btn-close').trigger('click');
              $('[data-bs-target="#upload-file"]').trigger('click');
              return;
            }
            
            //$('body #document-form').find('.btn-close').trigger('click');
          }
          break;
      
        default:
          
          /* if(nextStep === 4 && !formData.should_pay) {
          
            if(!formData.files && etimbreIntitule.id !== formData.type_doc_id && adminstratifIntitule.id !== formData.type_doc_id) {
          
              $('body #document-form').find('.btn-close').trigger('click');
              $('[data-bs-target="#upload-file"]').trigger('click');
              return;
            }
            
            $('body #document-form').find('.btn-close').trigger('click');
            setFormData({...formData, transactionId: 'FREE'})
            localStorage.setItem('transactionId', 'FREE')
          } */
          break;
      }

      if( nextStep >= 2 && !totalPayable(formData)) {

        setFormData({...formData, transactionId: 'FREE'})
        localStorage.setItem('transactionId', 'FREE')
      }
    }

    setProgress(Math.round(nextStep / totalSteps * 100))
    setActiveStep(nextStep);
  }

  const resetFormData = () => {

    setProgress(0)
    setActiveStep(1)
    setFormResponse({signer: '', documents: ''})
    setFormData({...formDataTemplate, administratif_extra: {...formDataTemplate.administratif_extra, country_id: formData.country_id}})
    localStorage.removeItem('transactionId');
    setForwardUsagersList([])
    setForwardingDocumentResponse({})
    setUsagerToForward({})
    setIsForwardingDocument(false)
    setShouldForwardDocument(false)
    setIsDocumentForwarded(false)
    setIsFileDownloaded(false)
    setIsSearchingForwardUsagers(false)
    setHideForwardUsagersList(false)
    setSearchForwardUsagers('')
    setSelectedTypeDoc('')
    setVisaDocuments({})
    setHaveVisaDocuments(false)
    
    $('#files').val('');
  }

  const postSearchForwardUsagers = (e) => {
    
    setSearchForwardUsagers(e.target.value)
    if(e.target.value.length < 3) { return; }

    setIsSearchingForwardUsagers(true)
    setUsagerToForward({})
    const search = e.target.value;
    axios.post('/api/search-usagers-for-forwarding-document', {search})
      .then(response => response.data)
      .then(data => {

        setForwardUsagersList(data)
        setIsSearchingForwardUsagers(false)
        setHideForwardUsagersList(false)
      }).catch(error => {

        setHideForwardUsagersList(false)
        setIsSearchingForwardUsagers(false)
      })
  }

  const fileDownloaded = () => { setIsFileDownloaded(true) }

  const forwardDocument = () => {

    setShouldForwardDocument(true)
  }

  const openInvitationModal = () => {

    const modal = new bootstrap.Modal('#registration-invitation')
    modal.show()
  }

  const sendInvitationAndFile = () => {

    setIsSubmittingInviteeForm(true)
    setInviteeValidationErrors([])
    axios.post('/api/send-invitation-with-file', {
      ...invitee,
      document_id: formResponse.documents[0].document_id
    })
    .then(r => r.data)
    .then(data => {

      setIsSubmittingInviteeForm(false)
      setStatusInviteeForm(t('Invitation email is sent to the user!'))
    })
    .catch(error => { 
      setIsSubmittingInviteeForm(false)
      setInviteeValidationErrors(Object.values(error.response.data.errors).flat()) 
    })
  }

  const selectUagerToForward = (user) => {

    setForwardUsagersList([])
    setHideForwardUsagersList(true)
    setSearchForwardUsagers(user.full_name)
    setIsSearchingForwardUsagers(true)
    axios.get(`/api/forward-usager-details/${user.id}`)
      .then(r => r.data)
      .then(data => { setIsSearchingForwardUsagers(false); setUsagerToForward(data) })
      .catch(error => {
        setIsSearchingForwardUsagers(false)
      })
  }

  const forwardDocumentToUsager = () => {

    setIsForwardingDocument(true)
    axios.post('/api/forward-document-to-usager', {
      document_id: formResponse.documents[0].document_id,
      user: usagerToForward.id
    })
    .then(r => r.data)
    .then(data => {

      setIsForwardingDocument(false)
      if(data.status === 200) { setIsDocumentForwarded(true) }
      setForwardingDocumentResponse(data)
    })
    .catch(error => {

      setIsForwardingDocument(false)
    })
  }

  return (<>
    <div className='row'>
      <h2 className="font-weight-light text-center text-grey-4 text-capitalize">{t('Title Plf')}</h2>
      <h6 className="text-info text-center mb-4">{t('Slogan Plf')}</h6>
      {!iAmReady ? <IsLoading /> : <div className='col-xs-12 card shadow py-6 mt-auto position-relative'>

        {user && user.usager && !user.usager.passport && <div className='position-absolute text-center d-flex justify-content-center align-items-center' 
          style={{ 
            backgroundColor: "#f1f1f19c", 
            height: '100%', 
            width: '100%', 
            zIndex: 99, 
            top: 0, 
            left: 0
          }}>
          <div>
            <h4>{t("Default of Passport")}</h4>
            <Link className="btn btn-primary" to="/submit/passport-details">
              <i className="fa fa-passport"></i> {t("Passport Form")}
            </Link>
          </div>
        </div>}

        <div className='row'>
            {Object.keys(errors).length ? <div className='col-xs-12 col-sm-12 col-md-12'>
              <div className='alert alert-danger'>
              <div>{t('Something went wrong')}</div>
                <ul>
                  {Object.keys(errors).map(index => <li key={index}>{errors[index]}</li>)}
                </ul>
              </div>
            </div> : ''}

            {isProcessingFormData && <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100 bg-secondary bg-opacity-10" style={{ zIndex: 9999, top: '0' }}>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">{t('Loading')}...</span>
              </div>
            </div>}

            <div className='uploader-tool col-xs-12 flex-md-row max-w-1000 d-flex position-relative'>

              <ProgressBar progress={progress}/>
              <Steps
                visaDocuments={visaDocuments}
                errors={errors} 
                moveForward={moveForward} 
                docTypesNeedFile={docTypesNeedFile} 
                setDocsTypes={setDocsTypes} 
                docsTypes={docsTypes} 
                setErrors={setErrors} 
                formData={formData} 
                getFormData={getFormData} 
                formatFormData={formatFormData} 
                handleFormDataChange={handleFormDataChange}
                setFormData={setFormData} 
                activeStep={activeStep} 
                setActiveStep={setActiveStep} 
                submitForm={submitForm} nextStep={nextStep} 
                etimbreIntitule={etimbreIntitule}
                adminstratifIntitule={adminstratifIntitule}
                isProcessingFormData={isProcessingFormData}
                setIsProcessingFormData={setIsProcessingFormData}
                formResponse={formResponse}
                setFormResponse={setFormResponse}
                selectedTypeDoc={selectedTypeDoc}
                setSelectedTypeDoc={setSelectedTypeDoc}
                />
            </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-4 offset-md-4">
            {(formResponse.signer ? (docsTypes.filter(d => d.id == formData.type_doc_id)[0].constante !== "LIB_DOC_TYPE_VISA" ? <div className="text-center">
                  {!isFileDownloaded ? <><div className='callout callout-success text-start'>{etimbreIntitule.id !== formData.type_doc_id ? t('Document Ready To Download') : t('Etimbre Ready To Download')}</div>
                  <div className="w-100 callout callout-warning text-start">
                    <strong><i className='bi bi-exclamation-octagon-fill'></i></strong> {t("Notice Download")}
                  </div>
                  <div className='w-100 btn-group btn-sm mb-2'>
                    <button title={t("Forward Document To Receiver")} disabled={shouldForwardDocument} onClick={forwardDocument} type="button" className="btn btn-success"><i className="bi bi-send-fill"></i></button>
                    <DownloadAttachmentButton downloaded={fileDownloaded} file={formResponse.documents[0]} />
                    <button disabled={!isFileDownloaded} type="button" className='btn btn-danger' onClick={resetFormData}>
                      <i className='bi bi-arrow-clockwise'></i> {t("Reset & Add New")}
                    </button>
                  </div>
                  <div className="input-group">
                    {shouldForwardDocument ? 
                      (
                        <div className="is-loading position-relative">
                          <input type="text" className="form-control" placeholder={t('Search Usagers')} onChange={postSearchForwardUsagers} value={searchForwardUsagers} disabled={isForwardingDocument || isDocumentForwarded} />
                          {isSearchingForwardUsagers && <div className="spinner-border spinner-border-sm"></div>}
                        </div>
                      ) :
                    <input readOnly type="text" value={formResponse.documents[0].filename} className="form-control" />}
                  </div>
                  {/* <div className="text-start"><small><a href='#' onClick={(e) => {e.preventDefault(); openInvitationModal()}} className="text-decoration-none text-muted">{t('Registration Invitation')}</a></small></div> */}
                  {searchForwardUsagers && !isSearchingForwardUsagers && !hideForwardUsagersList && <ul className="dropdown-menu show">
                      {
                        searchForwardUsagers.length >= 3 ? 
                          (!isSearchingForwardUsagers && (!!forwardUsagersList.length ? 
                            forwardUsagersList.map((user) => <li key={user.id}><a onClick={(e) => {e.preventDefault(); selectUagerToForward(user)}} className="dropdown-item" href="#">{user.full_name}</a></li>) : 
                            <li><a className="dropdown-item" href="#" onClick={(e) => {e.preventDefault(); setHideForwardUsagersList(true); openInvitationModal()}}>{t('No results found!')} <span className='text-primary'>{t('Registration Invitation')}</span></a></li>)) :
                          <li><a href="#" className='dropdown-item'>{t('Please Enter')} "{3-searchForwardUsagers.length}" {t('Characters To Search')}</a></li>
                      }
                  </ul>}

                  {!!Object.keys(usagerToForward).length && <>{!Object.keys(forwardingDocumentResponse).length ? (<div className="callout callout-info text-start">
                    <p><strong>{t('Full Name')}</strong>: {usagerToForward.full_name}</p>
                    <p><strong>{t('Mobile')}</strong>: {usagerToForward.mobile}</p>
                    <p><strong>{t('Country')}</strong>: {usagerToForward.country}</p>
                    <p><strong>{t('City')}</strong>: {usagerToForward.city}</p>
                    <p><strong>{t('Address')}</strong>: {usagerToForward.address}</p>
                  </div>) : ''}
                    {!!Object.keys(forwardingDocumentResponse).length && <div className={`mt-3 alert alert-${forwardingDocumentResponse.status !== 200 ? 'error' : 'success'}`}>{t(forwardingDocumentResponse.message)}</div>}
                  <button disabled={isForwardingDocument || isDocumentForwarded} onClick={forwardDocumentToUsager} className="btn btn-primary btn-sm w-100">{isForwardingDocument ? <span className='spinner-border spinner-border-sm'></span> : <i className='bi bi-send-fill'></i>} {t('Forward Document')}</button>
                  </>}</> : <>
                    <p>{t('Notice After Download')}</p>
                    <button type="button" className='btn btn-danger w-100 btn-sm' onClick={resetFormData}>
                      <i className='bi bi-arrow-clockwise'></i> {t("Reset & Add New")}
                    </button>
                  </>}
                </div> : <><div className="w-100 callout callout-success text-start">
                    <strong><i className='bi bi-exclamation-octagon-fill'></i></strong> {t("Visa Success Message")}
                  </div>
                  <button type="button" className='btn btn-danger w-100 btn-sm' onClick={resetFormData}>
                    <i className='bi bi-arrow-clockwise'></i> {t("Reset & Add New")}
                  </button>
                </>) : '')}
          </div>
        </div>
      </div>}
    </div>
    <div className="row my-4">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <ul className="mt-12 list-unstyled"> 
            <li className="bi bi-bookmark-plus text-grey-5"> Sécuriser vos documents par cryptographie via un QRCODE </li>
            <li className="bi bi-bookmark-plus text-grey-5"> Assurer la Confidentialité - l'Authenticité - l'Intégrité</li>
            <li className="bi bi-bookmark-plus text-grey-5"> Garantir la Non-Répudiation en toutes situations</li>
          </ul>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <ul className="mt-12 list-unstyled"> 
            <li className="bi bi-bookmark-plus text-grey-5"> Vérifier grace à un DigiVerif en mode Offline</li>
            <li className="bi bi-bookmark-plus text-grey-5"> Conserver documents numériques grace à un Doc-I-Wallet</li>
            <li className="bi bi-bookmark-plus text-grey-5"> Tracer en toutes situations</li>
          </ul>
        </div>
    </div>

    <Modal id="registration-invitation">
      <div className='modal-header'>
        <h5 className='modal-title'>{t('Send Registration Invitation & Notification')}</h5>
        <button type="button" className='btn-close' data-bs-dismiss="modal" aria-label='Close'></button>
      </div>

      <div className='modal-body'>
        <ValidationErrors errors={inviteeValidationErrors} />
        <form>
          <div className='row'>
            {statusInviteeForm && <div className='col-md-12'>
              <div className='alert alert-success'>
                <strong>{t('Success!')}</strong> {statusInviteeForm}
              </div>
            </div>}
            <div className='col-md-12'>
              <div className='mb-3'>
                <label htmlFor='email'>{t('Email')}</label>
                <input type="email" id="email" disabled={isSubmittingInviteeForm || statusInviteeForm} value={invitee.email} onChange={e => setInvitee({...invitee, email: e.target.value})} className='form-control' />
              </div>
            </div>

            <div className='col-md-12'>
              <div className='mb-3'>
                <label htmlFor='mobile'>{t('Mobile')}</label>
                <input type="text" id="mobile" disabled={isSubmittingInviteeForm || statusInviteeForm} value={invitee.mobile} onChange={e => setInvitee({...invitee, mobile: e.target.value})} className='form-control' />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="modal-footer">
        <button type="submit" disabled={isSubmittingInviteeForm || statusInviteeForm} onClick={sendInvitationAndFile} className="btn btn-primary">{isSubmittingInviteeForm ? t('Submitting....') : t('Send Invitation & Document')}</button>
      </div>
    </Modal>

    <Modal id="upload-file">
      <div className='modal-header'>
        <h5 className='modal-title'>{t('Upload Document')}</h5>
        <button type="button" className='btn-close' data-bs-dismiss="modal" aria-label='Close'></button>
      </div>

      <div className='modal-body'>
        {!docsTypes.filter(d => d.id == formData.type_doc_id).length ? '' : <>
          {docsTypes.filter(d => d.id == formData.type_doc_id)[0].constante !== "LIB_DOC_TYPE_VISA"  ? <>
            <div className={"alert alert-"+(formData.files ? 'success' : 'info')}>
            {!formData.files ? <>
                <strong>{t('Info!')}</strong> {t('SVP Upload Document!')} 
              </> : <>
                  <strong>{t('Success!')}</strong> {t('Document Uploaded')} 
              </>}
            </div>

            <div className='input-group'>
              <button onClick={() => { $('#files').trigger('click') }} className={'btn '+(!formData.files ? 'btn-secondary' : 'btn-success')}>{t("Choose File")}</button>
              <input className='form-control' readOnly value={!formData.files ? t("No file chosen") : t("File selected")+" ( "+formData.files.name+" )"} />
            </div>
            <input type="file" name="files" id="files" placeholder={t("No file chosen")} className={"d-none"}  onChange={handleFormDataChange} accept="application/pdf" />
          </> : <VisaDocumentInputs visaDocuments={visaDocuments} documentsNeedForVisa={documentsNeedForVisa}
                  handleVisaDocumentUpload={handleVisaDocumentUpload}
                  toggleCheckInCompanyInput={toggleCheckInCompanyInput}
                  documentCheckinAnnuaires={documentCheckinAnnuaires}
                  haveVisaDocuments={haveVisaDocuments}
                  setHaveVisaDocuments={setHaveVisaDocuments}
                  setVisaDocuments={setVisaDocuments} />}
        </>}
      </div>

      {docsTypes && formData.type_doc_id && <div className="modal-footer">
        <button type='button' disabled={docsTypes.filter(d => d.id == formData.type_doc_id)[0].constante === "LIB_DOC_TYPE_VISA" ? !haveVisaDocuments : !formData.files} 
          className={'btn btn-'+((haveVisaDocuments ? 'success' : 'secondary'))} data-bs-toggle="modal" data-bs-target="#document-form">
          <i className='bi bi-arrow-return-left'></i>{t('Back To Document Details')} 
        </button>

        {/* <MoveForwardButton disabled={docsTypes.filter(d => d.id == formData.type_doc_id)[0].constante === "LIB_DOC_TYPE_VISA" ? !haveVisaDocuments : !formData.files} moveForward={() => { moveForward()}} /> */}
      </div>}
    </Modal>

    {documentsNeedForVisa.map(intitule => (<VisaDocumentNewAnnuaire 
      key={intitule.constante} 
      setDocumentCheckinAnnuaires={setDocumentCheckinAnnuaires} 
      documentCheckinAnnuaires={documentCheckinAnnuaires} 
      visaDocuments={visaDocuments} 
      setVisaDocuments={setVisaDocuments} 
      intitule={intitule} />))}
  </>)
}

export default IndexContent