import React, { useContext, useEffect, useState } from "react";
import { Loader } from "google-maps";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import jQuery from "jquery";
import axios from "../lib/axios";
import { useTranslation } from "react-i18next";
import { GoogleContext } from "../contexts/GoogleContext";
window.$ = window.jQuery = jQuery;
require("geocomplete");

const GoogleMap = ({
  autocompleteInput,
  latitude,
  longitude,
  address,
  onDragEnd,
  forceLatLng,
  ifConfirmed,
  askIfWantsToUpdate
}) => {

    const {google} = useContext(GoogleContext)

    const [defaultLatitude, setDefaultLatitude] = useState(null);

    const [defaultLongitude, setDefaultLongitude] = useState(null);

    const [mapInstance, setMapInstance] = useState(null)

    const [marker, setMarker] = useState(null)

    const {t} = useTranslation()
    
  useEffect(async () => {
    axios
      .get("/api/parametres/DEFAULT_MAP")
      .then((r) => r.data)
      .then((data) => {
        
        const [lat, lng] = data.valeur.split(",");
        setDefaultLatitude(lat);
        setDefaultLongitude(lng);
      });
  }, []);

  useEffect(async () => {
    if (google && defaultLatitude && defaultLongitude) {

        let lat, lng;
        if(latitude) { lat = latitude; }
        else { lat = defaultLatitude; }

        if(longitude) { lng = longitude; }
        else { lng = defaultLongitude; }

        var map;
        var myLatlng = new google.maps.LatLng(lat, lng)
        var mapOptions = {
            zoom: 10,
            center: myLatlng,
            //mapTypeId: google.maps.MapTypeId.ROADMAP,
        };

        map = new google.maps.Map(document.getElementById("map"), mapOptions);
        setMapInstance(map)
    }
  }, [google, defaultLatitude, defaultLongitude]);

  useEffect(async () => {
    
    if(google && mapInstance) {

        var lat, lng;
        let localMarker = marker;
        var geocoder = new google.maps.Geocoder();
        if(latitude) { lat = latitude; }
        else { lat = defaultLatitude; }

        if(longitude) { lng = longitude; }
        else { lng = defaultLongitude; }

        let geocoderOptions = {};
        
        
        var infowindow = new google.maps.InfoWindow();
        var myLatlng;

        if(forceLatLng == "true") {

            myLatlng = new google.maps.LatLng(lat, lng)
            if(!marker) {

                localMarker = new google.maps.Marker({
                    map: mapInstance,
                    position: myLatlng,
                    draggable: true,
                })

                setMarker(localMarker);
            }  else { marker.setPosition(myLatlng) }

            await geocoder.geocode({latLng: myLatlng}, function (results, status) {
        
                if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
    
                        infowindow.setContent(results[0].formatted_address);
                        infowindow.open(mapInstance, marker);
                    }
                }
            });
        } else {
            
            if(address) {
                
                geocoderOptions["address"] = address
                await geocoder.geocode({address}, function (results, status) {
    
                    if (status == google.maps.GeocoderStatus.OK) {
                        
                        myLatlng = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng())
                        if(!marker) {
                            
                            localMarker = new google.maps.Marker({
                                map: mapInstance,
                                position: myLatlng,
                                draggable: true,
                            })
    
                            setMarker(localMarker);
                        } else {
                            marker.setPosition(myLatlng)
                        }
                    }
                })
            } else {
                
                myLatlng = new google.maps.LatLng(lat, lng)
                if(!marker) {
    
                    localMarker = new google.maps.Marker({
                        map: mapInstance,
                        position: myLatlng,
                        draggable: true,
                    })
    
                    setMarker(localMarker);
                }  else { marker.setPosition(myLatlng) }
    
                await geocoder.geocode({latLng: myLatlng}, function (results, status) {
            
                    if (status == google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
        
                            infowindow.setContent(results[0].formatted_address);
                            infowindow.open(mapInstance, marker);
                        }
                    }
                });
            }
        }

        mapInstance.setCenter(myLatlng)
        const eListener = google.maps.event.addListener(localMarker, "dragend", function () {
            geocoder.geocode(
                { latLng: localMarker.getPosition() },
                function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0]) {

                        if(onDragEnd) {

                            onDragEnd( formatGeocoderResult(results, localMarker) )
                        }

                        if(askIfWantsToUpdate) {

                            askIfWantsToUpdateData(formatGeocoderResult(results, localMarker))
                        }

                        infowindow.setContent(results[0].formatted_address);
                        infowindow.open(mapInstance, localMarker);
                    }
                }
                }
            );
        });


        ///old

        /* var infowindow = new google.maps.InfoWindow();
        if(address) { 
            
            geocoderOptions["address"] = address 
            let marker = new google.maps.Marker({
                map: mapInstance,
                //position: myLatlng,
                draggable: true,
            });
            window.geocoder.geocode(geocoderOptions, function (results, status) {
            
                if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
        
                        infowindow.setContent(results[0].formatted_address);
                        infowindow.open(mapInstance, marker);
                    }
                }
            });
        } */

        return () => google.maps.event.removeListener(eListener)
    }

  }, [google, ,mapInstance, address])

  function askIfWantsToUpdateData(result) {

    Swal.fire({
        title: t("Address Confirmation"),
        icon: 'question',
        text: result.address,
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: t("Yes, I'm sure"),
        cancelButtonText: t("No, cancel it!")
    }).then(decision => {

        if(decision.isConfirmed) {

            ifConfirmed(result)
        }
    })
}

  function formatGeocoderResult (results, marker) {

    const address_components = results[0]["address_components"];
    const route = address_components.filter(ad => ad.types.includes('route'))
    const locality = address_components.filter(ad => ad.types.includes('locality'));//city
    const postal_code = address_components.filter(ad => ad.types.includes('postal_code'))
    const street_number = address_components.filter(ad => ad.types.includes('street_number'))
    let country;
        
    country = address_components.filter(ad => ad.types.includes('country'))
    let _address_ = ''
    let _cp_ = ''
    let _country_ = ''
    let _city_ = '';

    if(postal_code.length) { _cp_ = postal_code[0].long_name }
    if(country.length) { _country_ = country[0].long_name }
    if(locality.length) { _city_ = locality[0].long_name }
    if(route.length) {

        _address_ = route[0].long_name;
        if(street_number.length) { _address_ =  street_number[0].long_name+','+_address_; }
    } else {

        var formatted_address=results[0].formatted_address;
        _address_ = results[0].address_components[0].short_name;
        var long_name= results[0].address_components[0].long_name;

        var lastIndex = formatted_address.lastIndexOf(",");
        var short_address = formatted_address.substring(0, lastIndex);//remove country name
        
        var last = short_address.substring(short_address.lastIndexOf(",") + 1, short_address.length);
        var last_string=jQuery.trim(last);
        var ret = last_string.split(" ");
        
        _cp_ = ret[0];
        var SecondlastIndex = short_address.lastIndexOf(",");
        var final_short_address = short_address.substring(0, SecondlastIndex);
        _country_ = jQuery.trim(formatted_address.substring(formatted_address.lastIndexOf(",") + 1, formatted_address.length));
        _address_ = (final_short_address || short_address);
    }

    return {
        address: _address_,
        postal_code: _cp_,
        country: _country_,
        city: _city_,
        lat: marker.getPosition().lat(),
        lng: marker.getPosition().lng()
    }
  }

  return <div style={{ height: 450 + "px", width: 100 + "%" }} id="map"></div>;
};

export default GoogleMap;
