import {React} from 'react';
import { useTranslation } from 'react-i18next';

const Ewallet = () => {
const { t } = useTranslation()

return <div className='container'>
        <div className="row  opacity-50">
            <div className="col-md-4">
                <div className='card show-sm mb-3' Style='height:550px;overflow:auto;'>
                    <div className='card-body'>
                        <h4 className='card-title'>{t('Doc-I-Wallet')}</h4>
                        <p className='card-text'>
                            Doc-I-Wallet (Document In Wallet) est un portefeuille digital multi-documents mobile sécurisé qui permet de conserver tous nos documents numériques sous format QR CODE comme: pièces d'identités et titres sécurisés, les habilitations diverses, pass sanitaires et vaccinaux, attestations, certificats, dans nos smartphones.
                        </p>
                        <p>
                            Grâce à cette solution, prouver son identité, une habilitation ou d'accéder aux droits et services qui nous sont réservés devient simple avec un confort d'utilisation.
                        </p>
                        <p className='card-text'>
                            Doc-I-Wallet offre aux citoyens un contrôle total sur leurs données personnelles, avec la liberté de décider quelles informations ils partagent, quand et avec qui ils souhaitent les partager.
                        </p>
                        <p>
                            Dans le même temps, il permet aux pouvoirs publics de pouvoir contrôler avec un dispositif digital dédié.
                        </p>
                        <p className='card-text'>
                            Il présente ainsi l'avantage d'être simple à émettre, à gérer et à vérifier, tout en offrant un outil puissant pour lutter efficacement contre l'usurpation d'identité, réduire le travail administratif et soutenir l'économie numérique. 
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className='card show-sm mb-3 text-center' Style='height:550px'>
                    <div className='card-body'>
                        <img src="/dociw1.png" className="" />
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className='card show-sm mb-3 text-center' Style='height:550px'>
                    <div className='card-body'>
                        <img src="/dociw2.png" className="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Ewallet