import {React} from 'react';
import { useTranslation } from 'react-i18next';

const Everifier = () => {
    const { t } = useTranslation()

    return <div className='container'>
        <div className="row opacity-50">
            <div className="col-md-4">
                <div className='card show-sm mb-3' Style='height: 550px;overflow:auto;'>
                    <div className='card-body'>
                        <h4 className='card-title'>{t('DigiVerif')}</h4>
                        <p className='card-text'>
                            DigiVerif (Digital Verifier) est un dispositif logiciel pour Verifier l'authencité des documents numériques délivrés par une entité.
                        </p>
                        <p className='card-text'>
                            La solution déployée sur des terminaux dédiés, permet aux forces de l'ordre ou à des agents d'une entité de verifier en mode hors connexion l'authenticité des documents présentés par les citoyens à savoir:<br />
                        </p>
                        <ul>
                            <li>Pièce d'identité</li>
                            <li>Pass Sanitaire & Vaccinal</li>
                            <li>Permis de conduire</li>
                            <li>Diverses Habilitations</li>
                            <li>Pièce de bord & Assurance</li>
                            <li>Certificat,Attestation,Contrat,Etc.</li>
                        </ul>
                        <p className='card-text'>
                            Si bien entendu ces documents sont dématérialisés au format QR CODE sécurisé et conformes aux standards
                        </p>
                        <p  className='card-text'>
                            Il présente ainsi l'avantage d'être simple à vérifier des documents, tout en offrant un outil puissant pour faciler les prérogatifs des agents assermentés ou habilités.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className='card show-sm mb-3 text-center' Style='height: 550px;'>
                    <div className='card-body'>
                        {/* <h4 className='card-title'>{t('Image')}</h4> */}
                        <img src="/digiverif1.png" className="" />
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className='card show-sm mb-3 text-center' Style='height: 550px;'>
                    <div className='card-body'>
                        {/* <h4 className='card-title'>{t('Image')}</h4> */}
                        <img src="/digiverif2.png" className="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Everifier