import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AsyncCreateableSelect from 'react-select/async-creatable';
import $ from 'jquery'
import {Modal as MT_Modal} from  'bootstrap'
import HelperContext from "../../contexts/HelperContext";

export default function VisaDocumentInputs({
    visaDocuments,
    documentsNeedForVisa,
    handleVisaDocumentUpload,
    documentCheckinAnnuaires,
    setVisaDocuments,
    setHaveVisaDocuments,
    toggleCheckInCompanyInput,
    haveVisaDocuments
}) {

    const {t} = useTranslation()

    const {required_label} = useContext(HelperContext)

    useEffect(() => {

        if(!haveVisaDocuments) {

            let data_we_have = []
            documentsNeedForVisa.map(d => {

                if(visaDocuments[d.constante]) {

                    if( visaDocuments[d.constante]["annuaire"] && visaDocuments[d.constante]["file"]) {

                        data_we_have.push(true)
                    }
                }
            })

            if(data_we_have.length > 0) {

                if(data_we_have.length === Object.keys(documentsNeedForVisa).length) { setHaveVisaDocuments(true) }
            }
        }
    }, [visaDocuments])

    return <>
    {!haveVisaDocuments ? <div className='alert alert-info'>
      <strong><i className='bi bi-exclamation-octagon-fill'></i></strong> {t('SVP Upload Document!')} 
      </div> : <div className='alert alert-success'>
          <strong>{t('Success!')}</strong> {t('Document Uploaded')} 
      </div>}

      {documentsNeedForVisa.map(d => <div className='col-md-12' key={d.id}>
        <div className='mb-3'>
          {required_label(d.constante, d.libelle)}
          <div className='input-group'>
            <button onClick={() => { $(`#${d.constante}`).trigger('click') }} className='btn btn-secondary'>{t("Choose File")}</button>
            <input className='form-control' readOnly value={(visaDocuments[d.constante] && visaDocuments[d.constante]["file"]) ? t("File selected")+" ( "+visaDocuments[d.constante].file.name+" )" : t("No file chosen")} />
            {(visaDocuments[d.constante] && visaDocuments[d.constante]["file"]) && <button className='btn btn-primary' type='button' onClick={() => toggleCheckInCompanyInput(d.constante)}>{t('Issued By')}</button>}
          </div>

          <input type="file" className='d-none' id={d.constante} name={d.constante} onChange={handleVisaDocumentUpload} accept="application/pdf" />
        </div>
        
        <div className='mb-3 d-none' id={d.constante+"_COMPANY"}>
        <AsyncCreateableSelect className='z-index-999' 
          onCreateOption={(option) => { 

            $('.modal.show').find('.btn-close').trigger('click')
            setTimeout(() => {
              const modal = new MT_Modal("#"+d.constante+"_modal")
              modal.show()
            }, 700)
          }}
          placeholder={t("Choose")+" "+d.libelle+" "+t("Check-in")} 
          defaultOptions={(documentCheckinAnnuaires[d.constante] ? documentCheckinAnnuaires[d.constante].map(a => ({label: a.raison_sociale, value: a.id})) : [])}
          loadOptions={(inputValue) => new Promise((resolve) => {

              if(inputValue.toLowerCase().length > 3) {

                setTimeout(() => {
                    
                    if(documentCheckinAnnuaires[d.constante]) {
  
                      const filtered = documentCheckinAnnuaires[d.constante].filter((i) =>
                        i.raison_sociale.toLowerCase().includes(inputValue.toLowerCase())
                      )
  
                      if(filtered) {
  
                        resolve(filtered.map(i => ({value: i.id, label: i.raison_sociale})))
                      } else {
  
                        resolve([])
                      }
                    } else {
  
                      resolve([])
                    }
                }, 700)
              }
          })}
          onChange={(option) => {
              
            setVisaDocuments({
              ...visaDocuments,
              [d.constante]: {
                file: (visaDocuments[d.constante] ? visaDocuments[d.constante]['file'] : null),
                annuaire: option.value
            }})
          }} />
        </div>
      </div>)}
  </>
}