import {React} from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const Footer = () => {
    const { t } = useTranslation()
    return <footer className="bg-primary text-white">
        <div className="container">
            <div className="row flex-column-reverse flex-lg-row">
                <div className="col-12 col-lg-12">
                    <h3 className="font-weight-bold h6 text-uppercase text-white mb-1">{t('Informations')} </h3>

                    <div className="row">
                        <div className="col-6 col-sm-3">
                            <ul className="list-unstyled">
                                <li className="pt-0"><Link to="cryptographie" className="text-white">{t('Cryptography')} </Link></li>
                            </ul>
                        </div>
                        <div className="col-6 col-sm-3">
                            <ul className="list-unstyled">
                                <li className="pt-0"><Link to="ewallet" className="text-white">{t('Doc-I-Wallet')} </Link></li>
                            </ul>
                        </div>
                        <div className="col-6 col-sm-3">
                            <ul className="list-unstyled">
                                <li className="pt-0"><Link to="everifier" className="text-white">{t('DigiVerif')} </Link></li>
                            </ul>
                        </div>
                        <div className="col-6 col-sm-3">
                            <ul className="list-unstyled">
                                <li className="pt-0"><Link to="company" className="text-white">{t('À Propos')} </Link></li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* <div className="col-12 col-lg-4">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="small text-white mb-1">Abonnez-vous à notre lettre d’information</h3>
                            <form className="form-inline" action="" method="post" target="_blank" >
                                <div className="form-group mb-0">
                                    <label htmlFor="footer-subscribe-email" className="sr-only">Your email address</label>
                                    <input id="footer-subscribe-email" className="form-control form-control-sm" name="EMAIL" placeholder="Votre adresse électronique" type="email" />
                                </div>
                                <button type="submit" className="btn btn-grey-8 btn-sm ml-2">S'abonner</button>
                            </form>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="row">
                <div className="col-12 text-right">
                    <small className="text-white-7">Copyright © 2022 SICOGES SAS <span className="d-none d-sm-inline">-</span> <span className="d-block d-sm-inline">All Rights Reserved</span></small>
                </div>
            </div>
        </div>
    </footer>
}


export default Footer