import { React, useContext, useEffect, useRef, useState } from "react"
import { useAuth } from "../hooks/auth"
import axios from "../lib/axios"
import {toast} from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"
import IsLoading from "../components/IsLoading"
import HelperContext from "../contexts/HelperContext"
import Pills from "../components/Nav/Pills"
import { Tabs as UsagerTabs, TabsContent as UsagerTabsContent } from "../components/Pages/Profile/Usager"
import { Tabs as AnnuaireTabs, TabsContent as AnnuaireTabsContent } from "../components/Pages/Profile/Annuaire"
import NavLinkItem from "../components/Nav/NavLinkItem"

const Profile = () => {
    
    const helpers = useContext(HelperContext)

    const { t } = useTranslation()

    const {user, mutate} = useAuth({middleware: 'auth'})

    const [isSaving, setIsSaving] = useState(false)

    const [haveUoData, setHaveUoData] = useState(false)

    const [countries, setCountries] = useState([])

    const [countriesData, setCountriesData] = useState([])

    const [cities, setCities] = useState([])

    const [userData, setUserData] = useState({
        username: '',
        name: '',
        password: '',
        email: '',
        /* country_id: '',
        city_id: '',
        adresse: '',
        adresse_suite: '',
        csbp: '', */
    })

    const [annuaireData, setAnnuaireData] = useState({
        category_id: '',
        type_id: '',
        num_compte: '',
        raison_sociale: '',
        formejuridique_id: '',
        rccm: '',
        date_rccm: '',
        siren: '',
        idfiscal: '',
        representant: '',
        capital: '',
        cp: '',
        country_id: '',
        city_id: '',
        adresse: '',
        adresse_suite: '',
        csbp: '',
        lat: '',
        lng: '',
    })

    const [usagerData, setUsagerData] = useState({
        nom: '',
        prenom: '',
        adresse: '',
        adrsuite: '',
        cp: '',
        csbp: '',
        mobile: '',
        type_id: '',
        civilite: '',
        country_id: '',
        formejuridique_id: '',
        denomination: '',
        profession: '',
        fonction_id: '',
        city_id: '',
        adresse_suite: '',
        lat: '',
        lng: '',
    })

    const [uoData, setUoData] = useState({
        country_id: '',
        city_id: '',
        url: '',
        num_compte: '',
        telephone: '',
        fax: '',
        mobile: '',
        email: '',
        adresse: '',
        adresse_suite: '',
        csbp: '',
        typevoie_id: '',
        trigramme: '',
        raison_sociale: '',
        formejuridique_id: '',
        activite_id: '',
        type_id: '',
    })

    const [errors, setErrors] = useState([])

    useEffect(async () => {

        if(user) {

            let user_data = {
                ...userData,
                username: user.username,
                name: user.name,
                email: user.email
            };

            const data = {}
            Object.keys(uoData).map(d => {

                data[d] = user.uo[d]
            })

            setUoData(data)
            setHaveUoData(true)
            axios.get(`/api/countries`)
                .then(r => r.data)
                .then(data => {

                    setCountriesData(data)
                    setCountries(data.map(country => ({'value': country.id, 'label': country.name})))
                    //handleCountryChange({value: user.annuaire.country_id})
                    axios(`/api/countries/${user.usager?.country_id || user.annuaire?.country_id}/cities`)
                        .then(r => r.data)
                        .then(data => {
                            
                            setCities(data.map(city => ({value: city.id, label: city.name})))
                        });
                })

            if(user.annuaire) {

                const aData = {}
                Object.keys(user.annuaire).map(k => { aData[k] = user.annuaire[k] || '' })
                setAnnuaireData(aData)
                /* setAnnuaireData({
                    ...annuaireData,
                    category_id: user.annuaire.category_id || '',
                    type_id: user.annuaire.type_id || '',
                    formejuridique_id: user.annuaire.formejuridique_id || '',
                    num_compte:user.annuaire.num_compte || '',
                    raison_sociale:user.annuaire.raison_sociale || '',
    
    
                    rccm: user.annuaire.rccm || '',
                    date_rccm: user.annuaire.date_rccm || '',
                    siren: user.annuaire.siren || '',
                    idfiscal: user.annuaire.idfiscal || '',
                    representant: user.annuaire.representant || '',
                    capital: user.annuaire.capital || '',
                    cp: user.annuaire.cp || '',
                    csbp: user.annuaire.csbp || '',
                }) */

                /* user_data = {
                    ...user_data,
                    country_id: user.annuaire.country_id || '',
                    city_id: user.annuaire.city_id || '',
                    adresse: user.annuaire.adresse || '',
                    adresse_suite: user.annuaire.adresse_suite || '',
                } */
            }

            if(user.usager) {

                /* user_data = {
                    ...user_data,
                    country_id: user.usager.country_id || '',
                    city_id: user.usager.city_id || '',
                    adresse: user.usager.adresse || '',
                    adresse_suite: user.usager.adresse_suite || '',
                    csbp: user.usager.csbp || '',
                } */

                const uData = {}
                Object.keys(user.usager).map(k => { uData[k] = user.usager[k] || '' })
                setUsagerData(uData)
                /* setUsagerData({
                    ...usagerData,
                    ...user.usager
                }) */
            }

            setUserData({...user_data})
        }
    }, [user])

    function handleChange(e) {

        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    function handleUsagerChange(e) {

        setUsagerData({
            ...usagerData,
            [e.target.name]: e.target.value
        })
    }

    function handleUserDataChange(e) {

        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    function handleCountryChange (country) {

        if(country) {
            
            if(user.usager) { setUsagerData({...usagerData, country: country.value, city_id: ''}) }
            if(user.annuaire) { setAnnuaireData({...annuaireData, country: country.value, city_id: ''}) }
            /* setUserData({
                ...userData,
                country_id: country.value,
                city_id: ''
            }) */
            
            axios(`/api/countries/${country.value}/cities`)
                .then(r => r.data)
                .then(data => {
                    
                    setCities(data.map(city => ({value: city.id, label: city.name})))
                });
        } else { setCities([]) }
    }

    function handleUoChange(name, value) {

        setUoData({
            ...uoData,
            [name]: value
        })
    }

    function handleSubmit() {

        setIsSaving(true)
        let _data = {...userData}
        if(user.usager) { _data = {..._data, ...usagerData} }
        else  { _data = {..._data, ...annuaireData } }

        //_data = {..._data, ...uoData}
        axios.post('/api/user', { ..._data })
            .then(r => r.data)
            .then(data => {

                toast.success(t("Profile updated successfully"))
                setErrors([])
                setUserData({
                    ...userData,
                    password: ''
                })

                mutate(data)
                setIsSaving(false)
            })
            .catch(error => {
                setIsSaving(false)
                if (error.response.status != 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const UpdateButton = () => {

        return <div className="col-md-12 mt-3 text-center">
            <div className="small text-muted">
                <button disabled={isSaving} onClick={handleSubmit} className="btn btn-primary">{t("Submit")}</button>
            </div>
        </div>
    }

    function handleAnnuaireChange(e) {

        setAnnuaireData({
            ...annuaireData,
            [e.target.name]: e.target.value
        })
    }

    return <>
        {!user ? <IsLoading /> :  <div className="container">
            <div className="row gutters-sm">
                <div className="col-md-4 d-none d-md-block">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <Pills>
                                <Link className="nav-item nav-link has-icon" to="/">  <i className="bi bi-house-fill"></i> {t("Home")}</Link>
                                {/* {user && user.usager && UsagerTabs.map(item =>  <NavLinkItem key={item.id} href={"#"+item.id} isActive={item.is_active}>
                                    <i className={item.icon}></i> {t(item.label)}
                                </NavLinkItem>)} */}

                                {user && user.usager && <UsagerTabs />}
                                {user && user.annuaire && <AnnuaireTabs />}
                                {/* {user && user.annuaire && AnnuaireTabs.map(item =>  <NavLinkItem key={item.id} href={"#"+item.id} isActive={item.is_active}>
                                    <i className={item.icon}></i> {t(item.label)}
                                </NavLinkItem>)} */}

                            </Pills>
                        </div>
                    </div>
                </div>

                <div className="col-md-8">
                    <div className="card shadow-sm">
                        <div className="card-header border-bottom mb-3 d-flex d-md-none">
                            <ul className="nav nav-tabs card-header-tabs nav-gap-x-1">
                                <li className="nav-item">
                                    <Link to="/" className={"nav-link has-icon"}>  <i className="bi bi-house-fill"></i> </Link>
                                </li>

                                {/* {user && user.usager && UsagerTabs.map(item => <li key={item.id} className="nav-item">
                                        <a href={"#"+item.id} data-bs-toggle="tab" className={"nav-link has-icon "+item.is_active}>
                                            <i className={item.icon}></i> 
                                        </a>
                                </li>)} */}
                                {user && user.usager && <UsagerTabs />}
                                {user && user.annuaire && <AnnuaireTabs />}

                                {/* {user && user.annuaire && AnnuaireTabs.map(item => <li key={item.id} className="nav-item">
                                        <a href={"#"+item.id} data-bs-toggle="tab" className={"nav-link has-icon "+item.is_active}>
                                            <i className={item.icon}></i> 
                                        </a>
                                </li>)} */}
                            </ul>
                        </div>

                        <div className="card-body tab-content">
                            {user && user.usager && <UsagerTabsContent
                                userData={userData} 
                                usagerData={usagerData} 
                                setUserData={setUserData} 
                                setUsagerData={setUsagerData} 
                                errors={errors} 
                                countries={countries}
                                cities={cities}
                                UpdateButton={UpdateButton}
                                handleChange={handleChange}
                                handleUserDataChange={handleUserDataChange}
                                handleUsagerChange={handleUsagerChange}
                                handleCountryChange={handleCountryChange}
                            />}

                            {user && user.annuaire && <AnnuaireTabsContent 
                                userData={userData} 
                                annuaireData={annuaireData} 
                                errors={errors} 
                                countries={countries}
                                cities={cities}
                                UpdateButton={UpdateButton}
                                handleChange={handleChange}
                                setAnnuaireData={setAnnuaireData}
                                handleUserDataChange={handleUserDataChange}
                                handleAnnuaireChange={handleAnnuaireChange}
                                handleCountryChange={handleCountryChange}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </>
}

export default Profile