import {React} from "react";
import { t } from "i18next"

const IsLoading = () => {

    return  <div className="spinner-border" role="status">
        <span className="visually-hidden">{t("Loading...")}</span>
    </div>
}

export default IsLoading