import { React, forwardRef } from "react"
import { getYear, getMonth } from 'date-fns'
import Picker from "react-datepicker"
import { useTranslation } from "react-i18next";

const years = ((start, end, step) => {
    return (
      Array.from(
        Array.from(
          Array(Math.ceil(
            (end - start) / step
          )).keys()
        ),
        x => start + x * step
      )
    );
})(1990, getYear(new Date()) + 1, 1);

const DatePicker = forwardRef((props, ref) => {

    const { t } = useTranslation()

    const months = [
        t("January"),
        t("February"),
        t("March"),
        t("April"),
        t("May"),
        t("June"),
        t("July"),
        t("August"),
        t("September"),
        t("October"),
        t("November"),
        t("December"),
    ];

    return <Picker
        renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
        }) => ( <div style={{ margin: 10, display: "flex", justifyContent: "center", }}>
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
        </button>
        <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
        >
            {years.map((option) => (
            <option key={option} value={option}>
                {option}
            </option>
            ))}
        </select>

            <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
                }
            >
                {months.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
                ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {">"}
            </button>
        </div>
        )}
        ref={ref}
        className="form-control" dateFormat="dd-MM-yyyy" {...props}/>
})

export default DatePicker