import { React, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../hooks/auth";
import { parse, format } from "date-fns";
import ValidationErrors from "../../../ValidationErrors";
import Select from "react-select";
import Inputmask from "inputmask";
import axios from "../../../../lib/axios";
import IsLoading from "../../../IsLoading";
import DatePicker from "../../../DatePicker";

const UsagerProfile = ({
    userData, handleUserDataChange,
    usagerData, handleUsagerChange
    }) => {

        const { t } = useTranslation()
        
        const [categoryConstante, setCategoryConstante] = useState('')

        const [fonctions, setFonctions] = useState([])

        const [formesjuridiques, setFormesjuridiques] = useState([])

        const [professions, setProfessions] = useState([])
        
        const [typesPieces, setTypesPieces] = useState([])
        
        const [iAmReady, setIAmReady] = useState(false)

        const [dob, setDob] = useState('')

        const dobInput = useRef(null)

        const [civilites, setCivilites] = useState([])

        useEffect(() => {

            if(usagerData.category_constante !== categoryConstante) { setCategoryConstante(usagerData.category_constante) }
        }, [usagerData.category_constante])

        useEffect(async () => {

            await axios.get(`/api/intitules/types-pieces/by-group`)
                .then(r => r.data)
                .then(data => { setTypesPieces(data.map(typepiece => ({value: typepiece.id, label: typepiece.libelle}))) })

            await axios.get(`/api/intitules/civilites/by-group`)
                .then(r => r.data)
                .then(data => { setCivilites(data.map(civilite => ({value: civilite.id, label: civilite.libelle}))) })

            await axios.get(`/api/intitules/professions/by-group`)
                .then(r => r.data)
                .then(data => { setFonctions(data.map(fonction => ({value: fonction.id, label: fonction.libelle}))) })

            await axios.get(`/api/intitules/professions/by-group`)
                .then(r => r.data)
                .then(data => { setProfessions(data.map(profession => ({value: profession.id, label: profession.libelle}))) })

            await axios.get(`/api/intitules/formes-juridiques/by-group`)
                .then(r => r.data)
                .then(data => { setFormesjuridiques(data.map(formjuridique => ({value: formjuridique.id, label: formjuridique.libelle}))) })

            setIAmReady(true)
        }, [])

        useEffect(() => {

            if(iAmReady) {
                
                const date_dob = new Inputmask('99-99-9999')
                date_dob.mask(dobInput.current.input)
                setDob(new Date(usagerData.dob))
            }
        }, [iAmReady])
        
        return <>
        {!iAmReady ? <IsLoading /> :  <>
            {categoryConstante !== "LIB_CATEGORIE_PARTICULIER" && <div className="col-md-12">

                <fieldset className="border p-1">
                    <legend className="text-muted w-auto">
                        <i className="bi bi-building"></i>{t('Company')}
                    </legend>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="formejuridique_id">{t("Forme Juridique")}</label>
                                <Select 
                                    placeholder={t("Choose")} 
                                    options={formesjuridiques} 
                                    value={formesjuridiques.filter(formjuridique => formjuridique.value == usagerData.formejuridique_id)[0]}
                                    onChange={(option) => handleUsagerChange({target: {name: "formejuridique_id", value: option.value}})} />
                            </div>
                        </div> 

                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="denomination">{t('Denomination')}</label>
                                <input type="text" value={usagerData.denomination} name="denomination" onChange={handleUsagerChange} className="form-control" />
                            </div>
                        </div> 
                    </div>
                </fieldset>
            </div>}

            <div className="col-md-12">
                <fieldset className="border p-1">
                    <legend className="text-muted w-auto">
                        <i className="bi bi-person-circle"></i>{categoryConstante == "LIB_CATEGORIE_PARTICULIER" ? t('Etat Civil') : t('Legal Representative')}
                    </legend>
                    <div className="row">

                        <div className="col-md-4">
                            <div className="form-group">
                                <div className="mb-3">
                                <label htmlFor="civilite">{t("Civility")}</label>
                                    <Select options={civilites} value={civilites.filter(civilite => civilite.value == usagerData.civilite)[0]} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="nom">{t('LastName')}</label>
                                <input type="text" value={usagerData.nom} name="nom" onChange={handleUsagerChange} className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="prenom">{t('FirstName')}</label>
                                <input type="text" value={usagerData.prenom} name="name" onChange={handleUsagerChange} className="form-control" />
                            </div>
                        </div>                                       
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="type_id">{t("Type Piece")}</label>
                                <Select placeholder={t("Choose")} value={typesPieces.filter(typepiece => typepiece.value == usagerData.type_id)[0]} options={typesPieces} onChange={(option) => (handleUserDataChange({target: {name: "type_id", value: option.value}})) } />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="pid">{t("No Pid")}</label>
                                <input type="text" name="pid" id="pid" className="form-control text-uppercase" value={usagerData.pid} onChange={handleUsagerChange} />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="dob">{t("Dob")}</label>
                                <DatePicker 
                                    ref={dobInput} 
                                    selected={dob} 
                                    onChange={(date) => {
                                        setDob(date)
                                        handleUserDataChange({...userData, dob: (date ? format(date, 'dd-MM-yyyy') : '')})
                                    }}
                                />
                            </div>
                        </div>

                        {categoryConstante !== "LIB_CATEGORIE_PARTICULIER" && <>
                            <div className={"col-md-4"}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="fonction_id">{t("Fonction")}</label>
                                    <Select placeholder={t("Choose")} value={fonctions.filter(fonction => fonction.value == usagerData.fonction_id)[0]} options={fonctions} onChange={option => {handleUsagerChange({target: {name: 'fonction_id', value: option.value}})}} />
                                </div>
                            </div>
                            
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="profession">{t("Profession")}</label>
                                    <Select placeholder={t("Choose")} value={professions.filter(profession => profession.value == usagerData.profession)[0]} options={professions} onChange={option => { handleUserDataChange({target: {name: 'profession', value: option.value}}) }} />
                                </div>
                            </div>
                        </>}
                        
                    </div>
                </fieldset> 
            </div>
        </>}
    </>
}

export default UsagerProfile