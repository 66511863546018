import { Loader } from "google-maps"
import React, { createContext, useEffect, useState } from "react"

const GoogleContext = createContext()

const GoogleContextProvider = ({ children }) => {

    const [google, setGoogle] = useState(null)

    useEffect(async () => {

        const GOOGLE_MAPS_API_KEY = "AIzaSyBZc8cubLAhAsJF3e2lCybpb41--KxILsI";
        const loader = new Loader(GOOGLE_MAPS_API_KEY, { libraries: ["places"], sensor: false });
        
        setGoogle(await loader.load())
    }, [])


    return <GoogleContext.Provider value={{ google, setGoogle }}>
        {children}
    </GoogleContext.Provider>
}

export { GoogleContext, GoogleContextProvider }